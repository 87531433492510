export const fullScreen = (ref) => {
  if (
    document.fullscreenElement /* Standard syntax */ ||
    document.webkitFullscreenElement /* Safari and Opera syntax */ ||
    document.msFullscreenElement /* IE11 syntax */
  ) {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen()
    } else {
      return null
    }
  } else {
    if (ref.requestFullscreen) {
      ref.requestFullscreen()
    } else if (ref.webkitRequestFullscreen) {
      /* Safari */
      ref.webkitRequestFullscreen()
    } else if (ref.msRequestFullscreen) {
      /* IE11 */
      ref.msRequestFullscreen()
    } else {
      return null
    }
  }
}
