import { v4 as uuid } from 'uuid';

export const getFileExtension = (filename) => {
    return filename.split('.').pop();
}

export const generateFileName = (type = 'document', extension) => {
    const timeInMs = Date.now();

    const unique = uuid();

    switch (type) {
        case "document":
            return `DOC-ID-${unique}-${timeInMs}.${extension}`;

        case "photo":
            return `IMG-ID-${unique}-${timeInMs}.${extension}`;

        case "video":
            return `VID-ID-${unique}-${timeInMs}.${extension}`;

        default:
            return `DOC-ID-${unique}-${timeInMs}.${extension}`;
    }
}