import { CheckCircle, Close, KeyboardArrowRight, Menu } from '@mui/icons-material'
import { Badge, Divider, IconButton, useMediaQuery, useTheme } from '@mui/material'
import adnexioEDU from 'assets/logos/adnexio-edu-logo.svg'
import eduIcon from 'assets/logos/edu-icon.png'
import adnexioHR from 'assets/logos/adnexio-hr.svg'
import adnexioJOBS from 'assets/logos/adnexio-jobs.svg'
import React, { useContext, useRef, useState } from 'react'
import EDUDrawer from 'components/Drawer/EDUDrawer'
import EDUButton from 'components/Button/EDUButton'
import { useLocation, useNavigate } from 'react-router-dom'
import EDUAvatar from 'components/Avatar/EDUAvatar'
import Bugsnag from '@bugsnag/js'
import { authContext } from 'contexts/AuthContext'
import { clearCookies } from 'services/helper/clearCookies'
import { signout } from 'services/api/authentication'
import { ReactComponent as NotificationIcon } from 'assets/icons/notificationIcon.svg'
import ProductsIcon from 'assets/icons/ProductsIcon'
import EDUPopper from 'components/Popper/EDUPopper'
import SettingIcon from 'assets/icons/SettingIcon'
import { ReactComponent as LogoutIcon } from 'assets/icons/logoutIcon.svg'
import { NotificationContext } from 'contexts/NotificationContext'
import { notificationRead } from 'services/api/notification'
import { ReactComponent as Enquiries } from 'assets/icons/enquiries.svg'
import { ReactComponent as FAQ } from 'assets/icons/faq-icon.svg'
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg'

const Header = ({ isLogin, userInfo }) => {
  const { logoutUser } = useContext(authContext)
  const { eduNotification, getNotification, readNotification } = useContext(NotificationContext)
  const { pathname } = useLocation()
  const [mobileMenu, setMobileMenu] = useState({ left: false })
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const lg = useMediaQuery(theme.breakpoints.up('lg'))

  const avatarRef = useRef(null)
  const [openProfile, setOpenProfile] = useState(false)
  const handleClick = () => {
    if (lg) {
      setOpenProfile((prevOpen) => !prevOpen);
    } else {
      handleMobileMenu('left', true)
    }
  };
  const handleClose = (event) => {
    if (avatarRef.current && avatarRef.current.contains(event.target)) {
      return
    }
    setOpenProfile(false)
  }

  const handleRead = async (id) => {
    try {
      await notificationRead(id).then(async () => await getNotification({ isEdu: true }))
    } catch (e) {
      Bugsnag.notify(e)
    }
  }

  const productsRef = useRef(null)
  const [openProducts, setOpenProducts] = useState(false)
  const handleOpenProducts = () => {
    setOpenProducts((prevOpen) => !prevOpen);
  };
  const handleCloseProducts = (event) => {
    if (productsRef.current && productsRef.current.contains(event.target)) {
      return
    }
    setOpenProducts(false)
  }

  const notificationRef = useRef(null)
  const [openNotification, setOpenNotification] = useState(false)
  const handleToggleNotification = () => {
    setOpenNotification((prevOpen) => !prevOpen)
  }
  const handleCloseNotification = (event) => {
    if (notificationRef.current && notificationRef.current.contains(event.target)) {
      return
    }
    setOpenNotification(false)
  }

  const navigate = useNavigate()
  const handleMobileMenu = (anchor, state) => {
    setMobileMenu({ [anchor]: state })
  }

  const handleSignOut = async () => {
    try {
      await signout()
      clearCookies()
      logoutUser()
      navigate('/')
    } catch (e) {
      Bugsnag.notify(e)
    }
  }

  const handleNavigationProducts = (prod) => {
    if (prod === 'hr') {
      window.open(process.env.REACT_APP_ADNEXIO_HR_URL)
    } else if (prod === 'jobs') {
      window.open(process.env.REACT_APP_URL_REDIRECT)
    } else {
      window.location.href = '/'
    }
  }

  const navigateProfile = () => {
    setOpenProfile(false)
    navigate('/profile')
  }

  const handleSettings = () => {
    setOpenProfile(false)
    navigate('/account-settings')
  }
  const handleFaq = () => {
    setOpenProfile(false)
    navigate('/faq')
  }
  const handleContactUs = () => {
    setOpenProfile(false)
    navigate('/contact-us')
  }
  const handleViewNotifications = () => {
    setOpenNotification(false)
    navigate('/notifications')
  }

  const firstSection = [
    {
      title: 'Dashboard',
      link: '/'
    },
    {
      title: 'My learning',
      link: '/my-learning'
    },
    {
      title: 'Payment',
      link: '/payment-dashboard'
    },
    {
      title: 'Explore courses',
      link: '/explore-courses'
    },
  ]
  const secondSection = [
    {
      title: 'My profile',
      link: '/profile'
    },
    {
      title: 'Account settings',
      link: '/account-settings'
    },
    {
      title: 'FAQs',
      link: '/faq'
    },
    {
      title: 'Support',
      link: '/contact-us'
    },
  ]

  const handleNavigateMobile = (link) => {
    handleMobileMenu('left', false)
    navigate(link)
  }

  const handleLogOutMobile = async () => {
    await handleSignOut()
    handleMobileMenu('left', false)
  }

  return (
    <div className="border-b-edu-grayLine sticky top-0 z-10 flex w-full flex-row items-center justify-between border-b bg-[rgba(255,255,255,0.9)] backdrop-blur px-[15px] py-0 lg:justify-end lg:px-[27px]">
      <div className="lg:hidden flex items-center space-x-6">
        <IconButton onClick={() => handleMobileMenu('left', true)}>
          <Menu />
        </IconButton>
        <img alt="adnexio EDU" src={eduIcon} className="h-[25px]" />
      </div>
      {/* <div className="absolute left-1/2 -translate-x-1/2 lg:hidden">
        <img alt="adnexio EDU" src={adnexioEDU} className="h-[20px] md:h-[30px]" />
      </div> */}
      {isLogin ? (
        <div className="flex flex-row items-center justify-end">
          <div className={`py-[12px] px-3 relative block`}>
            <div ref={notificationRef} onClick={handleToggleNotification} className='cursor-pointer px-2 py-[12px]'>
              <Badge
                badgeContent={eduNotification !== null && eduNotification?.unreadCount}
                color="error"
                max={99}
                sx={{
                  '& .MuiBadge-badge': {
                    '@media (max-width:1024px)': {
                      padding: '0px',
                      fontSize: '10px',
                    },
                  },
                }}
              >
                <NotificationIcon className={`${pathname === '/notifications' || openNotification === true ? 'fill-edu-primary' : 'fill-edu-lightGrayText'}`} />
              </Badge>
            </div>
            <div className={`absolute bottom-0 left-0 w-full h-[2px] ${(pathname === '/notifications' || openNotification === true) && 'bg-edu-primary'}`} />
          </div>
          <div className='py-[12px] px-3 relative block'>
            <div ref={productsRef} onClick={handleOpenProducts} className='cursor-pointer px-2 py-[12px]'>
              <ProductsIcon color={openProducts ? theme.palette.primary.main : theme.palette['light-gray-text'].main} />
            </div>
            <div className={`absolute bottom-0 left-0 w-full h-[2px] ${openProducts && 'bg-edu-primary'}`} />
          </div>

          <div onClick={handleClick} ref={avatarRef} className='py-2'>
            <EDUAvatar src={userInfo?.profile_photo} alt='profile' sx={{ cursor: 'pointer', ml: { md: '12px' } }}>{userInfo?.name}</EDUAvatar>
          </div>
          <EDUPopper
            open={openNotification}
            anchorEl={notificationRef.current}
            handleClose={handleCloseNotification}
            sx={{ maxWidth: '400px', width: { xs: '100%', md: '400px' } }}
          >
            <div className='p-[14px] rounded '>
              <h4 className='text-h4'>Notifications</h4>
            </div>
            <div className='w-full h-[1px] bg-edu-grayLine' />
            <div className='max-h-[415px] overflow-auto'>
              {
                (readNotification?.data === null || readNotification?.data?.length < 1) && (eduNotification?.data === null || eduNotification?.data?.length < 1) ? (
                  <div className='py-[40px] px-[30px] flex flex-col justify-center items-center'>
                    <div className='bg-edu-defaultBackground rounded-full p-[25px]'>
                      <NotificationIcon className='fill-edu-lightGrayText' />
                    </div>
                    <p className='text-edu-lightGrayText mt-[20px]'>You don’t have any notification yet</p>
                  </div>
                ) : eduNotification?.data === null || eduNotification?.data?.length < 1 ? (
                  <div className='py-[40px] px-[30px] flex flex-col justify-center items-center'>
                    <div className='bg-edu-defaultBackground rounded-full p-[25px]'>
                      <div className='relative'>
                        <NotificationIcon className='fill-edu-lightGrayText' />
                        <CheckCircle sx={{ color: 'success.main', backgroundColor: 'white.main', borderRadius: '100%', fontSize: 16, position: 'absolute', top: -5, right: -5 }} />
                      </div>
                    </div>
                    <p className='text-edu-lightGrayText mt-[20px]'>You are up to date</p>
                  </div>
                ) : eduNotification?.data?.map((notty, index) => (
                  <div onClick={() => handleRead(notty?.id)} key={index}>
                    <div className='relative flex items-start m-1 px-[10px] py-[14px] rounded cursor-pointer hover:bg-edu-defaultBackground overflow-auto'>
                      <div className='py-[10px] px-[12px] rounded-full bg-edu-lightYellow mr-[14px]'>
                        <NotificationIcon className='fill-edu-primary' />
                      </div>
                      <div className='flex flex-col'>
                        <p>{notty?.message}</p>
                        <p className='text-body2 text-edu-lightGrayText mt-[5px]'>{notty?.time_stamp}</p>
                      </div>
                      <div className='absolute bottom-[14px] right-[10px] w-[10px] h-[10px] rounded-full bg-edu-secondary' />
                    </div>
                    {
                      eduNotification?.data?.length !== index + 1 && <div className='w-full h-[1px] bg-edu-grayLine' />
                    }
                  </div>
                ))

              }
            </div>
            <div className='w-full h-[1px] bg-edu-grayLine' />
            <div onClick={handleViewNotifications} className='group flex items-center justify-center m-1 py-2 px-3 rounded cursor-pointer '>
              <p className='text-center text-edu-secondary group-hover:underline'>View all notifications</p>
            </div>
          </EDUPopper>
          <EDUPopper
            open={openProducts}
            anchorEl={productsRef.current}
            handleClose={handleCloseProducts}
            sx={{
              maxWidth: '400px',
              width: { xs: '100%', md: 'auto' }
            }}
          >
            <div onClick={() => handleNavigationProducts('hr')} className='flex flex-col items-start gap-3 m-1 p-[12.5px] rounded cursor-pointer hover:bg-edu-defaultBackground'>
              <img alt='adnexio HR' src={adnexioHR} className='h-[18px]' />
              <p>Manage HR tasks all in one place</p>
            </div>
            <div onClick={() => handleNavigationProducts('')} className='flex flex-col items-start gap-3 m-1 p-[12.5px] rounded cursor-pointer hover:bg-edu-defaultBackground'>
              <img alt='adnexio EDU' src={adnexioEDU} className='h-[18px]' />
              <p>Learn courses tailored to the skills you need</p>
            </div>
            {/* <div className='w-full h-[1px] bg-edu-grayLine' /> */}
            <div onClick={() => handleNavigationProducts('jobs')} className='flex flex-col items-start gap-3 m-1 p-[12.5px] rounded cursor-pointer hover:bg-edu-defaultBackground'>
              <img alt='adnexio JOBS' src={adnexioJOBS} className='h-[20px]' />
              <p>Search dream job with latest trend and demand</p>
            </div>
          </EDUPopper>
          <EDUPopper
            open={openProfile}
            anchorEl={avatarRef.current}
            handleClose={handleClose}
          >
            <div className='flex items-center gap-4 m-1 py-2 px-3 rounded cursor-default'>
              <EDUAvatar alt='profile' src={userInfo?.profile_photo} sx={{ width: 36, height: 36 }}>
                {userInfo?.name}
              </EDUAvatar>
              <div className='max-w-[220px]'>
                <p className='flex-nowrap'>{userInfo?.name}</p>
                <p className='text-body2 truncate text-edu-lightGrayText'>{userInfo?.email}</p>
              </div>
            </div>
            <div className='w-full h-[1px] bg-edu-grayLine' />
            <div onClick={navigateProfile} className='flex items-center gap-4 m-1 p-3 rounded cursor-pointer hover:bg-edu-defaultBackground'>
              <ProfileIcon />
              <div className='w-[220px]'>
                <p className='flex-nowrap'>My profile</p>
              </div>
            </div>
            <div onClick={handleSettings} className='flex items-center gap-4 m-1 p-3 rounded cursor-pointer hover:bg-edu-defaultBackground'>
              <SettingIcon />
              <div className='w-[220px]'>
                <p className='flex-nowrap'>Account settings</p>
              </div>
            </div>
            <div className='w-full border-t border-edu-grayLine' />
            <div onClick={handleFaq} className='flex items-center gap-4 m-1 p-3 rounded cursor-pointer hover:bg-edu-defaultBackground'>
              <FAQ className='w-[18px] fill-edu-lightGrayText' />
              <div className='w-[220px]'>
                <p className='flex-nowrap'>FAQs</p>
              </div>
            </div>
            <div onClick={handleContactUs} className='flex items-center gap-4 m-1 p-3 rounded cursor-pointer hover:bg-edu-defaultBackground'>
              <Enquiries className='w-[18px] h-[19px] fill-edu-lightGrayText' />
              <div className='w-[220px]'>
                <p className='flex-nowrap'>Contact us</p>
              </div>
            </div>
            <div className='w-full h-[1px] bg-edu-grayLine' />
            <div onClick={handleSignOut} className='flex items-center gap-4 m-1 p-3 rounded cursor-pointer hover:bg-edu-defaultBackground'>
              <LogoutIcon />
              <p className='text-edu-error'>Log out</p>
            </div>
          </EDUPopper>
        </div>
      ) : (
        <div className="flex flex-row items-center justify-end space-x-2">
          <div className="hidden md:block">
            <EDUButton onClick={() => navigate('/signup')}>Sign up</EDUButton>
          </div>
          <EDUButton onClick={() => navigate('/signin')} variant="contained">Sign in</EDUButton>
        </div>
      )}
      <EDUDrawer
        anchor={Object.keys(mobileMenu)[0]}
        drawerState={mobileMenu}
        handleDrawer={handleMobileMenu}
        sx={{ width: md ? '50vw' : '90vw' }}
        sxBody={{
          minHeight: '100vh',
          display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
        }}
      >
        <div >
          <div className='px-[16px] py-[24px] flex items-start justify-between box-border'>
            <div className='flex items-start w-auto'>
              <EDUAvatar src={userInfo?.profile_photo} alt='profile' sx={{ cursor: 'default', mr: 2 }}>{userInfo?.name}</EDUAvatar>
              <div className='flex-1 flex-col'>
                <p className='text-subtitle mb-[8px] '>{userInfo?.name}</p>
                <p className='text-edu-lightGrayText truncate max-w-[50vw] md:max-w-[calc(50vw-120px)]'>{userInfo?.email}</p>
              </div>
            </div>
            <Close
              sx={{ alignSelf: 'start', justifySelf: 'center', cursor: 'pointer' }}
              onClick={() => handleMobileMenu('left', false)}
            />
          </div>
          <Divider />
          <div className='py-[24px] px-[16px] flex flex-col space-y-[16px]'>
            <p className='text-edu-lightGrayText cursor-default'>Learn</p>
            {
              firstSection.map((item, index) => (
                <div key={index} onClick={() => handleNavigateMobile(item?.link)} className={`flex items-center justify-between cursor-pointer`}>
                  <p>{item?.title}</p>
                  <KeyboardArrowRight sx={{ fontSize: 20 }} />
                </div>
              ))
            }
          </div>
          <Divider />
          <div className='py-[24px] px-[16px] flex flex-col space-y-[16px]'>
            <p className='text-edu-lightGrayText cursor-default'>Account</p>
            {
              secondSection.map((item, index) => (
                <div key={index} onClick={() => handleNavigateMobile(item.link)} className={`flex items-center justify-between cursor-pointer`}>
                  <p>{item?.title}</p>
                  <KeyboardArrowRight sx={{ fontSize: 20 }} />
                </div>
              ))
            }
          </div>
        </div>
        <div onClick={handleLogOutMobile} className='px-[16px] py-[24px] cursor-pointer border-t border-edu-grayLine flex justify-between items-center'>
          <p className='text-edu-error'>Log out</p>
          <LogoutIcon />
        </div>
      </EDUDrawer>
    </div>
  )
}

export default Header
