import { Button, CircularProgress, useTheme } from '@mui/material'
import React from 'react'

const EDUButton = ({ children, variant, disabled, loading, sx, ...rest }) => {
  const theme = useTheme()
  return (
    <Button
      variant={variant}
      color="secondary"
      disableElevation
      disabled={disabled}
      sx={{
        '&.Mui-disabled': {
          color: 'light-gray-text.main',
          backgroundColor: variant === 'contained' && 'gray-background.main',
        },
        fontSize: theme.typography.h4.fontSize,
        fontWeight: theme.typography.h4.fontWeight,
        ...sx,
      }}
      {...rest}
    >
      {loading ? (
        <CircularProgress
          color={disabled ? 'light-gray-text' : variant === 'contained' ? 'white' : 'secondary'}
          size={14}
        />
      ) : (
        children
      )}
    </Button>
  )
}

export default EDUButton
