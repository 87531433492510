import Bugsnag from "@bugsnag/js"
import { Verified } from "@mui/icons-material"
import EDUButton from "components/Button/EDUButton"
import EDUTabPanel from "components/Tab/EDUTabPanel"
import { ProfileContext } from "contexts/ProfileContext"
import { useContext } from "react"
import { useState } from "react"
import { getCertificate } from "services/api/profile"

const CompletdCertificateTab = ({ label, data }) => {
  const { profileData } = useContext(ProfileContext)
  const [isLoading, setIsLoading] = useState(false)
  const handleViewCertificate = async (cert_id) => {
    setIsLoading(true)
    try {
      const res = await getCertificate(cert_id)
      const element = document.createElement('a')
      const file = new Blob([res.data], { type: res.headers["content-type"] })
      element.href = URL.createObjectURL(file)
      element.target = '_blank'
      element.click()
    } catch (error) {
      Bugsnag.notify(error)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <EDUTabPanel label={label}>
      <div className="py-[20px]">
        <div className={`w-full flex px-[16px] border rounded-[4.5px] py-[18px] items-start md:items-center justify-between border-edu-grayLine mb-[20px] `}>
          <div className="flex items-center space-x-[18px]">
            <div className="rounded-full flex justify-center items-center bg-edu-lightYellow p-[12px]">
              <Verified sx={{ color: 'primary.main', fontSize: 24 }} />
            </div>
            <div className="flex flex-col">
              <p className="text-subtitle mb-[4px]">Certificate of completion</p>
              <div className="flex flex-col items-start space-y-[4px] md:space-y-0 md:flex-row md:items-center">
                <p className="text-body2 text-edu-lightGrayText capitalize">Completed on {data?.completed_date}</p>
                {/* ONCE BACKEND GAVE THIS DATA, THEN ONLY WILL DISPLAY */}
                {/* <div className="hidden md:block rounded-full mt-[7px] bg-edu-grayLine w-[5px] h-[5px] mx-[10px]" /> */}
                {/* <p className="text-body2 text-edu-lightGrayText">Overall grade: </p> */}
              </div>
            </div>
          </div>
          <EDUButton loading={isLoading} disabled={isLoading} onClick={() => handleViewCertificate(data?.cert_id)} variant='contained'>View certificate</EDUButton>
        </div>
        <p>Congratulations on completing the <span className="text-subtitle">{data?.course_title} course by {data?.trainer_name}!</span> This certificate verifies that <span className="text-subtitle capitalize">{profileData?.name}</span> successfully completed the course on {data?.completed_date} and passed all assessments required with excellent grade.</p>
      </div>
    </EDUTabPanel>
  )
}

export default CompletdCertificateTab