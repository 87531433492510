import axios, { getData } from "../../../lib/axios"

export const registerJobseeker = async (data) => {
    return axios.post("/register_jobseekers", data).then(getData)
}

export const account = async () => {
    return axios.get("/my_accounts").then(getData)
}

export const updateAccount = async () => {
    return axios.put("/my_accounts").then(getData)
}

export const deleteAccount = async () => {
    return axios.delete("/delete_account_jobseekers").then(getData)
}

export const support = async (data) => {
    return axios.post('/support', data).then(getData);
}

// FOR EDU
export const eduSupport = async (payload) => {
    return axios.post(`adx_edu/support`, payload).then(getData)
}

export const checkEmail = async (payload) => {
    return axios.post(`/validate_email `, payload).then(getData)
}

export const resendEmail = async (payload) => {
    return axios.post(`/email/resend`, payload).then(getData)
}