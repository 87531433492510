import Bugsnag from "@bugsnag/js"
import { Error } from "@mui/icons-material"
import { Box, Grid, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import EDUButton from "components/Button/EDUButton"
import EDUCourseCard from "components/CourseCard/EDUCourseCard"
import EDUNoData from "components/NoData/EDUNoData"
import { useNavigate } from "react-router-dom"
import { getCourseDashboard } from "services/api/dashboard"
import exploreImage from '../assets/explore.png'
import backgroundImage from '../assets/background-banner.png'

const ExploreCourses = ({ isLogin }) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const {
    isLoading,
    data,
  } = useQuery(
    ['courseListDashboard'],
    async () => {
      const {
        list,
        recent: recentVideo,
      } = await getCourseDashboard()
      return { recommendedCourse: list?.data, recentVideo }
    },
    {
      onError: (err) => Bugsnag.notify(err),
    }
  )

  const navigate = useNavigate()

  const handleSignup = () => {
    navigate('/signup')
  }

  const handleViewCourseDetails = (courseId) => {
    navigate(`/course/${courseId}`)
  }

  return (
    <Box sx={{ py: isLogin ? '30px' : '50px', px: { xs: !isLogin ? 0 : '30px', md: '50px' }, flexGrow: 1 }}>
      {isLogin && <h4 className="text-h4 mb-[30px]">Explore courses</h4>}
      <Grid container rowSpacing={4} columnSpacing={8} >
        {isLoading ? (
          [1, 1, 1].map((_, index) => {
            return (
              <Grid key={index} item xs={12} md={6} lg={4}>
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    maxWidth: md ? '373px' : null,
                    backgroundColor: 'white.main',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Skeleton sx={{ width: '100%', height: '200px' }}></Skeleton>
                  <Box
                    sx={{
                      width: '100%',
                      paddingY: '22px',
                    }}
                  >
                    <Box
                      sx={{
                        mb: '8px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Skeleton>
                        <Typography
                          variant="subtitle1"
                          sx={{ textTransform: 'uppercase', color: 'information.main' }}
                        >
                          Course
                        </Typography>
                      </Skeleton>

                      <Skeleton
                        sx={{
                          paddingX: '10px',
                          paddingY: '2px',
                          borderRadius: '2.5px',
                        }}
                      >
                        <Typography variant="subtitle1" sx={{ color: 'white.main' }}>
                          Best Seller
                        </Typography>
                      </Skeleton>
                    </Box>
                    <Skeleton>
                      <Typography variant="h4" sx={{ textTransform: 'capitalize', mb: '6px' }}>
                        Software Engineering Conversion Bootcamp
                      </Typography>
                    </Skeleton>
                    <Skeleton>
                      <Typography
                        variant="body1"
                        sx={{ textTransform: 'capitalize', color: 'light-gray-text.main' }}
                      >
                        By Invoke Solutions Sdn. Bhd.
                      </Typography>
                    </Skeleton>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: md ? 'row' : 'column',
                        alignItems: md ? 'center' : 'flex-start',
                        justifyContent: 'space-between',
                        mt: '11.6px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: md ? 'row' : 'column',
                          alignItems: md ? 'center' : 'flex-start',
                          columnGap: 1,
                        }}
                      >
                        <Skeleton>
                          <Typography variant="h3" sx={{ color: 'error.main' }}>
                            RM 99,999
                          </Typography>
                        </Skeleton>

                        <Skeleton>
                          <Typography
                            variant="body1"
                            sx={{ color: 'dark-text.main', textDecoration: 'line-through' }}
                          >
                            RM 99,999
                          </Typography>
                        </Skeleton>
                      </Box>

                      <Skeleton>
                        <Typography variant="body1" sx={{ color: 'light-gray-text.main' }}>
                          (999.9k sold)
                        </Typography>
                      </Skeleton>
                    </Box>
                  </Box>
                </Box>
              </Grid >
            )
          })
        ) : (
          data && data?.recommendedCourse?.length !== 0 ? data?.recommendedCourse?.map((item, index) => {
            return (
              <Grid key={index} item xs={12} md={6} lg={4} className='group/card'>
                <div className=" cursor-pointer">
                  <EDUCourseCard data={item} showPricing={true} handleNavigate={() => handleViewCourseDetails(item?.id)} />
                </div>
              </Grid>
            )
          }) : (
            <Grid item xs={12}>
              <EDUNoData title="No Information" icon={<Error sx={{ fontSize: 40 }} />} />
            </Grid>
          )
        )}
      </Grid >
      <Box
        sx={{
          mt: 4,
          width: '100%',
          px: { xs: '15px', md: '50px' },
          py: '30px',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          justifyItems: 'space-between',
          alignItems: 'flex-start',
          gap: 8,
          borderRadius: '4.5px'
        }}
      >
        <div className="flex flex-col w-auto">
          <h2 className={`text-h3 md:text-h2 ${!isLogin ? 'max-w-[300px]' : 'lg:max-w-[500px]'} mb-4 lg:mt-4`}>{isLogin ? 'Stay tuned to enjoy more interesting courses coming soon' : 'More interesting courses coming soon!'}</h2>
          <p>{isLogin ? 'We offers the best courses tailored to the demands of today’s ever-changing job market.' : 'Sign up your account now to stay update with the latest courses and promotion available.'}</p>
          {!isLogin && <EDUButton variant='contained' onClick={handleSignup} sx={{ mt: 3, maxWidth: '200px' }}>Sign up an account</EDUButton>}
        </div>
        <img src={exploreImage} alt='' className="hidden lg:block" />
      </Box>
    </Box >
  )
}

export default ExploreCourses