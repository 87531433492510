import React from 'react'
import { Box, Breadcrumbs, useMediaQuery, useTheme } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

const EDUBreadcrumb = ({ children, separatorSx, ...rest }) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Box>
      {md ? (
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" sx={separatorSx} />}
          aria-label="breadcrumb"
          {...rest}
        >
          {children}
        </Breadcrumbs>
      ) : (
        <Breadcrumbs
          maxItems={2}
          separator={<NavigateNextIcon fontSize="small" sx={separatorSx} />}
          aria-label="breadcrumb"
          {...rest}
        >
          {children}
        </Breadcrumbs>
      )}
    </Box>
  )
}

export default EDUBreadcrumb
