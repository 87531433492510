import { useMediaQuery, useTheme } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import EDUButton from 'components/Button/EDUButton'
import React, { useState, useRef } from 'react'
import { postProgress } from 'services/api/myClass'
// import ReactPlayer from 'react-player/lazy'
import { fullScreen } from 'services/helper/fullScreen'
import MobileController from './MobileController'
import VideoController from './VideoController'
import { useEffect } from 'react'

const VideoPlayer = ({ source, state, enrollment_id, handleNext, setVideoProgressFunction }) => {
  const playerRef = useRef(null)
  const playerContainerRef = useRef(null)
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const [videoState, setVideoState] = useState({
    isPlaying: false,
    isMuted: false,
    volume: 0.5,
    playbackRate: 1,
    progressSeconds: 0,
    duration: 0,
    endVideo: false,
    isFullScreen: false,
  })
  const { isPlaying, isMuted, volume, duration, endVideo } = videoState
  const handlePlayPause = () => {
    if (!playerRef.current.paused) {
      playerRef.current.pause()
    } else {
      playerRef.current.play()
    }
  }
  const handleVolume = (_, vol) => {
    playerRef.current.volume = parseFloat(vol / 100)
  }
  const handleReplay = () => {
    playerRef.current.play()
    setVideoState({
      ...videoState,
      progressSeconds: 0,
    })
  }
  const handleMute = () => {
    playerRef.current.muted = !playerRef.current.muted
  }
  const handleSlideProgress = (_, newValue) => {
    playerRef.current.currentTime = (newValue * duration) / 100
    setVideoState({
      ...videoState,
      endVideo: newValue === 100 ? true : false,
      progressSeconds: parseFloat((newValue * duration) / 100),
    })
  }
  const handleRewind = () => {
    playerRef.current.currentTime =
      playerRef.current.currentTime < 10 ? 0 : playerRef.current.currentTime - 10
  }

  const handleFastForward = () => {
    if (playerRef.current.currentTime + 10 < duration) {
      playerRef.current.currentTime = playerRef.current.currentTime + 10
    } else {
      playerRef.current.currentTime = playerRef.current.currentTime + 10
      setVideoState({
        ...videoState,
        progressSeconds: duration,
      })
    }
  }
  const handlePlayback = (rate) => {
    playerRef.current.playbackRate = rate
    setVideoState({ ...videoState, playbackRate: playerRef.current.playbackRate })
  }

  const handleFullScreen = () => {
    fullScreen(playerContainerRef.current)
    if (
      document.fullscreenElement /* Standard syntax */ ||
      document.webkitFullscreenElement /* Safari and Opera syntax */ ||
      document.msFullscreenElement /* IE11 syntax */
    ) {
      setVideoState({
        ...videoState,
        isFullScreen: false,
      })
    } else {
      setVideoState({
        ...videoState,
        isFullScreen: true,
      })
    }
  }

  // const handleKeyboardEvent = (e) => {
  //   if (e.key === 'f') {
  //     if (playerContainerRef.current.requestFullscreen) {
  //       playerContainerRef.current.requestFullscreen()
  //     } else if (playerContainerRef.current.webkitRequestFullscreen) {
  //       /* Safari */
  //       playerContainerRef.current.webkitRequestFullscreen()
  //     } else if (playerContainerRef.current.msRequestFullscreen) {
  //       /* IE11 */
  //       playerContainerRef.current.msRequestFullscreen()
  //     } else {
  //       return null
  //     }
  //   } else if (e.key === 'Escape') {
  //     if (playerContainerRef.current.onfullscreenchange !== null) {
  //       if (document.exitFullscreen) {
  //         document.exitFullscreen()
  //       } else if (document.webkitExitFullscreen) {
  //         /* Safari */
  //         document.webkitExitFullscreen()
  //       } else if (document.msExitFullscreen) {
  //         /* IE11 */
  //         document.msExitFullscreen()
  //       } else {
  //         return null
  //       }
  //     } else {
  //       return null
  //     }
  //   } else {
  //     return null
  //   }
  // }
  const queryClient = useQueryClient()
  const { mutate } = useMutation(postProgress, {
    onSuccess: () => {
      queryClient.invalidateQueries(['moduleDetails', enrollment_id])
      // window.removeEventListener('onbeforeunload')
    },
  })

  // DETECT IF PAGE ON RELOAD OR REFRESH
  const handleBeforeUnload = (e) => {
    // if (parseInt(playerRef.current.currentTime) > 60) {
    mutate({
      nex_edu_current_enrolment_id: parseInt(enrollment_id),
      nex_edu_recording_id: state?.subtopic?.id,
      progress: parseInt(playerRef.current.currentTime),
    })
    // }
  }

  // DETECT IF CHANGE TAB OR WINDOW
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      // if (parseInt(playerRef.current.currentTime) > 60) {
      mutate({
        nex_edu_current_enrolment_id: parseInt(enrollment_id),
        nex_edu_recording_id: state?.subtopic?.id,
        progress: parseInt(playerRef.current.currentTime),
      })
      // }
    }
  }

  const handleChangeRoute = () => {
    // if (parseInt(playerRef.current.currentTime) > 60) {
    mutate({
      nex_edu_current_enrolment_id: parseInt(enrollment_id),
      nex_edu_recording_id: state?.subtopic?.id,
      progress: parseInt(videoState.progressSeconds),
    })
    // }
  }

  // TO FIND A WAY TO CHECK IF URL ABOUT TO CHANGE, SEND REQUEST
  useEffect(() => {
    window.addEventListener('visibilitychange', handleVisibilityChange)
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange)
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setVideoProgressFunction(() => (fn) => handleChangeRoute())
    // eslint-disable-next-line
  }, [videoState.progressSeconds])

  return (
    <div
      className="group/container relative h-full w-auto"
      ref={playerContainerRef}
    // onMouseEnter={handleControlDisplay}
    // onMouseMove={handleControlDisplay}
    // onMouseLeave={handleControlDisplay}
    // onKeyDown={handleKeyboardEvent}
    // tabIndex={-1} //for keyboard event
    >
      <video
        onClick={md ? () => handlePlayPause() : null}
        src={source}
        ref={playerRef}
        className="h-full w-full"
        controls={false}
        muted={isMuted}
        controlsList="nodownload"
        volume={volume}
        onPlay={(e) => {
          setVideoState({ ...videoState, isPlaying: !e.target.paused })
        }}
        onPause={(e) => {
          setVideoState({ ...videoState, isPlaying: !e.target.paused })
        }}
        onVolumeChange={(e) => {
          setVideoState({
            ...videoState,
            volume: e.target.volume,
            isMuted: e.target.muted || e.target.volume === 0 ? true : false,
          })
        }}
        // CHANGE CURRENT TIME
        onTimeUpdate={(e) => {
          setVideoState({
            ...videoState,
            progressSeconds: e.target.currentTime,
          })
        }}
        //FIRST TIME RENDER
        onLoadStart={(e) => {
          setVideoState({
            isPlaying: !e.target.paused || false,
            isMuted: e.target.muted || false,
            volume: e.target.volume || 0.5,
            playbackRate: e.target.playbackRate || 1,
            progressSeconds:
              state?.subtopic?.progress >= state?.subtopic?.duration
                ? 0
                : (e.target.currentTime = state?.subtopic?.progress),
            duration: e.target.duration || 0,
            endVideo: e.target.ended || false,
            isFullScreen: e.target.webkitDisplayingFullscreen || false,
          })
        }}
        onCanPlay={(e) => {
          setVideoState({
            isPlaying: !e.target.paused,
            isMuted: e.target.muted,
            volume: e.target.volume,
            playbackRate: e.target.playbackRate,
            progressSeconds: e.target.currentTime,
            duration: e.target.duration,
            endVideo: e.target.ended,
            isFullScreen: e.target.webkitDisplayingFullscreen,
          })
        }}
        //TO ENSURE STATE BACK TO DEFAULT WHEN CHANGING ACCORDION
        onDurationChange={(e) => {
          setVideoState({
            isPlaying: !e.target.paused,
            isMuted: e.target.muted,
            volume: e.target.volume,
            playbackRate: e.target.playbackRate,
            progressSeconds: e.target.currentTime,
            duration: e.target.duration,
            endVideo: e.target.ended,
            isFullScreen: e.target.webkitDisplayingFullscreen,
          })
        }}
        onEnded={(e) => {
          mutate({
            nex_edu_current_enrolment_id: parseInt(enrollment_id),
            nex_edu_recording_id: state?.subtopic?.id,
            progress: Math.round(e.target.currentTime),
          })
          setVideoState({
            ...videoState,
            endVideo: e.target.ended,
          })
        }}
      ></video>
      {endVideo && md && (
        <>
          <div className="z-1 absolute bottom-0 h-full w-full bg-edu-darkText opacity-50" />
          <div className="z-2 absolute bottom-0 left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center">
            <p className="mb-2 text-subtitle text-edu-white">UP NEXT</p>
            <h3 className="text-center text-h3 capitalize text-edu-white">
              {Boolean(!state?.next?.type)
                ? ''
                : state?.next?.type === 'GRADED'
                  ? 'Graded quiz:'
                  : 'Recap quiz:'}{' '}
              {state?.next?.title}
            </h3>
            <EDUButton
              onClick={handleNext}
              variant="contained"
              sx={{
                width: '250px',
                mt: '40px',
                '&.MuiButtonBase-root': {
                  background: 'white',
                  color: 'black',
                },
              }}
            >
              Continue
            </EDUButton>
          </div>
        </>
      )}
      {md ? (
        <div
          className={`z-3 absolute bottom-0 ${!isPlaying ? 'opacity-100' : 'opacity-0'
            } w-full transition-opacity delay-200 group-hover/container:opacity-100 group-hover/container:delay-[0ms]`}
        >
          <VideoController
            onReplay={handleReplay}
            onPlayPause={handlePlayPause}
            onMute={handleMute}
            onRewind={handleRewind}
            onFastForward={handleFastForward}
            onVolumeChange={handleVolume}
            onSlideProgress={handleSlideProgress}
            onToggleFullScreen={handleFullScreen}
            onPlaybackChange={handlePlayback}
            videoState={videoState}
          />
        </div>
      ) : (
        <div
          className={`z-3 absolute bottom-0 ${!isPlaying ? 'opacity-100' : 'opacity-0'
            } h-full w-full transition-opacity delay-200 group-hover/container:opacity-100 group-hover/container:delay-[0ms]`}
        >
          <MobileController
            onReplay={handleReplay}
            onPlayPause={handlePlayPause}
            onMute={handleMute}
            onRewind={handleRewind}
            onFastForward={handleFastForward}
            onVolumeChange={handleVolume}
            onSlideProgress={handleSlideProgress}
            onToggleFullScreen={handleFullScreen}
            onPlaybackChange={handlePlayback}
            videoState={videoState}
            courseState={state}
            handleNext={handleNext}
          />
        </div>
      )}
    </div>
  )
}

export default VideoPlayer
