import { Box, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material'
import EDUNoData from 'components/NoData/EDUNoData'
import EDUTabPanel from 'components/Tab/EDUTabPanel'
import EDUVideoCard from 'components/VideoCard/EDUVideoCard'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as MyLearningIcon } from 'assets/icons/myLearningIcon.svg'
import { useQuery } from '@tanstack/react-query'
import { getMyLearning } from 'services/api/myLearning'
import Bugsnag from '@bugsnag/js'
import EDUButton from 'components/Button/EDUButton'

const OnGoingTab = ({ label }) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const navigate = useNavigate()
  const handleCourseRoute = (id) => {
    navigate(`/my-learning/${id}`)
  }
  const navigateExploreCourses = () => {
    navigate(`/explore-courses`)
  }
  const { isLoading, data } = useQuery(
    ['getOngoingCourse'],
    async () => await getMyLearning('ongoing'),
    {
      onError: (err) => Bugsnag.notify(err),
    }
  )

  return (
    <EDUTabPanel label={label}>
      <Box sx={{ paddingY: '20px' }}>
        {!isLoading ? (
          data?.length !== 0 ? (
            data?.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{ marginBottom: 4, cursor: 'pointer' }}
                  onClick={() => handleCourseRoute(item?.id)}
                >
                  <EDUVideoCard data={item} />
                </Box>
              )
            })
          ) : (
            <EDUNoData title="You have no on going course" description='Purchase any course to start your learning here' icon={<MyLearningIcon className={`fill-edu-lightGrayText02 w-[28px] self-center h-[40px]`} />} sxIcon={{ width: 'auto', height: 'auto' }} action={<EDUButton variant='outlined' onClick={navigateExploreCourses} sx={{ mt: 3 }}>Explore courses</EDUButton>} />
          )
        ) : (
          /*Skeleton  */
          [1, 1, 1].map((_, index) => {
            return (
              <Box
                key={index}
                sx={{
                  width: '100%',
                  backgroundColor: 'white.main',
                  display: 'flex',
                  flexDirection: md ? 'row' : 'column',
                  alignItems: 'center',
                }}
              >
                {/*Image and Play Button */}
                <Box sx={{ width: md ? '25%' : '100%', height: '177px' }}>
                  <Skeleton
                    sx={{
                      width: '100%',
                      height: '100%',
                    }}
                  ></Skeleton>
                </Box>
                {/*Details and Copywriting */}
                <Box
                  sx={{
                    width: '100%',
                    paddingLeft: md ? '15px' : null,
                    paddingY: '20px',
                  }}
                >
                  <Skeleton>
                    <Typography
                      variant="subtitle1"
                      sx={{ textTransform: 'uppercase', color: 'information.main', mb: '6px' }}
                    >
                      Course
                    </Typography>
                  </Skeleton>
                  <Skeleton>
                    <Typography
                      variant="h4"
                      sx={{
                        textTransform: 'capitalize',
                      }}
                    >
                      Software Engineering Conversion Bootcamp
                    </Typography>
                  </Skeleton>
                  <Skeleton>
                    <Typography
                      variant="body1"
                      sx={{ textTransform: 'capitalize', color: 'light-gray-text.main' }}
                    >
                      By Invoke Solutions Sdn. Bhd.
                    </Typography>
                  </Skeleton>
                  <Skeleton sx={{ width: '100%' }}></Skeleton>

                  {md ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: '10px',
                      }}
                    >
                      <Skeleton>
                        <Typography
                          variant="body1"
                          sx={{ textTransform: 'capitalize', color: 'light-gray-text.main' }}
                        >
                          Next up: HTML introduction, Code Editor, Git, HTML elements
                        </Typography>
                      </Skeleton>
                      <Skeleton>
                        <Typography
                          variant="body1"
                          sx={{ textTransform: 'capitalize', color: 'light-gray-text.main' }}
                        >
                          70% Complete
                        </Typography>
                      </Skeleton>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        mt: '10px',
                      }}
                    >
                      <Skeleton>
                        <Typography
                          variant="body1"
                          sx={{ textTransform: 'capitalize', color: 'light-gray-text.main' }}
                        >
                          70% Complete
                        </Typography>
                      </Skeleton>
                      <Skeleton>
                        <Typography
                          variant="body1"
                          sx={{ textTransform: 'capitalize', color: 'light-gray-text.main' }}
                        >
                          Next up: HTML introduction, Code Editor, Git, HTML elements
                        </Typography>
                      </Skeleton>
                    </Box>
                  )}
                </Box>
              </Box>
            )
          })
        )}
      </Box>
    </EDUTabPanel>
  )
}

export default OnGoingTab
