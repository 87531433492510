import { Form, Formik } from "formik"
import SignUpContainer from "../components/SignUpContainer"
import * as yup from 'yup'
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import eduIcon from 'assets/logos/adnexio-edu-logo.svg'
import EDUTextField from "components/TextField/EDUTextField"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import EDUButton from "components/Button/EDUButton"
import { useState } from "react"
import EDUCheckbox from "components/Checkbox/EDUCheckbox"
import { useMutation } from "@tanstack/react-query"
import { registerJobseeker } from "services/api/registration"
import { clearCookies } from "services/helper/clearCookies"

const CreateAccount = () => {
  const [type, setType] = useState('password')
  const [typeConfirmPassword, setTypeConfirmPassword] = useState('password')
  const { state } = useLocation()
  const [payload, setPayload] = useState({ email: state })
  const validationSchema = yup.object({
    email: yup.string().email('Email must be a valid email').required('Email is required'),
    name: yup.string().required('Full name is required'),
    password: yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required'),
    password_confirmation: yup.string()
      .oneOf([yup.ref('password'), null], 'Password must match!')
      .required('The password field is required.'),
  })
  const navigate = useNavigate()

  const { mutate, isLoading } = useMutation(registerJobseeker, {
    onSuccess: () => {
      clearCookies()
      navigate('/verification-email', {
        state: {
          status: 'verify',
          payload: payload
        }
      })
    }
  })
  const handleSignUp = (values) => {
    const { agree_status, ...rest } = values
    setPayload(values)
    if (agree_status) {
      mutate(values)
    } else {
      mutate(rest)
    }
  }
  if (state === null) {
    return <Navigate to='/signup' />
  } else {
    return (
      <SignUpContainer>
        <Formik
          validationSchema={validationSchema}
          onSubmit={handleSignUp}
          initialValues={{
            email: state,
            name: '',
            password: '',
            password_confirmation: '',
            agree_status: false,
            redirect: 'edu-mini'
          }}
          enableReinitialize
        >
          {
            ({ values, handleSubmit, errors, touched, handleChange, setFieldValue }) => {
              return (
                <Form autoComplete="" className='max-w-[500px] min-h-full flex flex-col justify-between'>
                  <div className='w-full'>
                    <div
                      className="box-border flex md:hidden mb-[30px] h-[69.5px] w-full cursor-pointer flex-row items-center justify-center "
                      onClick={() => navigate('/')}
                    >
                      <img alt="adnexio EDU" src={eduIcon} />
                    </div>
                    <h2 className='text-h2 mb-[10px] text-left'>Creating account</h2>
                    <p className='text-edu-lightGrayText text-justify md:text-left'>Fill in your name and create a password to continue. By clicking create account, we will send a verification link to your email.</p>
                    <div className='my-[30px] flex flex-col w-full'>
                      <div className='relative'>
                        <EDUTextField
                          name='email'
                          label='Email'
                          disabled
                          value={values?.email}
                          onChange={(e) => {
                            setFieldValue('email', e.target.value)
                            setPayload({ email: e.target.value })
                          }}
                          isErrorShown={touched?.email && errors?.email}
                          errorMessage={touched?.email && errors?.email}
                        />
                        <EDUButton sx={{ position: 'absolute', bottom: 6, right: 0, px: '14px' }} onClick={() => navigate(-1)} variant='text'>CHANGE</EDUButton>
                      </div>
                      <div className="my-[20px]">
                        <EDUTextField
                          name='name'
                          label='Full name'
                          value={values?.name}
                          onChange={handleChange}
                          isErrorShown={touched?.name && errors?.name}
                          errorMessage={touched?.name && errors?.name}
                        />
                      </div>
                      <EDUTextField
                        label='Password'
                        name='password'
                        value={values?.password}
                        onChange={handleChange}
                        type={type}
                        endAdornment={type === 'password' ? <Visibility color='light-gray-text' sx={{ cursor: 'pointer' }} onClick={() => setType('text')} /> : <VisibilityOff color='light-gray-text' sx={{ cursor: 'pointer' }} onClick={() => setType('password')} />}
                        isErrorShown={touched?.password && errors?.password}
                        errorMessage={touched?.password && errors?.password}
                      />
                      <div className="my-[20px]">
                        <EDUTextField
                          label='Confirm password'
                          name='password_confirmation'
                          value={values?.password_confirmation}
                          onChange={handleChange}
                          type={typeConfirmPassword}
                          endAdornment={typeConfirmPassword === 'password' ? <Visibility color='light-gray-text' sx={{ cursor: 'pointer' }} onClick={() => setTypeConfirmPassword('text')} /> : <VisibilityOff color='light-gray-text' sx={{ cursor: 'pointer' }} onClick={() => setTypeConfirmPassword('password')} />}
                          isErrorShown={touched?.password_confirmation && errors?.password_confirmation}
                          errorMessage={touched?.password_confirmation && errors?.password_confirmation}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='w-full'>
                    <div className="flex items-start md:items-center py-3 mb-[10px]">
                      <EDUCheckbox
                        name='agree_status'
                        checked={values?.agree_status}
                        onChange={handleChange}
                        sx={{
                          '&.MuiCheckbox-root': {
                            padding: 0,
                            minHeight: 0
                          },
                          mr: '14px'
                        }}
                      />
                      <p className="text-edu-lightGrayText">Receive news, special offers and recommendations via email</p>
                    </div>
                    <EDUButton type='submit' loading={isLoading} disabled={isLoading} onClick={handleSubmit} variant='contained' fullWidth>
                      Create account
                    </EDUButton>
                  </div>
                </Form>
              )
            }
          }
        </Formik>
      </SignUpContainer>
    )
  }
}

export default CreateAccount