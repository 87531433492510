import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import EDUButton from 'components/Button/EDUButton'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import BannerOne from '../../asset/landingpage_girl.jpg'

const SectionOne = () => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const navigate = useNavigate()
  const handleSignUp = () => {
    navigate('/signup')
  }
  return (
    <>
      {md ? (
        //desktop view
        <Box
          className="m-auto max-w-[1200px]"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: '60px',
          }}
        >
          <Box sx={{ width: '100%', backgroundColor: 'white.main' }}>
            <Typography variant="h1" sx={{ mb: '10px', width: '70%' }}>
              No prior experience necessary to get started
            </Typography>
            <Typography variant="body1" sx={{ mb: '20px', width: '65%' }}>
              Gain the education and experience you need to move forward today with adnexioedu.
            </Typography>
            <EDUButton
              variant="contained"
              onClick={handleSignUp}
            >
              Sign up for free
            </EDUButton>
          </Box>
          <Box
            sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <img src={BannerOne} alt="banner" />
          </Box>
        </Box>
      ) : (
        //mobile view
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: '20px',
          }}
        >
          <img src={BannerOne} alt="banner" />
          <Box sx={{ width: '100%', backgroundColor: 'white.main', mt: '20px' }}>
            <Typography variant="h2" sx={{ mb: '10px', textAlign: 'center' }}>
              No prior experience necessary to get started
            </Typography>
            <Typography variant="body1" sx={{ mb: '20px', textAlign: 'center' }}>
              Gain the education and experience you need to move forward today with adnexioedu.
            </Typography>
            <EDUButton
              variant="contained"
              fullWidth
              sx={{
                mb: '20px',
              }}
              onClick={handleSignUp}
            >
              Sign up for free
            </EDUButton>
          </Box>
        </Box>
      )}
    </>
  )
}

export default SectionOne
