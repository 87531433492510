import { useNavigate, useSearchParams } from 'react-router-dom'
import checkIcon from '../assets/check-icon.svg'
import eduIcon from 'assets/logos/adnexio-edu-logo.svg'
import EDUButton from 'components/Button/EDUButton'

const EmailChangeStatus = () => {
  const navigate = useNavigate()
  const [queryParams] = useSearchParams()
  const email = queryParams.get('email')

  const handleSignin = () => {
    navigate('/signin')
  }

  return (
    <div className="relative bg-edu-defaultBackground  w-screen min-h-screen flex justify-center items-center">
      <div className="absolute top-[42px] w-full p-5 flex justify-center items-center">
        <img onClick={() => navigate('/')} alt="adnexio EDU" src={eduIcon} />
      </div>
      <div className='max-w-[570px] text-center flex flex-col items-center py-[40px] px-[40px] bg-edu-white'>
        <img src={checkIcon} alt='' />
        <h2 className='mt-[30px] mb-[10px] text-h2'>Email successfully changed</h2>
        <p className='text-edu-lightGrayText mb-[30px]'>You email has been changed to <span className='text-edu-darkText font-medium'>{email}</span>. Sign in with your new email to continue using adnexioedu.</p>
        <EDUButton fullWidth variant='contained' onClick={handleSignin}>Sign in</EDUButton>
      </div>
    </div>
  )
}

export default EmailChangeStatus