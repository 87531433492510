import { Link, PictureAsPdf } from '@mui/icons-material'
import { Link as MUILink } from '@mui/material'
import EDUTabPanel from 'components/Tab/EDUTabPanel'
import React from 'react'

const MaterialsTab = ({ label, readingMaterials }) => {
  return (
    <EDUTabPanel label={label}>
      <div className="py-[18px]">
        {
          readingMaterials?.map((material, index) => (
            <div
              key={index}
              className="my-[9px] flex flex-row items-center justify-start space-x-[17px]"
            >
              <div className="rounded-md bg-edu-grayLine p-[9px]">
                {material?.s3_exists === 1 ? <PictureAsPdf /> : <Link />}
              </div>
              <MUILink underline="hover" href={material?.material_url} color="info.main" target="_blank">
                {material?.title}
              </MUILink>
            </div>
          ))
        }
      </div>
    </EDUTabPanel>
  )
}

export default MaterialsTab
