import { PlayCircle } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const CourseCard = ({ data }) => {
  const [hover, setHover] = useState(false)
  const navigate = useNavigate()
  const handleNavigate = (event) => {
    event.preventDefault()
    navigate(`/course/${data?.id}`)
  }
  return (
    <Box
      onClick={handleNavigate}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
      }}
    >
      {/*Image and Play Button */}
      <Box
        sx={{
          width: '100%',
          height: '177px',
          backgroundImage: `url(${data?.course_logo})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      {/*Details and Copywriting */}
      <Box
        sx={{
          width: '100%',
          height: 'calc(100% - 177px)',
          paddingY: '20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ textTransform: 'uppercase', mb: '8px', color: 'information.main' }}
        >
          {data?.course_type}
        </Typography>

        <Typography
          variant="h4"
          sx={{
            textTransform: 'capitalize',
            mb: '6px',
            textDecoration: hover && 'underline',
          }}
        >
          {data?.course_title}
        </Typography>
        {data?.course_duration && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PlayCircle fontSize={'12px'} sx={{ color: 'info.main', mr: 1 }} />
            <Typography variant="subtitle1" color="light-gray-text.main">
              {data?.course_duration} month{data?.course_duration > 1 && 's'}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}