import { BrowserRouter } from 'react-router-dom'

// Contexts
import AuthProvider from 'contexts/AuthContext'
import ProfileContextProvider from 'contexts/ProfileContext'
import NotificationContextProvider from 'contexts/NotificationContext'
import CourseCardContextProvider from 'contexts/CourseCardContext'

//Components
import CustomHelmet from 'components/smart/CustomHelmet'
import MainRoutes from '_routes'

// Set MUI global theme
import { ThemeProvider } from '@mui/material/styles'
import { muiTheme } from 'muiTheme'
import { CssBaseline } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ScrollToTop from 'services/helper/scrollToTop'

function App() {
  const queryClient = new QueryClient()
  return (
    <>
      <CustomHelmet />
      <div className="font-poppins">
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={muiTheme}>
            <CssBaseline />
            <AuthProvider>
              <CourseCardContextProvider>
                <ProfileContextProvider>
                  <NotificationContextProvider>
                    <BrowserRouter>
                      <ScrollToTop />
                      <MainRoutes />
                    </BrowserRouter>
                  </NotificationContextProvider>
                </ProfileContextProvider>
              </CourseCardContextProvider>
            </AuthProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </div>
    </>
  )
}

export default App
