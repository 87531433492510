import { Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import EDUAccordion from 'components/Accordians/EDUAccordion'
import EDUAccordionDetails from 'components/Accordians/EDUAccordionDetails'
import EDUAccordionSummary from 'components/Accordians/EDUAccordionSummary'
import React from 'react'

const SectionSix = () => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const faqArray = [
    {
      title: 'What is adnexioedu',
      description:
        'adnexioedu is an online education platform that provides programmes for non-technical graduates who want to upskill and transition their careers into other disciplines.',
    },
    {
      title: 'What programmes are available on adnexioedu?',
      description: 'adnexioedu offers two programmes; adnexioedu Mini and adnexioedu Bootcamp.',
    },
    {
      title: 'What is adnexioedu Mini?',
      description:
        ' A short and snappy online course. The average mini course takes 190 minutes or less to complete and focuses on helping students learn a specific skill or concept.',
    },
    {
      title: 'What is adnexioedu Bootcamp?',
      description:
        'An accelerated, intensive study programme that allows students to learn in-demand skills sought by recruiters. The average duration of the adnexioedu Bootcamp programme is 2-4 months, depending on the course lesson plan. The learning mode is online, where modules can be accessed at your convenience, and at the pace that suits you, while still interacting with your peers and instructors via weekly live classes.',
    },
    {
      title: 'Will I receive a Certificate?',
      description:
        'Yes! Upon passing the course, you will receive a smart digital certificate at the end of the program. The smart digital certificate can be shared with schools or potential employers. You can incorporate it into your cover letter, resume, and/or LinkedIn profile.',
    },
    {
      title: 'Are there any prerequisites to join the program?',
      description:
        'The courses offered by adnexioedu are a beginner-friendly programme with no prerequisites. That means that anyone from any background can apply! We welcome you to join the course as long as you have a genuine interest, capacity, and self-motivation to commit to the course learning journey.',
    },
    {
      title: 'Can I work full-time while enrolled in adnexioedu course?',
      description:
        'Yes! All of our courses are designed for busy professionals with full-time work commitments. For bootcamp programme, you’ll be expected to spend time working on assignments and projects outside of class each week, but the workload is designed to be manageable with a full-time job. If you need to miss one or more days of a live class session, we provide resources to help you catch up.',
    },
    {
      title: 'Payment',
      description: 'All payment can be made with debit/credit card OR via eGHL payment gateway',
    },
  ]
  return (
    <Box
      className="m-auto max-w-[1200px]"
      sx={{
        px: md ? '60px' : '20px',
        py: md ? '60px' : '40px',
        backgroundColor: 'white.main',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant={md ? 'h1' : 'h2'} sx={{ textAlign: md ? 'none' : 'center', mb: '40px' }}>
        Frequently Asked Questions (FAQ)
      </Typography>
      {faqArray.map((item, index) => {
        return (
          <EDUAccordion
            key={index}
            sx={{
              borderTop: '1px solid',
              borderLeft: '1px solid',
              borderRight: '1px solid',
              borderBottom: index + 1 === faqArray.length && '1px solid',
              width: md ? '80%' : '100%',
              borderColor: 'gray-line.main',
              '&:not(:last-child)': {
                marginBottom: '0px',
              },
              px: '20px',
            }}
          >
            <EDUAccordionSummary
              sx={{
                backgroundColor: 'white.main',
                padding: 0,
                '& .MuiAccordionSummary-content': {
                  my: 0
                },
                '&.MuiAccordionSummary-root': {
                  minHeight: 0,
                  py: '15px'
                }
              }}
            >
              <Typography variant={md ? 'h3' : 'h4'}>{item.title}</Typography>
            </EDUAccordionSummary>

            <EDUAccordionDetails sx={{ padding: 0, pb: '15px' }}>
              <Typography sx={{ color: 'light-gray-text.main' }}>
                {item.description}
              </Typography>
            </EDUAccordionDetails>
          </EDUAccordion>
        )
      })}
    </Box>
  )
}

export default SectionSix
