import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { PlayArrow } from '@mui/icons-material'
import EDUProgressBar from 'components/ProgressBar/EDUProgressBar'

const EDUVideoCard = ({ data }) => {
  const theme = useTheme()
  const [hover, setHover] = useState(false)
  const md = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Box
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      sx={{
        width: '100%',
        backgroundColor: 'white.main',
        display: 'flex',
        flexDirection: md ? 'row' : 'column',
        alignItems: 'center',
      }}
    >
      {/*Image and Play Button */}
      <Box
        sx={{
          width: md ? '25%' : '100%',
          height: '177px',
          backgroundImage: `url(${data?.course_logo})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {hover && (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative'
            }}
          >
            <div className='absolute top-0 left-0 w-full h-full bg-edu-darkText opacity-50' />
            <PlayArrow
              sx={{
                fontSize: '60px',
                color: 'white.main',
                zIndex: 10
              }}
            />
          </Box>
        )}
      </Box>
      {/*Details and Copywriting */}
      <Box
        sx={{
          width: '100%',
          paddingLeft: md ? '20px' : null,
          paddingY: '20px',
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ textTransform: 'uppercase', color: 'information.main', mb: '6px' }}
        >
          {data?.course_type}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            textTransform: 'capitalize',
            textDecoration: hover && 'underline',
          }}
        >
          {data?.course_title}
        </Typography>
        <Typography variant="body1" sx={{ color: 'light-gray-text.main' }}>
          by {data?.trainer_name}
        </Typography>
        <EDUProgressBar
          value={data?.overall_progress}
          sx={{ height: '8px', mt: '30px' }}
        />
        <div className="mt-[10px] flex flex-col-reverse items-start justify-between md:flex-row md:items-center">
          <Typography
            variant="body1"
            sx={{ textTransform: 'capitalize', color: 'light-gray-text.main' }}
          >
            Next up: {data?.upcoming}
          </Typography>
          <Typography
            variant="body1"
            sx={{ textTransform: 'capitalize', color: 'light-gray-text.main' }}
          >
            {Math.round(data?.overall_progress)}% Complete
          </Typography>
        </div>
      </Box>
    </Box>
  )
}

export default EDUVideoCard
