import axios, { getData } from 'lib/axios'

export const pendingPayment = async () => {
  const { data } = await axios.get(`adx_edu_payment/dashboard/next_payment?course_type=course`).then(getData)
  return data
}

export const paymentDasboard = async ({ type, page = 1, limit = 100 }) => {
  // query params course_type is temporary until we integrate with bootcamp as well. The page and limit as well need to refactor to make it more flexible based on pagination or infinite loading
  const { data } = await axios.get(`adx_edu_payment/dashboard/summary?type=${type}&page=${page}&per_page=${limit}&course_type=course`).then(getData)
  return data
}

export const detailPayment = async (enrolment_id) => {
  const { data } = await axios.get(`adx_edu_payment/dashboard/summary/${enrolment_id}`).then(getData)
  return data
}

export const paymentSummary = async (course_id) => {
  const { data } = await axios.get(`adx_edu_payment/nex_edu_course/${course_id}`).then(getData)
  return data
}

export const paymenReceipt = async (order_id) => {
  return axios.get(`adx_edu_payment/receipt/${order_id}`, { responseType: 'blob' })
}

export const firstPayment = async (payload) => {
  return axios.post(`adx_edu_payment/make_payment`, payload).then(getData)
}

export const installmentPayment = async (payload) => {
  return axios.post(`adx_edu_payment/make_installment_payment`, payload).then(getData)
}
