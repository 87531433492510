import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { forwardRef } from 'react'

const IconTooltipButton = forwardRef(({ onClick, toolTipLabel, toolTipPlacement = 'top', children }, ref) => {
  return (
    <Tooltip ref={ref} placement={toolTipPlacement} title={toolTipLabel}>
      <IconButton onClick={onClick}>{children}</IconButton>
    </Tooltip>
  )
})

export default IconTooltipButton