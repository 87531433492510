import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material'
import { useState } from 'react'
import { useEffect } from 'react'

const EDUFormControlLabel = ({
  type,
  control,
  disabled,
  label,
  value,
  selected,
  description,
  sx,
  ...rest
}) => {
  const { palette } = useTheme()
  const [isSelected, setIsSelected] = useState(false)

  useEffect(() => {
    if (value === selected) {
      setIsSelected(true)
    } else {
      setIsSelected(false)
    }
  }, [value, selected])

  return (
    <FormControlLabel
      control={control}
      disabled={disabled}
      value={value}
      label={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {label}
        </Box>
      }
      {...rest}
      sx={{
        '& .MuiFormControlLabel-label': {
          width: '100%',
          '&.Mui-disabled': {
            color: 'gray-inactive-button.main',
          },
        },
        borderRadius: '4.5px',
        border: type === 'outlined' ? 1 : 0,
        borderColor: isSelected ? palette.secondary.main : palette['light-gray-text'].main,
        mx: 0,
        boxSizing: 'border-box',
        my: '5px',
        px: '14px',
        py: 1.5,
        ...sx,
      }}
    />
  )
}

export default EDUFormControlLabel
