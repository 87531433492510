import Bugsnag from '@bugsnag/js'
import { ReactComponent as NotificationIcon } from 'assets/icons/notificationIcon.svg'
import EDUButton from 'components/Button/EDUButton'
import EDUNoData from 'components/NoData/EDUNoData'
import { NotificationContext } from 'contexts/NotificationContext'
import { useContext } from 'react'
import { notificationRead } from 'services/api/notification'

const Notifications = () => {
  const { eduNotification, getNotification, readNotification } = useContext(NotificationContext)
  const handleRead = async (id) => {
    try {
      await notificationRead(id).then(async () => await getNotification({ isEdu: true }))
    } catch (e) {
      Bugsnag.notify(e)
    }
  }
  return (
    <div className='m-auto px-[30px] max-w-[800px]'>
      {
        (readNotification?.data === null || readNotification?.data?.length < 1) && (eduNotification?.data === null || eduNotification?.data?.length < 1) ?
          <EDUNoData title="You don't have any notification yet" sxContainer={{
            mt: 10
          }} sxIcon={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} icon={<NotificationIcon className='fill-edu-lightGrayText02 h-[40px]' />} /> :
          <div>
            <h4 className='text-h4 text-edu-lightGrayText my-[21.5px]'>Notification</h4>
            {
              (eduNotification?.data !== null || eduNotification?.data?.length > 0) && eduNotification?.data?.map((notty, index) => (
                <div onClick={() => handleRead(notty?.id)} key={index} className='relative flex items-start py-[14px] rounded cursor-pointer overflow-auto'>
                  <div className='py-[10px] px-[12px] rounded-full bg-edu-lightYellow mr-[14px]'>
                    <NotificationIcon className='fill-edu-primary' />
                  </div>
                  <div className='flex flex-col'>
                    <p>{notty?.message}</p>
                    <p className='text-body2 text-edu-lightGrayText mt-[5px]'>{notty?.time_stamp}</p>
                  </div>
                  <div className='absolute bottom-[14px] right-[10px] w-[10px] h-[10px] rounded-full bg-edu-secondary' />
                </div>
              ))
            }
            {
              (readNotification?.data !== null || readNotification?.data?.length > 0) && readNotification?.data?.map((notty, index) => (
                <div key={index} className='relative flex items-start py-[14px] rounded cursor-default'>
                  <div className='py-[10px] px-[12px] rounded-full bg-edu-lightYellow mr-[14px]'>
                    <NotificationIcon className='fill-edu-primary' />
                  </div>
                  <div className='flex flex-col'>
                    <p className='text-edu-lightGrayText'>{notty?.message}</p>
                    <p className='text-body2 text-edu-lightGrayText mt-[5px]'>{notty?.time_stamp}</p>
                  </div>
                </div>
              ))
            }
            <div className='flex items-center justify-center my-[30px]'>
              <EDUButton variant='contained' disabled>No more notification to show</EDUButton>
            </div>
          </div>
      }
    </div>
  )
}

export default Notifications