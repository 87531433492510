import { Cancel, CheckCircle } from '@mui/icons-material'
import React from 'react'

const EDUFormAnswerLabel = ({ label, value, answer, selected }) => {
  return (
    <div
      className={`my-[5px] flex w-full items-center rounded-[4.5px] px-[14px] py-3 ${answer === value && value === selected && 'border border-edu-success'
        } ${answer !== value && value === selected && 'border border-edu-error'} ${answer === value && value !== selected && 'bg-edu-defaultBackground'
        } `}
    >
      {answer === value && value === selected && <CheckCircle color="success" />}
      {answer !== value && value === selected && <Cancel color="error" />}
      {value !== selected && (
        <div className={`h-[18px] w-[18px] rounded-full border border-edu-grayLine `} />
      )}
      <div className=" ml-[14px] flex w-full items-center justify-between">
        <p className={`${answer !== value && value !== selected && 'text-edu-lightGrayText'}`}>
          {label}
        </p>
        {answer === value && value !== selected && <CheckCircle color="success" />}
      </div>
    </div>
  )
}

export default EDUFormAnswerLabel
