import Helmet from 'react-helmet'

function CustomHelmet(props) {
  const {
    title = 'adnexio | EDU',
    description = "Find the latest Vacancies in Malaysia. Get hired instantly. Get headhunted. We analyse vacancies every day to find the perfect career match for you using artificial intelligence. Malaysia's Best Job Portal.",
    url = 'https://adnexio.jobs/',
    image = 'https://adnexio-prod.s3.ap-southeast-1.amazonaws.com/adnexio_meta_images/meta-image.png',
  } = props

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* Facebook */}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      {/* Twitter */}
      <meta property="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
    </Helmet>
  )
}

export default CustomHelmet
