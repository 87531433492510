import PaymentHeader from 'components/Header/PaymentHeader'
import React, { useEffect } from 'react'
import { CheckCircle, Cancel } from '@mui/icons-material/'

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import EDUButton from 'components/Button/EDUButton'
import valueToStringConversion from 'services/helper/valueToStringConversion'
import dateConverter from 'services/helper/dateConverter'
import { useQuery } from '@tanstack/react-query'
import { paymenReceipt } from 'services/api/payment'
import Bugsnag from '@bugsnag/js'

const PaymentStatus = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const md = useMediaQuery(theme.breakpoints.up('md'))

  const [queryParams] = useSearchParams()
  const status = queryParams.get('status')
  const course_name = queryParams.get('course_name')
  const orderId = queryParams.get('order_id')
  const amount = queryParams.get('amount_paid')
  const paymentDate = queryParams.get('payment_date')
  const email = queryParams.get('email')

  const { refetch, isFetching } = useQuery(['getPaymentReceipt', orderId], async () => paymenReceipt(orderId), {
    enabled: false,
    onSuccess: (res) => {
      const receiptName = res.headers['content-disposition'].substring(21)
      const element = document.createElement('a')
      const file = new Blob([res.data], {
        type: res.headers["content-type"],
      })
      element.href = URL.createObjectURL(file)
      element.download = `${receiptName}`
      document.body.appendChild(element)
      element.click()
    },
    onError: (err) => {
      Bugsnag.notify(err)
    }
  })

  useEffect(() => {
    const isStatusValid = ['success', 'fail', 'error'].includes(status)
    if (!isStatusValid) {
      navigate('/payment-dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const handleNavigate = () => {
    navigate('/payment-dashboard')
  }

  const handleDownloadReceipt = () => {
    if (status === 'success') {
      refetch()
    } else {
      window.print()
    }
  }
  return (
    <div className="min-h-screen bg-edu-defaultBackground">
      <PaymentHeader />
      <div className="mx-auto max-w-3xl bg-edu-white p-[30px]">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column', minHeight: md ? 'calc(100vh - 131px)' : 'calc(100vh - 121px)' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mb: 2 }}>
            {status === 'success' ? (
              <CheckCircle sx={{ fontSize: '65px', color: 'success.main' }} />
            ) : (
              <Cancel sx={{ fontSize: '65px', color: 'error.main' }} />
            )}

            <Typography variant="h3" sx={{ mt: '30px', mb: '20px' }}>
              {status === 'success' ? 'Payment Received' : 'Payment Unsuccessful'}
            </Typography>
            {status === 'success' ? (
              <Typography
                variant="body1"
                sx={{ mb: '30px', textAlign: 'center', color: 'light-gray-text.main' }}
              >
                Thank you, your payment for order ID below has been successfully received. <br />A
                confirmation has been sent to {email}
              </Typography>
            ) : (
              <Typography
                variant="body1"
                sx={{ mb: '30px', textAlign: 'center', color: 'light-gray-text.main' }}
              >
                Thank you for your attempted payment. Unfortunately, your payment for order ID below
                cannot be processed. Please try again.
              </Typography>
            )}
            <Box
              sx={{
                width: '100%',
                backgroundColor: 'default-background.main',
                borderRadius: '4.5px',
                p: '20px',

                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: sm ? 'row' : 'column' }}>
                <Typography
                  variant="body1"
                  sx={{ width: sm ? '150px' : '100%', color: 'light-gray-text.main' }}
                >
                  Order ID
                </Typography>
                <Typography variant="body1" sx={{ width: sm ? '70% ' : '100%', color: 'light-gray-text.main' }}>
                  {orderId}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: sm ? 'row' : 'column' }}>
                <Typography
                  variant="body1"
                  sx={{ width: sm ? '150px ' : '100%', color: 'light-gray-text.main' }}
                >
                  Course name
                </Typography>
                <Typography variant="body1" sx={{ width: sm ? '70% ' : '100%', color: 'light-gray-text.main' }}>
                  {course_name}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: sm ? 'row' : 'column' }}>
                <Typography
                  variant="body1"
                  sx={{ width: sm ? '150px ' : '100%', color: 'light-gray-text.main' }}
                >
                  Amount paid
                </Typography>
                <Typography variant="body1" sx={{ width: sm ? '70% ' : '100%', color: 'light-gray-text.main' }}>
                  RM {valueToStringConversion(amount)}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: sm ? 'row' : 'column' }}>
                <Typography
                  variant="body1"
                  sx={{ width: sm ? '150px ' : '100%', color: 'light-gray-text.main' }}
                >
                  Payment date
                </Typography>
                <Typography variant="body1" sx={{ width: sm ? '70% ' : '100%', color: 'light-gray-text.main' }}>
                  {dateConverter(paymentDate)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 3,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              rowGap: '10px',
            }}
          >
            <Typography variant="body1" sx={{ color: 'light-gray-text.main' }}>
              Need help?{' '}
              <span
                onClick={() => navigate('/contact-us')}
                className="cursor-pointer text-edu-secondary underline"
              >
                Contact us
              </span>{' '}
              for inquiries
            </Typography>
            <EDUButton fullWidth variant="contained" onClick={handleNavigate}>
              Return
            </EDUButton>
            <EDUButton loading={isFetching} disabled={isFetching} fullWidth variant="outlined" onClick={handleDownloadReceipt}>
              {status === 'success' ? 'Download receipt' : 'Print'}
            </EDUButton>
          </Box>
        </Box>
      </div>
    </div>
  )
}

export default PaymentStatus
