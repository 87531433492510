import Bugsnag from '@bugsnag/js'
import { FormControl, RadioGroup } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import EDUButton from 'components/Button/EDUButton'
import EDUFormAnswerLabel from 'components/FormLabel/EDUFormAnswerLabel'
import EDUFormControlLabel from 'components/FormLabel/EDUFormControlLabel'
import EDURadio from 'components/Radio/EDURadio'
import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getRecapAnswer, quizSubmission } from 'services/api/quiz'

const RecapQuestions = ({ questionState, setQuestionState, quiz_id, enrollment_id, courseState }) => {
  const { question, ...rest } = questionState
  const [radioValue, setRadioValue] = useState(null)
  const [answer, setAnswer] = useState()
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const handleChangeRadio = (e) => {
    setRadioValue(parseInt(e.target.value))
  }

  const { refetch } = useQuery(['recapAnswer', question && question[page]?.id], async () => await getRecapAnswer(question[page]?.id), {
    enabled: false,
    onSuccess: (res) => {
      setQuestionState({
        ...rest,
        question: question?.map((item, index) => {
          return {
            ...item,
            attempt: index <= page ? true : false
          }
        })
      })
      setAnswer(res?.find((obj) => obj?.is_correct === true)?.id)
    }
  })

  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation(async (val) => await quizSubmission(quiz_id, val), {
    onSuccess: () => {
      queryClient.invalidateQueries(['moduleDetails', enrollment_id])
      navigate(`/my-learning/${enrollment_id}`, { state: courseState })
    }, onError: (err) => Bugsnag.notify(err)
  })

  const handleCheck = () => {
    refetch()
  }

  const handleNextQuestion = () => {
    setRadioValue(null)
    setPage((prev) => prev + 1)
  }

  const handleSubmit = () => {
    mutate({
      enrolment_id: enrollment_id,
    })
  }

  const handleReset = () => {
    setPage(0)
    setRadioValue(null)
    setQuestionState({
      ...rest,
      question: question?.map((item) => {
        return {
          ...item,
          attempt: false
        }
      })
    })
  }
  return (
    <div className='mx-auto flex-1 flex flex-col min-w-[700px] max-w-3xl'>
      <div className="bg-edu-white p-[30px] flex-1 h-full">
        <div className='flex flex-col'>
          <p className='mb-[30px]'>{question && question[page]?.title}</p>
          {
            question &&
            question[page]?.answers?.map((opt, optIndex) => question[page]?.attempt === false ?
              <FormControl key={optIndex}>
                <RadioGroup value={radioValue} onChange={handleChangeRadio} >
                  <EDUFormControlLabel
                    value={opt?.id}
                    control={<EDURadio />}
                    label={opt?.answer}
                  />
                </RadioGroup>
              </FormControl>
              :
              <EDUFormAnswerLabel
                key={optIndex}
                selected={radioValue}
                value={opt?.id}
                answer={answer}
                label={opt?.answer}
              />
            )
          }
        </div>
        {
          question && question[page]?.attempt === true &&
          <div className='w-full bg-edu-defaultBackground mt-[10px] py-[12px] px-[14px] rounded-[4.5px]'>
            <p className={`text-subtitle ${answer === radioValue ? 'text-edu-success' : 'text-edu-error'}`}>{answer === radioValue ? 'Correct' : 'Incorrect'}</p>
          </div>
        }

      </div>
      <div className='flex items-center justify-end pt-[20px] pb-[30px] gap-3'>
        {
          (question?.length === page + 1) && question && question[page]?.attempt === true && <EDUButton onClick={handleReset} variant='outlined' sx={{ width: '260px' }}>Take again</EDUButton>
        }
        {
          ((question?.length === page + 1) && question && question[page]?.attempt === true) ? <EDUButton onClick={handleSubmit} loading={isLoading} disabled={isLoading} variant='contained' sx={{ width: '260px' }}>
            End quiz
          </EDUButton> : question && question[page]?.attempt === false ? <EDUButton onClick={handleCheck} disabled={radioValue === null} variant='contained' sx={{ width: '260px' }}>
            Check
          </EDUButton> : <EDUButton onClick={handleNextQuestion} variant='contained' sx={{ width: '260px' }}>
            Next
          </EDUButton>
        }
      </div>
    </div>
  )
}

export default RecapQuestions