import { Box, Typography, Grid, useMediaQuery, useTheme, Skeleton } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import EDUButton from 'components/Button/EDUButton'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getCourseDashboard } from 'services/api/dashboard'
import { CourseCard } from './CourseCard'
import Bugsnag from '@bugsnag/js'

const SectionTwo = () => {
  // const courseArray = [{
  //   id: 1,
  //   trainer_id: 4,
  //   trainer_name: "Darth Vader",
  //   trainer_photo: null,
  //   course_logo: "https://adnexio-dev.s3.ap-southeast-1.amazonaws.com/edu_course_logo/01_SEC_Logo-12.png",
  //   course_type: "BOOTCAMP",
  //   course_title: "Jedi Master Training",
  //   course_fee: 10000,
  //   course_duration: 2,
  //   discount_fee: 9500,
  //   discount_value: 500,
  //   discount_type: "amount",
  //   countdown: null,
  //   total: 1,
  //   isPurchased: true,
  //   spots_remaining: 25
  // }, {
  //   id: 2,
  //   trainer_id: 4,
  //   trainer_name: "Darth Vader",
  //   trainer_photo: null,
  //   course_logo: "https://adnexio-dev.s3.ap-southeast-1.amazonaws.com/edu_course_logo/01_SEC_Logo-12.png",
  //   course_type: "BOOTCAMP",
  //   course_title: "Jedi Master Training",
  //   course_fee: 10000,
  //   course_duration: 2,
  //   discount_fee: 9500,
  //   discount_value: 500,
  //   discount_type: "amount",
  //   countdown: null,
  //   total: 1,
  //   isPurchased: true,
  //   spots_remaining: 25
  // }, {
  //   id: 3,
  //   trainer_id: 4,
  //   trainer_name: "Darth Vader",
  //   trainer_photo: null,
  //   course_logo: "https://adnexio-dev.s3.ap-southeast-1.amazonaws.com/edu_course_logo/01_SEC_Logo-12.png",
  //   course_type: "BOOTCAMP",
  //   course_title: "Jedi Master Training",
  //   course_fee: 10000,
  //   course_duration: 2,
  //   discount_fee: 9500,
  //   discount_value: 500,
  //   discount_type: "amount",
  //   countdown: null,
  //   total: 1,
  //   isPurchased: true,
  //   spots_remaining: 25
  // }]
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const navigate = useNavigate()
  const handleMoreCourses = () => {
    navigate('/explore-courses')
  }
  const {
    isLoading,
    data: courseArray,
  } = useQuery(
    ['courseListDashboard'],
    async () => {
      const {
        list: { data: recommendedCourse },
      } = await getCourseDashboard()
      return { recommendedCourse, recentVideo: null }
    },
    {
      onError: (err) => Bugsnag.notify(err),
    }
  )

  return (
    <Box
      className="m-auto max-w-[1200px]"
      sx={{
        display: 'flex',
        p: md ? '60px' : '20px',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant={md ? 'h1' : 'h2'} sx={{ mb: '20px', textAlign: md ? 'none' : 'center' }}>
        Learn most In-Demand Skills
      </Typography>
      <EDUButton
        variant="outlined"
        sx={{
          mb: '30px',
          width: md ? 'none' : '100%',
        }}
        onClick={handleMoreCourses}
      >
        Explore more courses
      </EDUButton>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Grid container rowSpacing={4} columnSpacing={8}>
          {
            isLoading ? [1, 1, 1].map((_, index) => (
              <Grid item key={index} xs={12} md={6} lg={4}>
                <Skeleton variant='rectangle' width='100%' height='177px' />
                <Box
                  sx={{
                    paddingY: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}
                >
                  <Skeleton width='30%' />
                  <Skeleton width='60%' />
                  <Skeleton width='40%' />
                </Box>
              </Grid>
            )) : courseArray?.recommendedCourse?.slice(0, 3).map((data, index) => {
              return (
                <Grid item key={index} xs={12} md={6} lg={4}>
                  <CourseCard data={data} />
                </Grid>
              )
            })
          }
        </Grid>
      </Box>
    </Box>
  )
}

export default SectionTwo
