import { CheckCircle, Edit, PlayArrow } from '@mui/icons-material'
import { Skeleton } from '@mui/material'
import EDUAccordion from 'components/Accordians/EDUAccordion'
import EDUAccordionDetails from 'components/Accordians/EDUAccordionDetails'
import EDUAccordionSummary from 'components/Accordians/EDUAccordionSummary'
import EDUCheckbox from 'components/Checkbox/EDUCheckbox'
import React from 'react'
import timeConversion from 'services/helper/timeConversion'

const LessonPlanAccordion = ({
  courseDetails,
  courseState,
  setCourseSession,
  expanded,
  setExpanded,
  isLoading,
}) => {
  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  return (
    <>
      <p className="hidden border-b border-edu-grayLine py-[10px] text-subtitle text-edu-lightGrayText lg:block">
        Lessons
      </p>
      {isLoading
        ? [1, 1, 1].map((_, index) => (
          <Skeleton key={index} variant="rectangular" width="100%" height="80px" sx={{ my: 1 }} />
        ))
        : courseDetails?.map((topic, topicIndex) => {
          let totalDuration = timeConversion(topic?.total_duration)
          return (
            <EDUAccordion
              key={topicIndex}
              expanded={expanded === topic?.id}
              onChange={handleChange(topic?.id)}
              sx={{ boxSizing: 'border-box' }}
            >
              <EDUAccordionSummary sx={{ backgroundColor: 'white.main' }}>
                <div className="flex flex-col items-start justify-start space-y-[4px]">
                  <p className="text-subtitle">{topic?.topic_title}</p>
                  {topic?.is_completed ? (
                    <div className="flex items-center space-x-[6px]">
                      <CheckCircle sx={{ color: 'success.main', fontSize: 16 }} />
                      <p className="text-body2 text-edu-lightGrayText">Completed</p>
                    </div>
                  ) : (
                    <p className="text-body2">
                      {totalDuration?.hours !== '00' && `${totalDuration?.hours.slice(-1, 2)}h`}{' '}
                      {totalDuration?.minutes && `${totalDuration?.minutes}min`}
                    </p>
                  )}
                </div>
              </EDUAccordionSummary>
              {topic?.course_sessions?.map((subtopic, subtopicIndex) => {
                let { hours, minutes } = timeConversion(subtopic?.video?.duration)
                return (
                  <div key={subtopicIndex}>
                    {subtopic?.video && (
                      <EDUAccordionDetails
                        sx={{
                          '&:hover': {
                            backgroundColor: 'default-background.main',
                          },
                          backgroundColor:
                            courseState?.type === 'video' &&
                              subtopic?.video?.id === courseState?.id
                              ? 'default-background.main'
                              : 'white.main',
                          borderLeft: 3,
                          borderColor:
                            courseState?.type === 'video' &&
                              subtopic?.video?.id === courseState?.id
                              ? 'primary.main'
                              : 'white.main',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          setCourseSession({
                            id: subtopic?.video?.id,
                            topic: topic?.topic_title,
                            subtopic: subtopic?.video,
                            material: topic?.material,
                            overview: topic?.topic_description,
                            type: 'video',
                            next:
                              subtopic?.quiz ||
                              topic?.course_sessions[subtopicIndex + 1]?.video ||
                              topic?.course_sessions[subtopicIndex + 1]?.quiz,
                          })
                        }
                      >
                        <div className="flex w-full flex-row items-center justify-between space-x-6">
                          <div className="flex flex-row items-center justify-start space-x-4">
                            <div className="rounded-full bg-edu-lightYellow p-[9px]">
                              <PlayArrow sx={{ color: 'primary.main' }} />
                            </div>
                            <div className="flex flex-col items-start justify-start space-y-[2px]">
                              <p>{subtopic?.video?.title}</p>
                              <p className="text-body2 text-edu-lightGrayText">
                                {hours !== '00' && `${hours.slice(-1, 2)}h`}{' '}
                                {minutes && `${minutes}min`}
                              </p>
                            </div>
                          </div>
                          <EDUCheckbox
                            disableRipple
                            disabled
                            checked={Boolean(subtopic?.video?.is_completed)}
                            sx={{
                              '&.Mui-checked': {
                                color: 'success.main',
                              },
                              '&.Mui-disabled': {
                                color: 'none',
                              },
                            }}
                          />
                        </div>
                      </EDUAccordionDetails>
                    )}
                    {subtopic?.quiz && (
                      <EDUAccordionDetails
                        sx={{
                          '&:hover': {
                            backgroundColor: 'default-background.main',
                          },
                          backgroundColor:
                            courseState?.type !== 'video' &&
                              subtopic?.quiz?.setupable_id === courseState?.subtopic?.setupable_id
                              ? 'default-background.main'
                              : 'white.main',
                          borderLeft: 3,
                          borderColor:
                            courseState?.type !== 'video' &&
                              subtopic?.quiz?.setupable_id === courseState?.subtopic?.setupable_id
                              ? 'primary.main'
                              : 'white.main',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          setCourseSession({
                            id: subtopic?.quiz?.id,
                            topic: topic?.topic_title,
                            subtopic: subtopic?.quiz,
                            material: topic?.material,
                            overview: topic?.topic_description,
                            type: subtopic?.quiz?.type === 'RECAP' ? 'recap' : 'graded',
                            next:
                              topic?.course_sessions?.length !== subtopicIndex + 1
                                ? topic?.course_sessions[subtopicIndex + 1]?.video ||
                                topic?.course_sessions[subtopicIndex + 1]?.quiz
                                : courseDetails[topicIndex + 1]?.course_sessions[0]?.video,
                          })
                        }
                      >
                        <div className="flex w-full flex-row items-center justify-between space-x-6">
                          <div className="flex flex-row items-center justify-start space-x-4">
                            <div className="rounded-full bg-edu-lightYellow p-[9px]">
                              <Edit sx={{ color: 'primary.main' }} />
                            </div>
                            <div className="flex flex-col items-start justify-start space-y-[2px]">
                              <p>
                                {subtopic?.quiz?.type === 'RECAP'
                                  ? `Recap quiz: ${subtopic?.quiz?.title}`
                                  : `Graded quiz: ${subtopic?.quiz?.title}`}
                              </p>
                              <p className="text-body2 text-edu-lightGrayText">Quiz</p>
                            </div>
                          </div>
                          <EDUCheckbox
                            disableRipple
                            disabled
                            checked={Boolean(subtopic?.quiz?.is_completed)}
                            sx={{
                              '&.Mui-checked': {
                                color: 'success.main',
                              },
                              '&.Mui-disabled': {
                                color: 'none',
                              },
                            }}
                          />
                        </div>
                      </EDUAccordionDetails>
                    )}
                  </div>
                )
              })}
            </EDUAccordion>
          )
        })}
    </>
  )
}

export default LessonPlanAccordion
