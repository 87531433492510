import EDUTabBadge from "components/Tab/EDUTabBadge"
import CertificateTab from "../components/CetificateTab"
import AboutTab from "../components/AboutTab"
import profileBackground from '../assets/banner_profile.png'
import { Box, Divider, Tooltip, useMediaQuery, useTheme } from "@mui/material"
import { CameraAlt, Close, Edit } from "@mui/icons-material"
import { ProfileContext } from "contexts/ProfileContext"
import { useCallback, useContext, useRef, useState } from "react"
import dateConverter from "services/helper/dateConverter"
import { useNavigate } from "react-router-dom"
import EDUTextField from "components/TextField/EDUTextField"
import EDUModal from "components/Modal/EDUModal"
import { Form, Formik } from "formik"
import * as yup from 'yup'
import EDUDropzone from "components/Dropzone/EDUDropzone"
import EDUButton from "components/Button/EDUButton"
import EDUPhoneNumber from "components/PhoneNumber/EDUPhoneNumber"
import { signUrl } from "services/api/storage"
import axios from "axios"
import { jobseekerImage, updateProfile } from "services/api/profile"
import Bugsnag from "@bugsnag/js"
import { useMutation } from "@tanstack/react-query"
import profileIconImage from 'assets/icons/profile.svg'

const Profile = () => {
  const { profileData, retrieveProfile } = useContext(ProfileContext)
  const [open, setModalOpen] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [files, setFiles] = useState({})
  const navigate = useNavigate()
  const dropzoneRef = useRef()
  const formikRef = useRef()
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const validationSchema = yup.object().shape({
    name: yup.string().required('Full name is required'),
    country_id: yup.string().required('Phone code is required'),
    email: yup.string().email('Please use correct email format').required('Email is required'),
    contact_no: yup.string().matches(/^[0-9]+$/, 'Field must be an integer number').required('Phone number is required')
  })
  const handleClose = () => {
    setModalOpen(false)
    setFiles({})
    setErrorMsg('')
  }
  const handleOpen = () => {
    setModalOpen(true)
  }

  const navigateSetting = () => {
    navigate('/account-settings')
  }

  const handleUpload = useCallback(async (acceptedFiles, fileRejections) => {
    if (fileRejections.length && !acceptedFiles.length) {
      setErrorMsg(fileRejections[0]?.errors[0]?.code === "file-invalid-type" ? 'Failed to upload. Wrong file format.' : fileRejections[0]?.errors[0]?.code === "file-too-large" ? `Failed to upload. "File is larger than 2MB"` : `Failed to upload. ${fileRejections[0]?.errors[0]?.message}`);
      return;
    } else {
      setErrorMsg('')
    }
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    formikRef.current.setFieldValue('profile_image', acceptedFiles[0])
    // eslint-disable-next-line
  }, [])

  const { mutate, isLoading } = useMutation(updateProfile, {
    onSuccess: () => {
      retrieveProfile()
      handleClose()
    }, onError: (error) => {
      if (error.status === 422) {
        const errors = error.data.errors
        Object.keys(errors).forEach(function (key) {
          formikRef.current.setFieldError(key, errors[key][0])
        })
      } else {
        Bugsnag.notify(error)
      }
    }
  })

  const submitDetails = async (values) => {
    const { country_id, contact_no, email, profile_image, ...rest } = values
    let deformatCode = JSON.parse(country_id)
    let data = {
      country_id: deformatCode?.id,
      contact_no: parseInt(contact_no),
      ...rest
    }
    try {
      if (profile_image?.name) {
        const results = await signUrl({
          bucket: process.env.REACT_APP_AWS_BUCKET,
          key: 'profile_photo/' + profile_image.name,
        })
        const awsRequest = axios.create()
        delete awsRequest.defaults.headers.common['Authorization']
        await awsRequest.put(results.data.url, profile_image, {
          headers: {
            'Content-Type': profile_image.type,
          },
        })
        await jobseekerImage({ profile_image: profile_image.name })
      }
      mutate(data)
    } catch (error) {
      Bugsnag.notify(error)
    }
  }
  return (
    <div>
      <div className="flex flex-col lg:flex-row w-full lg:h-[calc(100vh-70.5px)] ">
        <div
          style={{ backgroundImage: `url(${profileBackground})` }}
          className="flex flex-col items-center justify-start w-full h-full p-8 bg-center bg-no-repeat bg-cover lg:w-1/3"
        >
          <div className='relative'>
            <Box
              sx={{
                width: '150px',
                height: '150px',
                borderRadius: '100%',
                backgroundImage: `url(${profileData?.profile_photo || profileIconImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundColor: 'white.main',
                border: 2,
                borderColor: 'white.main'
              }}
            />
            <Tooltip title='Edit profile' placement="bottom-start">
              <div onClick={handleOpen} className='absolute bottom-0 right-[6px] cursor-pointer rounded-full border-[3px] border-edu-white flex justify-center items-center bg-edu-primary p-[8px]'>
                <Edit sx={{ color: 'white.main', fontSize: 20 }} />
              </div>
            </Tooltip>
          </div>
          <h4 className="my-6 text-center text-h4">{profileData?.name}</h4>
          <p className="text-center">Joined since {profileData && dateConverter(profileData?.created_at)}</p>
        </div>
        <div className="w-full lg:w-2/3">
          <EDUTabBadge tabsBorderBottom >
            <CertificateTab
              label='Certificate'
              data={profileData?.certificate}
            />
            <AboutTab label='Personal details' data={profileData} handleEdit={handleOpen} />
          </EDUTabBadge>
        </div>
      </div>
      <EDUModal
        open={open}
        modalWidth={600}
        onClose={handleClose}
        sx={{
          '&.MuiModal-root': { overflowY: 'auto' },
          paddingY: '20px'
        }}
        sxModal={{
          px: 0, py: 0,
          position: 'relative',
          top: 0,
          left: '50%',
          transform: 'translate(-50%,0%)',
        }}
      >
        <Formik
          innerRef={formikRef}
          enableReinitialize
          initialValues={{
            profile_image: profileData?.profile_photo || null,
            name: profileData?.name || '',
            email: profileData?.email || '',
            country_id: profileData?.country_id || 132,
            contact_no: profileData?.contact_no || '',
            about: profileData?.about || ''
          }}
          validationSchema={validationSchema}
          onSubmit={submitDetails}
        >
          {
            ({ values, handleChange, errors, touched, setFieldValue, handleSubmit }) => (
              <Form>
                <div className="flex items-center justify-between px-[24px] py-[15px]">
                  <h4 className='text-h4'>Edit profile</h4>
                  <Close onClick={handleClose} sx={{ fontSize: 20, color: 'dark-text.main', cursor: 'pointer' }} />
                </div>
                <Divider />
                <div className='p-[24px] w-full'>
                  <div className='flex flex-col items-center justify-center w-full'>
                    <EDUDropzone
                      ref={dropzoneRef}
                      sx={{
                        width: '150px',
                        height: '150px',
                        border: '0',
                        px: 0
                      }}
                      icon=''
                      useDropzoneObject={{
                        accept: {
                          'image/jpeg': ['.jpeg', '.png']
                        },
                        maxSize: 2097152,
                        noClick: true,
                        onDrop: handleUpload
                      }}
                    >
                      <div onClick={() => dropzoneRef.current.open()}>
                        {
                          files?.length && files[0].type.includes("image") ? (
                            <div className='relative'>
                              <Box
                                sx={{
                                  width: '150px',
                                  height: '150px',
                                  borderRadius: '100%',
                                  backgroundImage: `url(${files[0].preview})`,
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center',
                                }}
                              />
                              <div className='absolute bottom-0 right-[6px] rounded-full border-[3px] border-edu-white flex justify-center items-center bg-edu-primary p-[8px]'>
                                <CameraAlt sx={{ color: 'white.main', fontSize: 20 }} />
                              </div>
                            </div>
                          ) : values?.profile_image ?
                            <div className='relative'>
                              <Box
                                sx={{
                                  width: '150px',
                                  height: '150px',
                                  borderRadius: '100%',
                                  backgroundImage: `url(${values?.profile_image})`,
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center',
                                }}
                              />
                              <div className='absolute bottom-0 right-[6px] rounded-full border-[3px] border-edu-white flex justify-center items-center bg-edu-primary p-[8px]'>
                                <CameraAlt sx={{ color: 'white.main', fontSize: 20 }} />
                              </div>
                            </div> : (
                              <div className='w-[150px] h-[150px] rounded-full border border-dashed flex justify-center items-center bg-edu-defaultBackground'>
                                <CameraAlt sx={{ color: 'light-gray-text.main', fontSize: 30 }} />
                              </div>
                            )
                        }
                      </div>
                    </EDUDropzone>
                    <p className='mt-[6px] text-center'>Profile picture</p>
                    {errorMsg && <p className='mt-2 text-body2 text-edu-error'>{errorMsg}</p>}
                  </div>
                  <div className='mt-[30px] w-full'>
                    <EDUTextField
                      name='name'
                      label='Full name'
                      onChange={handleChange}
                      value={values?.name}
                      isErrorShown={touched?.name && errors?.name}
                      errorMessage={touched?.name && errors?.name}
                    />
                    <div className='relative mt-5'>
                      <EDUTextField
                        name='email'
                        label='Email'
                        disabled
                        value={values?.email}
                        onChange={handleChange}
                        isErrorShown={touched?.email && errors?.email}
                        errorMessage={touched?.email && errors?.email}
                      />
                      <EDUButton sx={{ position: 'absolute', bottom: 6, right: 0, px: '14px' }} onClick={navigateSetting} variant='text'>{md && 'GO TO'} SETTINGS</EDUButton>
                    </div>
                    <div className='mt-5 w-full md:w-[70%]'>
                      <EDUPhoneNumber
                        label='Phone number'
                        dropdownValue={values?.country_id}
                        onDropdownChange={(value) => setFieldValue(`country_id`, value)}
                        dropdownErrorMessage={
                          touched.country_id && errors.country_id
                        }
                        dropdownName='country_id'
                        inputValue={values.contact_no}
                        onInputChange={(e) => setFieldValue('contact_no', e.target.value)}
                        inputErrorMessage={touched.contact_no && errors.contact_no}
                        dropdownIsErrorShown={
                          touched.country_id && errors.country_id
                        }
                        inputName='contact_no'
                        inputIsErrorShown={touched.contact_no && errors.contact_no}
                      />
                    </div>
                    <div className="mt-5">
                      <EDUTextField
                        name='about'
                        label='About'
                        onChange={handleChange}
                        value={values?.about}
                        isErrorShown={touched?.about && errors?.about}
                        errorMessage={touched?.about && errors?.about}
                        multiline maxRows={5} minRows={5} charLimit={255}
                      />
                    </div>
                  </div>
                </div>
                <div className='flex items-center justify-end pt-[20px] pb-[30px] px-[24px] gap-4'>
                  <EDUButton onClick={handleClose} variant='outlined' >Cancel</EDUButton>
                  <EDUButton loading={isLoading} disabled={isLoading} onClick={handleSubmit} variant='contained' sx={{ width: '260px' }}>
                    Update
                  </EDUButton>
                </div>
              </Form>
            )
          }
        </Formik>
      </EDUModal>
    </div>
  )
}

export default Profile