import React, { useState, useCallback, forwardRef } from 'react'
import Dropzone from 'react-dropzone'
import { Box, Typography, Icon } from '@mui/material'

import { getFileExtension, generateFileName } from 'services/helper'

const EDUDropzone = forwardRef(
  (
    {
      mainText,
      optionalText,
      setUploadState,
      variant = 'square',
      icon = 'arrow_upward',
      iconSx = {},
      useDropzoneObject = {},
      sx,
      handleFiles,
      fileType,
      setErrFiles,
      children,
      ...rest
    },
    ref
  ) => {
    // 1. Pass setUploadState from the parent for the ability to control the readiness of the dropzone ("READY", "NOT_READY", "PENDING")
    // 2. Pass useDropzoneObject to pass and configure the dropzone props as per react-dropzone documentation
    //  https://react-dropzone.js.org/#src
    // 3. Wrap the AdxDropzone in a <Box></Box> to control the width and the height of the dropzone
    // 4. variant: "circle" || "square" (default). To change the shape of the dropzone
    // 5. icon: "arrow_upward" (default) || any MUI icons string name
    // 6. iconSx: object. pass this to change the icon styling.

    const [files, setFiles] = useState([])

    const handleDrop = useCallback(
      async (acceptedFiles, fileRejections) => {
        if (fileRejections.length) {
          setErrFiles(fileRejections.map((file) => file))
          if (!!setUploadState) {
            setUploadState('ERROR')
          }
          return
        } else {
          if (!!setUploadState) {
            setUploadState('PENDING')
          }
        }

        const extension = getFileExtension(acceptedFiles[0].name).toLowerCase()
        const indexNumber = extension === 'pdf' ? 1 : 0
        const fileName = fileType.map((type) => generateFileName(type, extension))[indexNumber]

        setFiles(
          acceptedFiles.map((file) => {
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
            Object.defineProperty(file, 'name', {
              writable: true,
              value: fileName,
            })
          })
        )

        await handleFiles(acceptedFiles[0])
      },
      [fileType]
    )

    return (
      <Dropzone
        ref={ref}
        onDrop={handleDrop}
        maxFiles={useDropzoneObject.maxFiles ? useDropzoneObject.maxFiles : 1}
        maxSize={useDropzoneObject.maxSize ? useDropzoneObject.maxSize : 2097152}
        {...useDropzoneObject}
      >
        {({ fileRejections, getRootProps, getInputProps, isDragAccept, isDragReject }) => {
          const dropzoneStyles = () => {
            return {
              height: '100%',
              width: '100%',
              borderRadius: variant === 'circle' ? '50%' : 4,
              border: '2px dashed',
              borderColor: 'gray-line.main',
              backgroundColor: isDragAccept
                ? 'light-green-01.main'
                : isDragReject
                  ? 'light-error.main'
                  : useDropzoneObject.disabled
                    ? 'gray-background.main'
                    : 'white.main',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              rowGap: 2,
              p: 2,
              cursor: useDropzoneObject.disabled ? 'default' : 'pointer',
            }
          }
          return (
            <Box
              sx={{
                ...dropzoneStyles(),
                ...sx,
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />

              {
                icon !== '' && (
                  <Box>
                    <Icon
                      sx={{
                        color: useDropzoneObject.disabled
                          ? 'gray-inactive-button.main'
                          : 'green-01.main',
                        fontSize: '35px',
                        ...iconSx,
                      }}
                    >
                      {icon}
                    </Icon>
                  </Box>
                )
              }
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Typography
                  variant="body1"
                  color={
                    useDropzoneObject.disabled
                      ? 'gray-inactive-button.main'
                      : 'light-gray-text.main'
                  }
                  sx={{
                    textAlign: variant === 'circle' ? 'center' : 'left',
                  }}
                >
                  {mainText}
                </Typography>
                <Typography
                  variant="body2"
                  color={
                    useDropzoneObject.disabled
                      ? 'gray-inactive-button.main'
                      : 'light-gray-text.main'
                  }
                >
                  {optionalText}
                </Typography>
                {children}
              </Box>
            </Box>
          )
        }}
      </Dropzone>
    )
  }
)

export default EDUDropzone
