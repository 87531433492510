import EDUButton from 'components/Button/EDUButton'
import QuizHeader from 'components/Header/QuizHeader'
import React, { useEffect, useRef, useState } from 'react'
import MultipleChoiceQuiz from '../components/MultipleChoiceQuiz'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { quizSubmission, retrieveQuizQuestions } from 'services/api/quiz'
import Bugsnag from '@bugsnag/js'
import EDUModal from 'components/Modal/EDUModal'
import { PriorityHigh } from '@mui/icons-material'

const GradeQuiz = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { state } = useLocation()
  const [open, setOpen] = useState(false)
  const { data } = useQuery(['getQuestions', { id, enrollment_id: state?.enrollment_id }], () => retrieveQuizQuestions(id, state?.enrollment_id))
  const formikRef = useRef()

  const handleCloseModal = () => {
    setOpen(false)
  }

  const [answer, setAnswer] = useState([])
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation(async (val) => await quizSubmission(data?.id, val), {
    onSuccess: () => {
      queryClient.invalidateQueries(['moduleDetails', state?.enrollment_id])
      navigate(`/quiz/${id}/results`, { state: { view: true, enrollment_id: state?.enrollment_id, title: data?.title, courseState: state?.courseState } })
    },
    onError: (err) => Bugsnag.notify(err),
  })

  const handleSubmitQuiz = (values) => {
    mutate(values)
  }

  useEffect(() => {
    setAnswer(
      data?.sections[0]?.questions?.map((e) => {
        let question_id
        let answer_id = null
        question_id = e.id
        return { question_id, answer_id }
      })
    )
  }, [data])

  return (
    <div className="min-h-screen bg-edu-defaultBackground">
      <QuizHeader ref={formikRef} title={`Graded Quiz: ${data?.title}`} enrollment_id={state?.enrollment_id} courseState={state?.courseState} setModal={setOpen} />
      <Formik
        innerRef={formikRef}
        initialValues={{
          enrolment_id: state?.enrollment_id,
          submission: answer,
        }}
        enableReinitialize
        validationSchema={yup.object().shape({
          submission: yup.array(
            yup.object({
              answer_id: yup.number().required('Please indicate an answer'),
            })
          ),
        })}
        onSubmit={handleSubmitQuiz}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleSubmit,
          dirty,
          isValid,
          ...formikProps
        }) => {
          return (
            <Form>
              <div className="mx-auto max-w-3xl py-[10px]">
                {data?.sections[0]?.questions?.map((quest, index) => (
                  <MultipleChoiceQuiz
                    key={index}
                    FormikValues={values}
                    formikProps={formikProps}
                    quest={quest}
                    index={index}
                    setAnswer={(e) => setFieldValue(`submission[${index}].answer_id`, parseInt(e))}
                  />
                ))}
                <div className="flex  justify-end py-[20px] px-[20px] lg:px-0">
                  <EDUButton
                    className="w-full lg:w-[30%]"
                    variant="contained"
                    disabled={!(isValid && dirty) || isLoading || typeof data?.retry === 'number'}
                    onClick={handleSubmit}
                    loading={isLoading}
                  >
                    Submit
                  </EDUButton>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
      <EDUModal
        open={open}
        onClose={handleCloseModal}
        modalWidth={600}
      >
        <div className='flex flex-col space-y-[40px]'>
          <div className='flex flex-col justify-start items-center'>
            <div className='p-[15px] bg-edu-warning rounded-full'>
              <PriorityHigh color='white' />
            </div>
            <h2 className='text-h2 text-center mb-[16px] mt-[20px]'>Are you sure want to<br />leave quiz?</h2>
            <p className='text-center'>Any of your answer up to this point will not be saved. If you leave the quiz before submit the answer, you need to start all over again.</p>
          </div>
          <div className='flex flex-col space-y-[8px]'>
            <EDUButton onClick={handleCloseModal} fullWidth variant='contained'>No, continue with quiz</EDUButton>
            <EDUButton onClick={() => navigate(`/my-learning/${state?.enrollment_id}`, { state: state?.courseState })} fullWidth>Yes, leave quiz</EDUButton>
          </div>
        </div>
      </EDUModal>
    </div>
  )
}

export default GradeQuiz
