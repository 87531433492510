import { Snackbar, Fade, Slide, Grow, IconButton, Typography, Box } from '@mui/material'
import { CheckCircle, Close, Error } from '@mui/icons-material'

const SlideTransition = (props) => {
  return <Slide {...props} direction="down" />
}

const GrowTransition = (props) => {
  return <Grow {...props} />
}

const EDUSnackbar = ({
  message,
  transition = 'grow',
  action,
  handleAction,
  open,
  handleClose,
  icon,
  status,
  sx,
  ...rest
}) => {

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={transition === 'fade' ? Fade : transition === 'grow' ? GrowTransition : transition === 'slide' && SlideTransition}
      message={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '14px' }}>
          {
            icon ? icon : status === 'success' ? <CheckCircle color='success' sx={{ fontSize: 20 }} /> : status === 'error' && <Error color='error' sx={{ fontSize: 20 }} />
          }
          <Typography>{message}</Typography>
        </Box>
      }
      action={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {
            action || <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={handleAction || handleClose}
            >
              <Close />
            </IconButton>
          }
        </Box>
      }
      {...rest}
    />
  )
}

export default EDUSnackbar
