import EDUButton from 'components/Button/EDUButton'
import React from 'react'
import { CheckCircle, Error } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import timeConversion from 'services/helper/timeConversion'
import recapImage from '../../myClass/assets/recap-quiz-thumbnail.png'
import gradedImage from '../../myClass/assets/graded-quiz-thumbnail.png'

const RecapQuizDashboard = ({ enrollment_id, courseState, handleNext, courseDetails }) => {
  const navigate = useNavigate()

  const handleTakeQuiz = (id) => {
    navigate(`/recap-quiz/${id}`, {
      state: {
        enrollment_id,
        courseState
      }
    })
  }
  const { hours, minutes } = timeConversion(Boolean(courseState?.next?.type) ? (courseState?.next?.duration * 60) : courseState?.next?.duration)

  return (
    <div>
      <h3 className="text-h3 text-edu-darkText">Recap quiz: {courseState?.subtopic?.title}</h3>
      {
        !courseState?.subtopic?.id &&
        <div className='bg-edu-defaultBackground mt-[14px] rounded-[4.5px] p-[16px] flex space-x-[12px] items-center'>
          <Error sx={{ color: 'warning.main', fontSize: 20 }} />
          <p>You have an incomplete task before you can continue to this section</p>
        </div>
      }
      <div className="border-grayLine mt-[20px] flex w-full flex-col rounded-[4.5px] border py-6 px-5">
        <div className="flex flex-col items-center justify-between space-y-[20px] md:space-y-0 md:space-x-[20px] md:flex-row">
          <div style={{ backgroundImage: `url(${recapImage})` }} className="self-stretch h-[200px] md:h-auto w-full md:w-1/3 bg-cover bg-center bg-no-repeat" />
          <div className="flex h-auto w-full md:w-2/3 flex-col">
            <div className="flex-col mb-[24px] space-y-[8px]">
              <h3 className="text-h3 text-edu-darkText">Recap quiz</h3>
              <div className='flex items-center'>
                <p className="text-body text-edu-lightGrayText">
                  {courseState?.subtopic?.total_question} question{courseState?.subtopic?.total_question > 1 ? 's' : ''} &bull; {courseState?.subtopic?.duration} mins
                </p>
                {
                  courseState?.subtopic?.is_completed && <div className='flex items-center'>
                    <p className="text-body ml-2 text-edu-lightGrayText">&bull;</p>
                    <CheckCircle color='success' sx={{ mx: '6px' }} />
                    <p className="text-body text-edu-lightGrayText">Completed</p>
                  </div>
                }
              </div>
            </div>
            <EDUButton disabled={!courseState?.subtopic?.id} variant={!courseState?.subtopic?.is_completed ? "contained" : 'outlined'} onClick={() => handleTakeQuiz(courseState?.id)}>
              {
                !courseState?.subtopic?.is_completed ? 'Take recap quiz' : 'Take again'
              }
            </EDUButton>
          </div>
        </div>
      </div>
      {courseState?.subtopic?.is_completed && (
        <div className="mt-[20px] flex w-full flex-col gap-y-[20px] rounded-[4.5px] bg-edu-defaultBackground py-6 px-5">
          <div>
            <h3 className="text-subtitle text-edu-lightGrayText">UP NEXT</h3>
          </div>
          <div className="flex flex-col justify-start space-y-[20px] md:space-y-0 items-start md:flex-row md:items-center md:justify-between">
            <div className="flex flex-row items-center gap-x-[20px]">
              <div style={{ backgroundImage: `url(${courseState?.next?.type === undefined ? courseDetails?.thumbnail_url : courseState?.next?.type === 'RECAP' ? recapImage : gradedImage})` }} className="self-stretch w-[100px] bg-edu-grayBackground bg-cover bg-center bg-no-repeat" />
              <div className="flex flex-col gap-y-[10px]">
                <p className="text-subtitle text-edu-darkText">
                  {courseState?.next.title}
                </p>
                <p className="text-body text-edu-lightGrayText">{hours !== '00' && `${hours.slice(-1, 2)}h`}{' '}
                  {minutes && `${minutes}min`}</p>
              </div>
            </div>
            <EDUButton variant="contained" sx={{ width: { xs: '100%', md: '150px' } }} onClick={() => handleNext()}>
              <div className="flex flex-row items-center space-x-[10px]">
                <p className="text-subtitle">Continue</p>
              </div>
            </EDUButton>
          </div>
        </div>
      )}
    </div>
  )
}

export default RecapQuizDashboard
