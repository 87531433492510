import { Check } from '@mui/icons-material'
import { Skeleton, Typography } from '@mui/material'
import EDUTabPanel from 'components/Tab/EDUTabPanel'
import React from 'react'

const InformationTab = ({ label, isLoading, information }) => {
  return (
    <EDUTabPanel label={label}>
      <div className="py-[30px]">
        {!isLoading ? (
          <div>
            <h4 className="mb-[15px] text-h4">Introduction</h4>
            <p className="mb-[30px] whitespace-pre-line text-justify md:text-left">
              {information?.introduction}
            </p>
            <h4 className="mb-[15px] text-h4">Learning objectives</h4>
            {information?.learning_objectives?.length > 1 ? (
              information?.learning_objectives?.map((objective, index) => (
                <div
                  key={index}
                  className="mb-[3px] flex flex-row items-center justify-start space-x-[20px] rounded-[4.5px] bg-edu-defaultBackground py-[15px] px-5"
                >
                  <div className="rounded-full bg-edu-lightInformation p-2.5">
                    <Check color="information" />
                  </div>
                  <p>{objective}</p>
                </div>
              ))
            ) : (
              <p className="text-justify md:text-left">{information?.learning_objectives[0]}</p>
            )}
            <h4 className="mt-[30px] mb-[15px] text-h4">Requirement and materials</h4>
            <ul className="list-disc list-outside ml-6 space-y-1">
              {
                information?.requirement?.split('\n')?.map((item, index) => (
                  <li key={index}>{item}</li>
                ))
              }
            </ul>
            {/* <p className="text-justify md:text-left">
              {information?.requirement}
            </p> */}
          </div>
        ) : (
          <div className="flex flex-col space-y-2">
            <Typography variant="h2" sx={{ width: '100%' }}>
              <Skeleton width="30%" />
            </Typography>
            <Skeleton width="100%" />
            <Skeleton width="40%" />
            <Typography variant="h2" sx={{ width: '100%' }}>
              <Skeleton width="30%" />
            </Typography>
            {[1, 1, 1].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width="100%"
                height="65px"
                sx={{ my: 1 }}
              />
            ))}
            <Typography variant="h2" sx={{ width: '100%' }}>
              <Skeleton width="30%" />
            </Typography>
            <Skeleton width="80%" />
          </div>
        )}
      </div>
    </EDUTabPanel>
  )
}

export default InformationTab
