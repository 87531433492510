import { Box } from '@mui/material'
import React from 'react'
import SectionFive from '../components/sectionFive'
import SectionFour from '../components/sectionFour'
import SectionOne from '../components/sectionOne'
import SectionSix from '../components/sectionSix'
import SectionThree from '../components/sectionThree'
import SectionTwo from '../components/sectionTwo'

const LandingPage = () => {
  return (
    <Box>
      {/* Section One */}
      <SectionOne />
      {/* Section Two */}
      <Box
        sx={{
          backgroundColor: 'default-background.main',
        }}
      >
        <SectionTwo />
      </Box>
      {/* Section Three */}
      <SectionThree />
      {/* Section Four */}
      <SectionFour />
      {/* Section Five */}
      <SectionFive />
      {/* Section Six */}
      <SectionSix />
    </Box>
  )
}

export default LandingPage
