import { useNavigate } from 'react-router-dom'
import logo from 'assets/logos/adnexio-edu-logo.svg'
import EDUButton from 'components/Button/EDUButton'

const PageNotFound404 = () => {
  const navigate = useNavigate()

  const handleSignUp = () => {
    window.scrollTo(0, 0)
    navigate('/')
  }

  return (
    <div className='flex flex-col justify-center items-center h-screen'>
      <img src={logo} alt='adnexio logo' className='absolute top-[50px] h-10' />
      <h1 className='text-[100px] leading-[100px] font-medium'>404</h1>
      <h3 className='text-h3 uppercase my-5'>Page Not Found</h3>
      <p className='max-w-[450px] text-center my-[14px]'>
        The page you are trying to access was deleted or never existed. Sorry for the inconvenience!
      </p>
      <EDUButton
        variant='contained'
        sx={{ paddingX: '30px', marginTop: '20px' }}
        disableElevation
        onClick={handleSignUp}
      >
        Home
      </EDUButton>
    </div>
  )
}

export default PageNotFound404
