import Bugsnag from '@bugsnag/js'
import { Star } from '@mui/icons-material'
import { Box, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import EDUBreadcrumb from 'components/Breadcrumbs/EDUBreadcrumb'
import EDUBreadLink from 'components/Breadcrumbs/EDUBreadLink'
import EDUTabBadge from 'components/Tab/EDUTabBadge'
import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { getCourseDetails } from 'services/api/courseDetails'
import valueToStringConversion from 'services/helper/valueToStringConversion'
import CourseInfo from '../components/CourseInfo'
import InformationTab from '../components/InformationTab'
import LessonPlanTab from '../components/LessonPlanTab'
// import ReviewTab from '../components/ReviewTab' */ TEMPORARY HIDE UNTIL WE HAVE REVIEW FEATURE */ 

const CourseDetailsPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data: courseDetails, isLoading } = useQuery(['queryCourseDetails', id], async () => getCourseDetails(id), {
    onSuccess: (res) => { return res },
    onError: (err) => {
      if (err?.data?.message === 'Data not found') {
        navigate('/course-not-found')
      }
      Bugsnag.notify(err)
    }
  })
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const lg = useMediaQuery(theme.breakpoints.up('lg'))
  return (
    <div className="relative">
      <div className="flex h-full w-full flex-row">
        <div className="h-full w-full py-[30px] px-[30px] lg:w-3/5">
          <EDUBreadcrumb separatorSx={{ fontSize: 20 }}>
            <EDUBreadLink href="/explore-courses">Explore courses</EDUBreadLink>
            <EDUBreadLink last>{courseDetails?.course_title}</EDUBreadLink>
          </EDUBreadcrumb>
          {!isLoading ? (
            <>
              <h2 className="mt-[16px] text-h2">{courseDetails?.course_title}</h2>
              <p className="text-edu-lightGrayText">By {courseDetails?.trainer_name}</p>
            </>
          ) : (
            <>
              <Typography variant="h2" sx={{ width: '100%' }}>
                <Skeleton width="30%" />
              </Typography>
              <Skeleton width="50%" />
            </>
          )}
          {
            courseDetails?.total || courseDetails?.reviews ?
              <div className='mt-[14px] flex items-center'>
                {
                  courseDetails?.total > 5 && <Box
                    sx={{
                      paddingX: '10px',
                      paddingY: '2px',
                      backgroundColor: 'primary.main',
                      borderRadius: '2.5px',
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ color: 'white.main' }}>
                      Best Seller
                    </Typography>
                  </Box>
                }
                {
                  courseDetails?.reviews?.length > 0 &&
                  <div className='flex items-center mx-5'>
                    <Star color='primary' sx={{ fontSize: 20 }} />
                    <p className='mx-[6px] text-subtitle text-edu-lightGrayText'>{courseDetails?.average_rate}</p>
                    <p className='text-subtitle text-edu-lightGrayText'>({courseDetails?.reviews?.length} review{courseDetails?.reviews?.length > 1 ? 's' : ''})</p>
                  </div>
                }
                {courseDetails?.total > 20 && (
                  <Typography variant="body1" sx={{ color: 'light-gray-text.main' }}>
                    {valueToStringConversion(courseDetails?.total)} sold
                  </Typography>
                )}
              </div> : null
          }
          <Box
            sx={{
              my: '20px',
              width: '100%',
              height: '400px',
              backgroundImage: `url(${courseDetails?.course_logo})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: lg ? 'block' : 'hidden'
            }}
          />
          <div className="my-[25px] block lg:hidden">
            <CourseInfo courseDetails={courseDetails} isLoading={isLoading} />
          </div>
          <EDUTabBadge tabsBorderBottom sx={{
            '& .MuiTabScrollButton-root': {
              display: md && 'none',
            },
          }}>
            <InformationTab
              label="Information"
              isLoading={isLoading}
              information={{
                introduction: courseDetails?.course_description,
                learning_objectives: courseDetails?.objectives,
                requirement: courseDetails?.course_requirements
              }}
            />
            <LessonPlanTab
              label="Lesson Plan"
              isLoading={isLoading}
              lessonPlans={courseDetails?.course_topic}
            />
            {/* TEMPORARY HIDE UNTIL WE HAVE REVIEW FEATURE */}
            {/* <ReviewTab
              label="Review"
              isLoading={isLoading}
              sideProps={
                Boolean(courseDetails?.average_rate) && (
                  <div className="flex flex-row items-center justify-center space-x-[5px]">
                    <p className="text-subtitle">{courseDetails?.average_rate}</p>
                    <Star sx={{ fontSize: 16 }} />
                  </div>
                )
              }
              reviews={{
                reviews_list: courseDetails?.reviews || [],
                total_reviews: courseDetails?.total_reviews || 0,
              }}
            /> */}
          </EDUTabBadge>
        </div>
        <div className="hidden h-full w-2/5 py-[30px] px-[25px] lg:sticky lg:top-[75px] lg:block">
          <CourseInfo courseDetails={courseDetails} isLoading={isLoading} />
        </div>
      </div>
    </div>
  )
}

export default CourseDetailsPage
