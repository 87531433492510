import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { retrieveQuizQuestions } from 'services/api/quiz'
import RecapHeader from '../components/RecapHeader'
import RecapQuestions from '../components/RecapQuestions'
import { useEffect } from 'react'
import EDUModal from 'components/Modal/EDUModal'
import { PriorityHigh } from '@mui/icons-material'
import EDUButton from 'components/Button/EDUButton'

const RecapQuizPage = () => {
  const { id } = useParams() //quiz id
  const { state } = useLocation()
  const navigate = useNavigate()
  const [questionState, setQuestionState] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const { isLoading, refetch } = useQuery(['getQuestions', id], () => retrieveQuizQuestions(id), {
    enabled: false,
    onSuccess: (res) => {
      const resArray = res?.sections[0]?.questions?.map((question, index) => {
        Object.assign(question, { attempt: false })
        return {
          ...question,
          title: `${index + 1}. ${question?.title}`
        }
      })
      setQuestionState({
        id: res?.id,
        question: resArray,
        title: res?.title
      })
    }
  })

  useEffect(() => {
    refetch()
    // eslint-disable-next-line
  }, [])

  if (isLoading) {
    return (
      <div className='min-h-screen bg-edu-defaultBackground flex flex-col'>
        <RecapHeader />
      </div>
    )
  }
  return (
    <div className='min-h-screen bg-edu-defaultBackground flex flex-col'>
      <RecapHeader state={questionState} setOpenModal={setOpenModal} courseState={state?.courseState} enrollment_id={state?.enrollment_id} />
      <RecapQuestions questionState={questionState} setQuestionState={setQuestionState} quiz_id={id} enrollment_id={state?.enrollment_id} courseState={state?.courseState} />
      <EDUModal
        open={openModal}
        onClose={handleCloseModal}
        modalWidth={600}
      >
        <div className='flex flex-col space-y-[40px]'>
          <div className='flex flex-col justify-start items-center'>
            <div className='p-[15px] bg-edu-warning rounded-full'>
              <PriorityHigh color='white' />
            </div>
            <h2 className='text-h2 text-center mb-[16px] mt-[20px]'>Are you sure want to<br />leave quiz?</h2>
            <p className='text-center'>Any of your answer up to this point will not be saved. If you leave the quiz before submit the answer, you need to start all over again.</p>
          </div>
          <div className='flex flex-col space-y-[8px]'>
            <EDUButton onClick={handleCloseModal} fullWidth variant='contained'>No, continue with quiz</EDUButton>
            <EDUButton onClick={() => navigate(`/my-learning/${state?.enrollment_id}`, { state: state?.courseState })} fullWidth>Yes, leave quiz</EDUButton>
          </div>
        </div>
      </EDUModal>
    </div>
  )
}

export default RecapQuizPage