import EDUNoData from "components/NoData/EDUNoData"
import { Verified } from "@mui/icons-material"
import EDUButton from "components/Button/EDUButton"
import { useNavigate } from "react-router-dom"
import { getCertificate } from "services/api/profile"
import Bugsnag from "@bugsnag/js"
import { useState } from "react"
import { CircularProgress } from "@mui/material"

const { default: EDUTabPanel } = require("components/Tab/EDUTabPanel")

const CertificateTab = ({ label, data }) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const handleNavigate = () => {
    navigate('/explore-courses')
  }

  const handleViewCertificate = async (cert_id) => {
    setIsLoading(true)
    try {
      const res = await getCertificate(cert_id)
      const element = document.createElement('a')
      const file = new Blob([res.data], { type: 'application/pdf' })
      element.href = URL.createObjectURL(file)
      element.target = '_blank'
      element.click()
    } catch (error) {
      Bugsnag.notify(error)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <EDUTabPanel label={label} >
      <div className='px-[30px] py-[11px] lg:py-[30px]'>
        {
          data === null || data === undefined || data?.length < 1 ? (
            <EDUNoData title='No certificate yet' description={'Complete a course to achieve your first certificate'} sxDesc={{ mb: 3 }} action={<EDUButton variant='outlined' onClick={handleNavigate}>Explore courses</EDUButton>} icon={<Verified sx={{ color: 'light-gray-text-2.main', fontSize: 40 }} />} />
          ) : (
            <div className="w-full">
              {
                data?.map((subject, index) => (
                  <div key={index} className={`w-full flex py-[16px] items-start md:items-center justify-between border-edu-grayLine ${data?.length !== index + 1 && 'border-b'}`}>
                    <div className="flex items-start md:items-center space-x-[18px]">
                      <Verified sx={{ color: 'primary.main', fontSize: 24 }} />
                      <div className="flex flex-col">
                        <p className="text-subtitle mb-[4px]">{subject?.course_title}</p>
                        <div className="flex items-start">
                          <p className="text-body2 text-edu-lightGrayText capitalize">{subject?.type}</p>
                          <div className="rounded-full mt-[7px] bg-edu-lightGrayText w-[5px] h-[5px] mx-[10px]" />
                          <p className="text-body2 text-edu-lightGrayText">Completed on {subject?.completed_date}</p>
                        </div>
                      </div>
                    </div>
                    {
                      isLoading ? <CircularProgress size={20} sx={{ color: 'light-gray-text.main' }} /> :
                        <p onClick={() => handleViewCertificate(subject?.cert_id)} className="underline text-edu-secondary cursor-pointer">View</p>
                    }
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
    </EDUTabPanel>
  )
}

export default CertificateTab