import React from 'react'
import { Close } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { forwardRef } from 'react'

const QuizHeader = forwardRef(({ title = 'Default Text Insert Here', enrollment_id, setModal, courseState }, ref) => {
  const navigate = useNavigate()
  const handleClose = () => {
    if (ref && ref.current.dirty) {
      setModal(true)
    } else {
      navigate(`/my-learning/${enrollment_id}`, { state: courseState })
    }
  }
  return (
    <header className="sticky top-0 z-20 flex w-full flex-row items-center justify-between border-b border-edu-grayLine bg-[rgba(255,255,255,0.8)] py-[24px] px-[22px] backdrop-blur">
      <h4 className="text-h4 text-edu-darkText">{title}</h4>
      <Close sx={{ fontSize: 20, cursor: 'pointer' }} onClick={handleClose} />
    </header>
  )
})

export default QuizHeader
