import React, { Children, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'

const EDUTabBadge = ({
  initialValue = 0,
  whiteBackground,
  tabsBorderBottom,
  sxTabs,
  scrollButtons = true,
  sxPanel,
  children,
  setTabIndex,
  addButton,
  ...rest
}) => {
  const [value, setValue] = useState(initialValue)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const arrayChildren = Children.toArray(children)

  const tabClick = (index) => {
    if (setTabIndex) {
      setTabIndex(index)
    } else {
      return null
    }
  }
  return (
    <Box
      height={1}
      bgcolor={whiteBackground ? 'white.main' : null}
      borderRadius={whiteBackground ? 3 : null}
      sx={{ width: '100%', ...sxTabs }}
      display={'flex'}
      flexDirection={'column'}
      paddingY={whiteBackground ? 1 : null}
    >
      <Box
        boxSizing="border-box"
        display={'flex'}
        flexDirection={'row'}
        borderBottom={whiteBackground || tabsBorderBottom ? '1px solid' : null}
        sx={whiteBackground || tabsBorderBottom ? { borderBottomColor: 'gray-line.main' } : null}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          scrollButtons={scrollButtons}
          variant="scrollable"
          allowScrollButtonsMobile
          TabIndicatorProps={{
            sx: {
              backgroundColor: 'primary.main',
              height: '2.5px',
            },
          }}
          {...rest}
        >
          {arrayChildren.map((item, index) => {
            return (
              <Tab
                icon={item.props?.icon}
                iconPosition={item.props?.iconPosition}
                key={index}
                value={index}
                onClick={() => tabClick(index)}
                sx={{
                  fontSize: '16px',
                  fontSizeLg: '16px',
                }}
                label={
                  <Box display={'flex'} flexDirection="row" alignContent="center">
                    <Typography
                      variant="body1"
                      color={value === index ? 'primary.main' : 'dark-text.main'}
                      fontWeight="medium"
                    >
                      {item.props.label ? item.props.label : `Tab ${[index]}`}
                    </Typography>

                    {item.props.notification ? (
                      <Box
                        marginLeft="10px"
                        width="45px"
                        borderRadius="3px"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        bgcolor="#E1F3F0"
                      >
                        <Typography variant="body2" fontWeight={600} color="primary.main">
                          {item.props.notification}
                        </Typography>
                      </Box>
                    ) : null}

                    {item.props.sideProps ? (
                      <Box
                        marginLeft="10px"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        color={value === index ? 'primary.main' : 'dark-text.main'}
                      >
                        {item.props?.sideProps}
                      </Box>
                    ) : null}
                  </Box>
                }
              />
            )
          })}
          {addButton}
        </Tabs>
      </Box>
      <Box
        bgcolor={!whiteBackground ? 'white.main' : null}
        borderRadius={!whiteBackground ? 3 : null}
        sx={{
          overflowY: 'auto',
          ...sxPanel,
        }}
      >
        {arrayChildren[value]}
      </Box>
    </Box>
  )
}

export default EDUTabBadge
