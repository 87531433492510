import { Box } from '@mui/material'
import Footer from 'components/Footer/Footer'
import LandingPageHeader from 'components/Header/LandingPageHeader'
import { Outlet, useLocation } from 'react-router-dom'

const LayoutwithHeaderFooter = () => {
  const { pathname } = useLocation()
  return (
    <Box>
      <LandingPageHeader />
      {
        (pathname === '/' || pathname === '/faq') ? <Outlet /> : <div className='m-auto px-[30px] max-w-[1200px]'>
          <Outlet />
        </div>
      }
      <Footer />
    </Box>
  )
}

export default LayoutwithHeaderFooter