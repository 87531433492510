import { LinearProgress } from '@mui/material'
import React from 'react'

const EDUProgressBar = ({ value, sx, ...rest }) => {
  return (
    <>
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          borderRadius: 50,
          height: '14px',
          width: '100%',
          '&.MuiLinearProgress-root': {
            backgroundColor: sx?.backgroundColor || 'gray-background.main'
          },
          '& .MuiLinearProgress-bar': {
            backgroundColor: sx?.color || 'primary.main',
            borderRadius: 50
          },
          ...sx
        }}
        {...rest}
      />
    </>
  )
}

export default EDUProgressBar