import { ChevronRight, Circle, ShoppingCart } from '@mui/icons-material'
import { Skeleton } from '@mui/material'
import EDUDrawer from 'components/Drawer/EDUDrawer'
import EDUNoData from 'components/NoData/EDUNoData'
import EDUTabPanel from 'components/Tab/EDUTabPanel'
import React from 'react'
import { useState } from 'react'
import valueToStringConversion from 'services/helper/valueToStringConversion'
import PurchaseDetailsDrawer from './PurchaseDetailsDrawer'
import dateConverter from 'services/helper/dateConverter'
import { paymentDasboard } from 'services/api/payment'
import { useQuery } from '@tanstack/react-query'
import Bugsnag from '@bugsnag/js'
import { useEffect } from 'react'

const ActivePaymentTab = ({ label, setDataLength }) => {
  const [drawerState, setDrawerState] = useState({ right: false })
  const [enrollmentId, setEnrollmentId] = useState()

  const handleDrawer = (anchor, state) => {
    setDrawerState({ [anchor]: state })
  }

  const { data, isLoading } = useQuery(['activePayment'], async () => {
    return await paymentDasboard({ type: 'active' })
  }, {
    onError: (err) => Bugsnag.notify(err)
  })
  useEffect(() => {
    if (typeof data?.data !== 'undefined') {
      setDataLength(data?.data?.length)
    }
    // eslint-disable-next-line
  }, [data])

  return (
    <>
      <EDUTabPanel label={label}>
        <div className="py-[20px]">
          {isLoading ? (
            <div className="rounded-lg border border-edu-grayLine">
              {[1, 1, 1].map((_, index) => (
                <div
                  key={index}
                  className={`flex w-full cursor-pointer items-center justify-between p-[20px] ${index + 1 !== 3 && 'border-b border-edu-grayLine'
                    }`}
                >
                  <div className="flex w-full flex-col gap-[5px]">
                    <Skeleton variant="rectangular" width="40%" height="20px" />
                    <div className="flex items-center gap-[10px]">
                      <Skeleton width="10%" />
                      <Skeleton width="20%" />
                    </div>
                  </div>
                  <Skeleton variant="rectangular" width="20%" height="20px" />
                </div>
              ))}
            </div>
          ) : (data?.data?.length < 1 || data === null || data === undefined || data?.length === 0) ? (
            <EDUNoData
              title="No active installment"
              description="You have no active installment at the moment."
              icon={<ShoppingCart sx={{ fontSize: 40 }} />}
            />
          ) : (
            <div className="rounded-lg border border-edu-grayLine">
              {data?.data?.map((active, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      handleDrawer('right', true)
                      setEnrollmentId(active?.enrolment_id)
                    }}
                    className={`flex cursor-pointer items-center justify-between p-[20px] ${index + 1 !== data?.data?.length && 'border-b border-edu-grayLine'
                      }`}
                  >
                    <div>
                      <p className="mb-[8px] text-subtitle">{active?.course_title}</p>
                      <div className="flex flex-col-reverse items-start md:flex-row md:items-center">
                        <p className="text-body2 italic text-edu-lightGrayText md:text-body md:not-italic">
                          {active?.completed_installments + 1}/{active?.total_installments} payment
                        </p>
                        <span className="hidden md:block">
                          <Circle sx={{ fontSize: 7, color: 'light-gray-text.main', mx: 1 }} />
                        </span>
                        <div className="flex items-center">
                          <p className="mr-[8px] text-edu-lightGrayText">
                            Due on {dateConverter(active?.next_due_date)}
                          </p>
                          {active?.overdue_status === 1 ? (
                            <div className="block bg-edu-lightError px-[6px] py-[2px] md:hidden">
                              <p className="text-subtitle2 text-edu-error">Overdue</p>
                            </div>
                          ) : null}
                        </div>
                        {active?.overdue_status === 1 ? (
                          <div className="hidden bg-edu-lightError px-[6px] py-[2px] md:block">
                            <p className="text-subtitle2 text-edu-error">Overdue</p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex items-center gap-[12px]">
                      <p className="hidden text-subtitle md:block">RM {valueToStringConversion(active?.course_fee)}</p>
                      <ChevronRight color="primary" />
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </EDUTabPanel>
      <EDUDrawer
        drawerState={drawerState}
        handleDrawer={handleDrawer}
        anchor={Object.keys(drawerState)[0]}
        header={<h4 className="text-h4">Purchase details</h4>}
        divider
      >
        <PurchaseDetailsDrawer enrollment_id={enrollmentId} />
      </EDUDrawer>
    </>
  )
}

export default ActivePaymentTab
