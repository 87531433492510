import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import Quotes from '../../asset/landingpage_testimonial_quote.jpg'
import LandingPageCarousel from 'components/Carousel/LandingPageCarousel'

const SectionFive = () => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const testimonialArray = [
    {
      name: 'Muhammad Naqib',
      age: 25,
      testimonial: `The Data Science Bootcamp enabled me to work a 9-5 job and still attend classes at night
            and live consultations on weekends. The online classes are engaging and the trainer, Dr.
            Nickholas is qualified, enthusiastic and supportive. It was worth investing my time for
            2 months because at the end of it, I felt confident in applying for data science jobs in
            Malaysia`,
      jobscope: 'Data Scientist',
    },
    {
      name: 'Wan Adzhar Faiq',
      age: 27,
      testimonial: `I took the course because I wanted the option to pivot my career into Data Science. I
          have no regrets as this course provided me with the practical experience and portfolio
          building, allowing me to qualify as a data scientist in the real job market. Dr
          Nickholas is very helpful and has patiently gone through application of key concepts
          with us. The curriculum is well-planned and covers basic and advanced concepts in data
          science. I am now hired as data scientist at Invoke`,
      jobscope: 'Data Scientist',
    },
    {
      name: 'Muhammad Fairuz',
      age: 24,
      testimonial: `The SEC bootcamp was a transformative journey that pushed my limits and sharpened my web development skills. I'm much more confident in my web development skills now, and I'm excited to tackle more challenging projects and continue growing in the field. The program has proven to be valuable as I have successfully landed my first software engineer role at a company.`,
      jobscope: 'Software Engineer',
    },
    {
      name: 'Jun Yang Chin',
      age: 26,
      testimonial: `The program was designed in a way that it fast tracked learning while still providing in-depth knowledge of the subject, making it easy for learners to quickly grasp the essential concepts of software engineering. I am deeply grateful for the experience and wholeheartedly recommend the SEC bootcamp to anyone seeking to break into the field of software engineering.`,
      jobscope: 'Data Scientist',
    },
  ]

  return (
    <Box
      className="m-auto max-w-[1200px]"
      sx={{
        p: md ? '60px' : '20px',
        backgroundColor: 'white.main',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant={md ? 'h1' : 'h2'} sx={{ mb: '20px', textAlign: md ? 'none' : 'center' }}>
        What are our students saying?
      </Typography>
      {/* Carousel Section */}

      <LandingPageCarousel arrowIndicator={md ? true : false}>
        {testimonialArray.map((item, index) => {
          return (
            <Box
              key={index}
              sx={{
                backgroundColor: 'default-background.main',
                width: md ? (testimonialArray.length > 2 ? '32%' : '520px') : '90%',
                height: '100%',
                p: '20px',
                borderRadius: '4.5px',
                display: 'inline-block',
                marginRight: testimonialArray.length > 2 ? '0.5%' : '30px',
                verticalAlign: 'top',
              }}
            >
              <Box sx={{ mb: '20px' }}>
                <img alt="" src={Quotes} />
              </Box>
              <Typography variant="body1" sx={{ mb: '30px', width: '100%', whiteSpace: 'normal' }}>
                {item?.testimonial}
              </Typography>
              <Typography variant="subtitle1">
                {item?.name} ({item?.age})
              </Typography>
              <Typography variant="body2" sx={{ color: 'light-gray-text.main' }}>
                {item?.jobscope}
              </Typography>
            </Box>
          )
        })}
      </LandingPageCarousel>
    </Box>
  )
}

export default SectionFive
