import { Edit } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import EDUButton from 'components/Button/EDUButton';
import EDUTabPanel from 'components/Tab/EDUTabPanel'
import { countries } from 'services/api/dropdown_list';

const AboutTab = ({ label, data, handleEdit }) => {
  const {
    data: countriesList,
    isLoading
  } = useQuery(["countriesList"], async () => await countries());

  const detailsArray = [
    {
      title: "full name",
      value: data?.name,
    },
    {
      title: "about",
      value: data?.about,
    },
    {
      title: "phone number",
      value: '+' + countriesList?.find((country) => country?.id === data?.country_id)?.phone_code?.concat(data?.contact_no),
    },
    {
      title: "email",
      value: data?.email,
    },
  ];
  return (
    <EDUTabPanel label={label}>
      <div className="p-[30px]">
        {
          isLoading ? [1, 1, 1, 1].map((_, index) => (
            <div key={index} className='flex items-start mb-[16px]'>
              <Skeleton width={'20%'} sx={{ mr: '16px' }} />
              <Skeleton width={'50%'} />
            </div>
          )) : detailsArray?.map((item, index) => (
            <div key={index} className='flex items-start mb-[16px]'>
              <p className='text-edu-lightGrayText w-[120px] mr-[16px] capitalize'>{item?.title}</p>
              <p className={`${(item?.value === null || item?.value === undefined || item?.value === '') && 'text-edu-lightGrayText'} w-auto flex-1 max-w-[50vw]`}>{item?.value === null || item?.value === undefined || item?.value === '' ? `-` : item?.value}</p>
            </div>
          ))
        }
        <EDUButton onClick={() => handleEdit()} variant='outlined' sx={{ mt: 2 }}>
          <Edit sx={{ fontSize: 20, color: 'secondary.main', mr: '10px' }} /> Edit profile
        </EDUButton>
      </div>
    </EDUTabPanel>
  )
}

export default AboutTab