import { Box, Grid, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material'
import EDUTabPanel from 'components/Tab/EDUTabPanel'
import EDUNoData from 'components/NoData/EDUNoData'
import EDUCourseCard from 'components/CourseCard/EDUCourseCard'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as MyLearningIcon } from 'assets/icons/myLearningIcon.svg'
import { useQuery } from '@tanstack/react-query'
import { getMyLearning } from 'services/api/myLearning'
import Bugsnag from '@bugsnag/js'

const CompletedTab = ({ label }) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const navigate = useNavigate()
  const handleCourseRoute = (id) => {
    navigate(`/my-learning/${id}`)
  }

  const { isLoading, data } = useQuery(
    ['getCompletedCourse'],
    async () => await getMyLearning('completed'),
    {
      onError: (err) => Bugsnag.notify(err),
    }
  )

  return (
    <EDUTabPanel label={label}>
      <Box sx={{ paddingY: '20px', flexGrow: 1 }}>
        <Grid container rowSpacing={4} columnSpacing={8}>
          {!isLoading ? (
            data?.length !== 0 ? data.map((item, index) => {
              return (
                <Grid key={index} item xs={12} md={6} lg={4}>
                  <Box
                    sx={{ marginBottom: 4, cursor: 'pointer' }}
                  >
                    <EDUCourseCard data={item} showPricing={false} handleNavigate={() => handleCourseRoute(item?.id)} />
                  </Box>
                </Grid>
              )
            }) : (
              <Grid item xs={12}>
                <EDUNoData title="No completed course yet" icon={<MyLearningIcon className={`fill-edu-lightGrayText02 w-[28px] self-center h-[40px]`} />} sxIcon={{ width: 'auto', height: 'auto' }} />
              </Grid>
            )
          ) : (
            [1, 1, 1].map((_, index) => {
              return (
                <Grid key={index} item xs={12} md={6} lg={4}>
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      maxWidth: md ? '373px' : null,
                      backgroundColor: 'white.main',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    {/*Image and Play Button */}
                    <Skeleton sx={{ width: '100%', height: '200px' }}></Skeleton>
                    {/*Details and Copywriting */}
                    <Box
                      sx={{
                        width: '100%',
                        paddingY: '22px',
                      }}
                    >
                      <Box
                        sx={{
                          mb: '8px',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Skeleton>
                          <Typography
                            variant="subtitle1"
                            sx={{ textTransform: 'uppercase', color: 'information.main' }}
                          >
                            Course
                          </Typography>
                        </Skeleton>

                        <Skeleton
                          sx={{
                            paddingX: '10px',
                            paddingY: '2px',
                            borderRadius: '2.5px',
                          }}
                        >
                          <Typography variant="subtitle1" sx={{ color: 'white.main' }}>
                            Best Seller
                          </Typography>
                        </Skeleton>
                      </Box>
                      <Skeleton>
                        <Typography variant="h4" sx={{ textTransform: 'capitalize', mb: '6px' }}>
                          Software Engineering Conversion Bootcamp
                        </Typography>
                      </Skeleton>
                      <Skeleton>
                        <Typography
                          variant="body1"
                          sx={{ textTransform: 'capitalize', color: 'light-gray-text.main' }}
                        >
                          By Invoke Solutions Sdn. Bhd.
                        </Typography>
                      </Skeleton>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: md ? 'row' : 'column',
                          alignItems: md ? 'center' : 'flex-start',
                          justifyContent: 'space-between',
                          mt: '11.6px',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: md ? 'row' : 'column',
                            alignItems: md ? 'center' : 'flex-start',
                            columnGap: 1,
                          }}
                        >
                          <Skeleton>
                            <Typography variant="h3" sx={{ color: 'error.main' }}>
                              RM 99,999
                            </Typography>
                          </Skeleton>

                          <Skeleton>
                            <Typography
                              variant="body1"
                              sx={{ color: 'dark-text.main', textDecoration: 'line-through' }}
                            >
                              RM 99,999
                            </Typography>
                          </Skeleton>
                        </Box>

                        <Skeleton>
                          <Typography variant="body1" sx={{ color: 'light-gray-text.main' }}>
                            (999.9k sold)
                          </Typography>
                        </Skeleton>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              )
            })
          )}
        </Grid>
      </Box>
    </EDUTabPanel>
  )
}

export default CompletedTab
