import { Box } from '@mui/material'
import BackgroundSignup from '../assets/Sign-up.webp'

const SignUpContainer = ({ children }) => {
  return (
    <div className="flex flex-row w-screen h-screen ">
      <Box
        sx={{
          height: '100vh',
          width: '40%',
          display: { xs: 'none', lg: 'block' },
          background: `url(${BackgroundSignup})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <div className="w-full flex flex-row justify-center items-start min-h-full overflow-auto lg:w-3/5 p-[50px] md:p-[60px] ">
        {children}
      </div>
    </div>
  )
}

export default SignUpContainer