import React from 'react'
import { Box, Link, Typography } from '@mui/material'

const EDUBreadLink = ({ href, children, last, ...rest }) => {
  return (
    <Box {...rest}>
      {last ? (
        <Typography noWrap sx={{ color: 'light-gray-text.main' }}>
          {children}
        </Typography>
      ) : (
        <Link underline="hover" href={href} color="primary.main">
          {children}
        </Link>
      )}
    </Box>
  )
}

export default EDUBreadLink
