import { Checkbox } from '@mui/material'
import React from 'react'

const EDUCheckbox = ({ sx, ...rest }) => {
  return (
    <Checkbox
      {...rest}
      sx={{
        '&.MuiCheckbox-root': {
          minHeight: 0,
          color: 'light-gray-text.main',
        },
        '&.Mui-checked': {
          color: 'secondary.main',
        },
        ...sx,
      }}
    />
  )
}

export default EDUCheckbox
