import { Box, Icon, Typography } from "@mui/material";
import React from "react";

const EDUNoData = ({
  icon,
  title,
  description,
  action,
  sxContainer,
  sxTitle,
  sxDesc,
  sxIcon,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        // backgroundColor: "gray-background.main",
        borderRadius: 2,
        boxShadow: "none",
        px: { xs: 2, md: 10 },
        py: 10,
        rowGap: 1,
        ...sxContainer,
      }}
    >
      <Box
        sx={{
          width: "100px",
          height: "100px",
          borderRadius: "50%",
          backgroundColor: "default-background.main",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // border: 1,
          // borderColor: "light-gray-text.main",
          mb: 2.5,
        }}
      >
        <Icon
          sx={{
            fontSize: 40,
            color: "light-gray-text-2.main",
            ...sxIcon,
          }}
        >
          {icon}
        </Icon>
      </Box>
      <Typography
        variant="h4"
        align="center"
        sx={{ color: "light-gray-text.main", ...sxTitle }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        align="center"
        sx={{ color: "light-gray-text.main", ...sxDesc }}
      >
        {description}
      </Typography>
      {
        action && action
      }
    </Box>
  );
};

export default EDUNoData;