import SectionSix from 'pages/landingPage/components/sectionSix'
import { NearMeOutlined } from '@mui/icons-material'
import EDUButton from 'components/Button/EDUButton'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Enquiries } from 'assets/icons/enquiries.svg'

const Faq = () => {
  const navigate = useNavigate()
  return (
    <div>
      <SectionSix />
      <div className='p-[30px] md:p-[80px]  bg-edu-defaultBackground'>
        <div className='m-auto max-w-[1200px]'>
          <h2 className='text-h2 mb-[32px]'>
            Need more assistance?
          </h2>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-6 '>
            <div className='rounded-[4.5px] border border-edu-grayLine bg-edu-white space-x-4 p-[24px] flex items-start'>
              <div className='rounded-full bg-edu-defaultBackground p-[15px]'>
                <Enquiries className='fill-edu-primary' sx={{ fontSize: 20 }} />
              </div>
              <div>
                <h3 className='text-h3'>Send your enquiry</h3>
                <h4 className='mt-[8px] mb-[20px] text-h4'>Let our dedicated support to assist you with all your needs at all time.</h4>
                <EDUButton onClick={() => navigate('/contact-us')} fullWidth variant='contained'>Contact us</EDUButton>
              </div>
            </div>
            <div className='rounded-[4.5px] flex-1 border bg-edu-white border-edu-grayLine p-[24px] space-x-4 flex items-start'>
              <div className='rounded-full bg-edu-defaultBackground p-[15px]'>
                <NearMeOutlined sx={{ fontSize: 24, color: 'primary.main' }} />
              </div>
              <div>
                <h3 className='text-h3'>Our location</h3>
                <h4 className='mt-[8px] mb-[20px] text-h4'>Level 2-4, 80, Jalan Tasik Utama 5, 57000, Sungai Besi, Kuala Lumpur</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq
