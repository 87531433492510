import {
  Check,
  // ClosedCaption,
  FastForward,
  FastRewind,
  Fullscreen,
  FullscreenExit,
  Pause,
  PlayArrow,
  Replay,
  SlowMotionVideo,
  VolumeOff,
  VolumeUp,
} from '@mui/icons-material'
import { Box, MenuItem, Slider, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import timeConversion from 'services/helper/timeConversion'
import IconTooltipButton from './IconTooltipButton'
import EDUPopper from 'components/Popper/EDUPopper'

const VideoController = ({
  onReplay,
  onPlayPause,
  onMute,
  onRewind,
  onFastForward,
  onVolumeChange,
  onSlideProgress,
  onToggleFullScreen,
  onPlaybackChange,
  videoState,
}) => {
  let total_duration = timeConversion(videoState?.duration, true)
  let progress_play = timeConversion(videoState?.progressSeconds, true)
  const { hours: totalHours, minutes: totalMinutes, seconds: totalSeconds } = total_duration
  const { hours: playedHours, minutes: playedMinutes, seconds: playedSeconds } = progress_play

  const defaultStyle = () => {
    return {
      color: 'white.main',
      cursor: 'pointer',
    }
  }

  const playbackRef = useRef(null)
  const [open, setOpen] = useState(false)
  // const [caption, setCaption] = useState(false)
  // TO FIX THE POPPER
  const openSetting = () => {
    setOpen((prev) => !prev)
  }

  const handleClose = (event) => {
    if (playbackRef.current && playbackRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handlePlaybackRate = (newValue) => (e) => {
    onPlaybackChange(newValue === 'Normal' ? 1 : newValue)
    setOpen(false)
  }

  // const handleSubtitles = () => {
  //   setCaption(!caption)
  // }

  return (
    <div className="flex h-auto w-full flex-col items-start justify-end bg-gradient-to-t from-edu-darkText px-[10px] py-[5px]">
      <div className="w-full">
        {/* slider onSeek */}
        <div className="flex flex-col justify-center px-2">
          <Slider
            value={
              !videoState || videoState?.progressSeconds === 0 || videoState?.duration === 0
                ? 0
                : (videoState?.progressSeconds * 100) / videoState?.duration
            }
            onChange={onSlideProgress}
            sx={{
              height: 5,
              color: 'gray-line.main',
              '& .MuiSlider-thumb': {
                height: 15,
                width: 15,
              },
              '&.MuiSlider-root': {
                py: '5px',
              },
              '& .MuiSlider-track': {
                height: 4,
                color: 'yellow-01.main',
              },
            }}
          // valueLabelDisplay='auto'
          />
        </div>
        <div className="flex w-full flex-row items-center justify-between">
          <div className="flex flex-row items-center space-x-2">
            <IconTooltipButton onClick={onRewind}>
              <FastRewind sx={{ ...defaultStyle() }} />
            </IconTooltipButton>

            {/* play pause replay */}
            {videoState?.endVideo ? (
              <IconTooltipButton onClick={onReplay} toolTipLabel="Replay">
                <Replay sx={{ ...defaultStyle() }} />
              </IconTooltipButton>
            ) : (
              <IconTooltipButton
                onClick={onPlayPause}
                toolTipLabel={videoState?.isPlaying === true ? 'Pause' : 'Play'}
              >
                {videoState?.isPlaying === true ? (
                  <Pause sx={{ ...defaultStyle() }} />
                ) : (
                  <PlayArrow sx={{ ...defaultStyle() }} />
                )}
              </IconTooltipButton>
            )}
            <IconTooltipButton onClick={onFastForward}>
              <FastForward sx={{ ...defaultStyle() }} />
            </IconTooltipButton>

            {/* volume */}
            <div className="group/controller flex flex-row items-center ">
              <IconTooltipButton
                onClick={onMute}
                toolTipLabel={videoState?.volume === 0 || videoState?.isMuted ? 'Unmute' : 'Mute'}
              >
                {videoState?.volume === 0 || videoState?.isMuted ? (
                  <VolumeOff sx={{ ...defaultStyle() }} />
                ) : (
                  <VolumeUp sx={{ ...defaultStyle() }} />
                )}
              </IconTooltipButton>
              <div className="mx-0 hidden w-0 items-center justify-center group-hover/controller:mx-[16px] group-hover/controller:flex group-hover/controller:w-[60px]">
                <Slider
                  value={
                    videoState ? (videoState?.isMuted === true ? 0 : videoState?.volume * 100) : 50
                  }
                  onChange={onVolumeChange}
                  min={0}
                  max={100}
                  sx={{
                    height: 3,
                    width: '100%',
                    color: 'gray-line.main',
                    '& .MuiSlider-thumb': {
                      height: 13,
                      width: 13,
                    },
                    '& .MuiSlider-track': {
                      height: 2,
                      color: 'white.main',
                    },
                  }}
                // valueLabelDisplay='auto'
                />
              </div>
            </div>

            {/* duration */}
            <Typography sx={{ color: 'white.main' }}>
              {playedHours === '00'
                ? `${playedMinutes}:${playedSeconds}`
                : `${playedHours}:${playedMinutes}:${playedSeconds}`}
            </Typography>
            <Typography sx={{ color: 'white.main' }}>{` / `}</Typography>
            <Typography sx={{ color: 'white.main' }}>
              {totalHours === '00'
                ? `${totalMinutes}:${totalSeconds}`
                : `${totalHours}:${totalMinutes}:${totalSeconds}`}
            </Typography>
          </div>
          <div className="flex flex-row items-center space-x-2">
            {/* HIDDEN TEMPORARILY UNTIL INTEGRATE CAPTION */}
            {/* <IconTooltipButton
              onClick={handleSubtitles}
              toolTipLabel={caption ? 'Subtitles (on)' : 'Subtitles (off)'}
            >
              <ClosedCaption
                sx={{
                  ...defaultStyle(),
                  borderBottom: caption && 3,
                  borderColor: caption && 'yellow-01.main',
                }}
              />
            </IconTooltipButton> */}

            <IconTooltipButton ref={playbackRef} onClick={openSetting}>
              <SlowMotionVideo sx={{ ...defaultStyle() }} />
            </IconTooltipButton>
            <EDUPopper
              open={open}
              handleClose={handleClose}
              anchorEl={playbackRef.current}
              placement="top-end"
              sx={{
                backgroundColor: 'dark-text.main',
                minWidth: '100px',
              }}
            >
              <Box
                sx={{
                  borderBottom: 1,
                  py: 1,
                  px: 2,
                  borderColor: 'light-gray-text.main',
                }}
              >
                <Typography color="light-gray-text.main">Playback speed</Typography>
              </Box>
              {[0.5, 1, 1.5, 2].map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={handlePlaybackRate(item)}
                  sx={{
                    color: 'light-gray-text.main',
                    textAlign: 'center',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: 2,
                    py: 1,
                  }}
                >
                  {item}
                  {videoState?.playbackRate === item && (
                    <Check color="light-gray-text" sx={{ fontSize: 20 }} />
                  )}
                </MenuItem>
              ))}
            </EDUPopper>
            <IconTooltipButton
              onClick={onToggleFullScreen}
              toolTipLabel={videoState?.isFullScreen ? 'Exit fullscreen' : 'Fullscreen'}
            >
              {videoState?.isFullScreen ? (
                <FullscreenExit sx={{ ...defaultStyle() }} />
              ) : (
                <Fullscreen sx={{ ...defaultStyle() }} />
              )}
            </IconTooltipButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoController
