import { Box, Typography, InputBase, Select, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import InputAdornment from "@mui/material/InputAdornment";
import { ErrorMessage } from "formik";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { countries } from "services/api/dropdown_list";
import EDUErrorMessage from "components/ErrorMessage/EDUErrorMessage";
import { Close, Flag, Phone, Search } from "@mui/icons-material";
import EDUMenu from "components/Dropdown/EDUMenu";
import EDUTextField from "components/TextField/EDUTextField";
import { useQuery } from "@tanstack/react-query";

const getAllCountries = async (fields = 'name,flags,idd,cca2') => {
  try {
    const res = await axios.get(
      `https://restcountries.com/v3.1/all?fields=${fields}`
    );
    return res.data
  } catch (error) {
    return [];
  }
};

const EDUPhoneNumber = ({
  // Dropdown
  dropdownValue,
  dropdownName,
  onDropdownChange,
  dropdownDisabled = false,
  sxDropdown,
  dropdownErrorMessage,
  dropdownIsErrorShown,
  // Input
  inputValue,
  inputName,
  onInputChange,
  inputDisabled = false,
  sxInput,
  inputErrorMessage,
  inputIsErrorShown,
  // Label
  label,
  sxLabel,
  // Others
  size,
  filters = ["country_name", "phone_code"],
  modalSearch = "Search country name or code",
  sx,
  sxBox,
  ...rest
}) => {
  const [isFocus, setIsFocus] = useState(false)
  const [searchFilterValue, setSearchFilterValue] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  // state and function for AdxDropdown components
  const [selectInternalValue, setSelectInternalValue] = useState('');
  const {
    data: countriesList,
    isLoading: loadingCountryList
  } = useQuery(["countriesList"], async () => await countries());

  const {
    data: thirdPartyList,
    isLoading: loadingThirdParty
  } = useQuery(["thirdPartyPhoneList"], async () => {
    const thirdPartyData = await getAllCountries();
    return thirdPartyData;
  });

  const handleChangeSearchFilter = (event) => {
    event.preventDefault();
    setSearchFilterValue(event.target.value);
  };

  const handleSetSelectInternalValue = (props) => {
    setSelectInternalValue(props);
  };

  const fetchCountries = async () => {
    if (countriesList?.length) {
      const filteredCountries = countriesList
        .filter((cc) => cc.sortname !== "IL")
        .filter((option) => {
          if (`${option.name.toLowerCase()} ${option.phone_code}`.includes(searchFilterValue)) {
            return option
          } else {
            return null
          }
        })
        .map((country) => {
          return {
            id: country.id,
            phone_code: `+${country.phone_code}`,
            country_name: country.name,
            country_code: country.sortname,
            country_flag: thirdPartyList.find(
              (element) => element.cca2 === country.sortname
            )?.flags.png,
          };
        })
        .sort((a, b) => {
          return a.country_name.localeCompare(b.country_name);
        });
      setCountryOptions(filteredCountries);
    }
  };

  useEffect(() => {
    if (countriesList && thirdPartyList) {
      if (typeof dropdownValue !== 'string') {
        const initalCountry = countriesList?.find((obj) => obj.id === dropdownValue)
        let initialReturn = {
          id: initalCountry?.id,
          phone_code: `+${initalCountry.phone_code}`,
          country_name: initalCountry.name,
          country_code: initalCountry.sortname,
          country_flag: thirdPartyList.find(
            (element) => element.cca2 === initalCountry.sortname
          )?.flags.png,
        }
        handleSetSelectInternalValue(JSON.stringify(initialReturn))
        onDropdownChange(JSON.stringify(initialReturn))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownValue, countriesList, thirdPartyList])

  const handleChangesManually = (e) => {
    handleSetSelectInternalValue(e.target.value);
    onDropdownChange(e.target.value);
  };

  const { palette } = useTheme();

  let fontSize;
  let height;
  let paddingX;

  switch (size) {
    case "standard":
      fontSize = 14;
      paddingX = 14;
      height = 45;
      break;

    case "large":
      fontSize = 20;
      paddingX = 20;
      height = 75;
      break;

    default:
      fontSize = 14;
      paddingX = 14;
      height = 45;
  }

  const defaultProps = () => {
    return {
      fontSize: `${fontSize}px !important`,
      paddingX: `${paddingX}px`,
      height,
      width: "100%",
      marginRight: "1px",
      borderTopRightRadius: `${height * 0.1}px`,
      // "&.MuiInputBase-input": {
      //   fontSize: 50,
      // },
      outlineLeft: 0,
      borderBottomRightRadius: `${height * 0.1}px`,
      outlineColor: inputIsErrorShown ? palette.error.main : null,
      "&.MuiInputBase-root": {
        "&.Mui-focused": {
          outlineColor: inputIsErrorShown ? palette.error.main : null,
        },
      },
      ...sxInput,
      "&.Mui-focused": {
        outline: 1,
        outlineStyle: 'solid',
        outlineColor: palette.secondary.main
      },
      marginLeft: "1px",
      minHeight: 45,
    };
  };

  const MenuProps = () => {
    return {
      height,
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "0px",
      borderTopLeftRadius: `${height * 0.1}px`,
      borderBottomLeftRadius: `${height * 0.1}px`,
      "&. MuiMenu-root": {
        color: "secondary.main",
      },
      outlineColor: dropdownIsErrorShown ? palette.error.main : null,
      "&.MuiOutlinedInput-root": {
        "&.Mui-focused": {
          outlineColor: dropdownIsErrorShown ? palette.error.main : null,
        },
      },
      minHeight: 45,
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: 0,
      },
      "&.Mui-disabled": {
        backgroundColor: "default-background.main",
      },
      '& .MuiSelect-select': {
        py: 0,
        px: '14px',
      },
      "&.Mui-focused": {
        outlineColor: palette['secondary'].main,
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: 0,
        },
      },
      ...sxDropdown,
    };
  };

  useEffect(() => {
    if (countriesList && thirdPartyList) {
      fetchCountries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesList, thirdPartyList, searchFilterValue]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      rowGap={label ? '6px' : null}
      sx={{ ...sx }}
    >
      {label ? (
        <Typography
          sx={{
            color: (dropdownDisabled && inputDisabled) ? 'light-gray-text.main' : isFocus ? 'secondary.main' : (dropdownIsErrorShown && inputIsErrorShown) ? 'error.main' : 'dark-text.main',
            ...sxLabel
          }}
        >
          {label}
        </Typography>
      ) : null}
      <Box>
        <Box display={"flex"} flexDirection={"row"} sx={{ ...sxBox }}>
          <Select
            color="secondary"
            value={selectInternalValue}
            displayEmpty
            name={dropdownName}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            onChange={(e) => {
              handleChangesManually(e)
              setSearchFilterValue('')
            }}
            disabled={dropdownDisabled}
            IconComponent={''}
            MenuProps={{
              sx: {
                "&.MuiMenu-root": {
                  my: 1.25,
                  maxHeight: "440px",
                },
              },
              autoFocus: false,
            }}
            renderValue={(option) => {
              const parsedOption = Boolean(option) && typeof option === 'string' ? JSON.parse(option) : option
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {
                    loadingCountryList || loadingThirdParty ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <CircularProgress size={20} color='primary' />
                    </Box> : <Box display='flex' alignItems='center'>
                      {parsedOption.country_flag ? (
                        <Box
                          as="img"
                          src={parsedOption.country_flag}
                          sx={{
                            backgroundColor: "gray-background.main",
                            width: "20px",
                            height: "14px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "10%",
                            mx: 1,
                          }}
                        />
                      ) : (
                        <Box
                          sx={{
                            // backgroundColor: "gray-background.main",
                            width: "20px",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "10%",
                            mx: 1,
                          }}
                        >
                          <Phone
                            sx={{ fontSize: 18, marginTop: "2px" }}
                            color="secondary"
                          />
                        </Box>
                      )}
                      <Typography variant="subtitle">
                        {parsedOption.phone_code}
                      </Typography>
                    </Box>
                  }
                </Box>
              )
            }}
            sx={{
              ...MenuProps(),
            }}
            {...rest}
          >
            {/* Filter search bar here */}
            <ListSubheader sx={{ py: 1, px: 1 }}>
              <EDUTextField
                value={searchFilterValue}
                onChange={handleChangeSearchFilter}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation()
                  }
                }}
                autoFocus
                placeholder="Search"
                startAdornment={
                  <InputAdornment position="start">
                    <Search sx={{ mr: 1 }} />
                  </InputAdornment>
                }
                endAdornment={searchFilterValue !== '' && <InputAdornment position="end">
                  <Close onClick={() => setSearchFilterValue('')} sx={{ color: 'secondary.main', cursor: 'pointer' }} />
                </InputAdornment>}
              />
            </ListSubheader>
            {
              countryOptions.map((item, index) => (
                <EDUMenu key={index} value={JSON.stringify(item)}>
                  <Box
                    sx={{
                      width: "25vw",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {item.country_flag ? (
                        <Box
                          as="img"
                          sx={{
                            backgroundColor: "gray-background.main",
                            width: "20px",
                            height: "14px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "10%",
                            mx: 1,
                          }}
                          src={item.country_flag}
                        />
                      ) : (
                        <Box
                          sx={{
                            // backgroundColor: "gray-background.main",
                            width: "20px",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "10%",
                            mx: 1,
                          }}
                        >
                          <Flag sx={{ fontSize: 20 }} color="secondary" />
                        </Box>
                      )}
                      <Typography variant="body1">
                        {item.country_name}
                      </Typography>
                    </Box>
                    <Typography variant="subtitle2">{item.phone_code}</Typography>
                  </Box>
                </EDUMenu>
              ))
            }
          </Select>
          <InputBase
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            {...rest}
            sx={{
              ...defaultProps(),
            }}
            value={inputValue}
            onChange={onInputChange}
            name={inputName}
            disabled={inputDisabled}
            type="tel"
          />
        </Box>
        {inputIsErrorShown && dropdownIsErrorShown ? (
          <EDUErrorMessage>
            Phone Number and Phone Code is required
          </EDUErrorMessage>
        ) : inputName && inputIsErrorShown ? (
          <ErrorMessage name={inputName} component={EDUErrorMessage} />
        ) : (
          dropdownName &&
          dropdownIsErrorShown && (
            <ErrorMessage name={dropdownName} component={EDUErrorMessage} />
          )
        )}
      </Box>
    </Box>
  );
};

export default EDUPhoneNumber;
