import { Skeleton, Box } from "@mui/material"

const LoadingGradedAnswer = () => {
  return (
    <div className=" mx-auto min-h-screen max-w-3xl bg-edu-white ">
      <Skeleton variant="rectangular" width='100%' height='150px' />

      <Box sx={{ mt: '40px', px: '50px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
          <Skeleton width='50%' height='40px' />
          <Skeleton width='80%' />
          <Skeleton width='40%' />
        </Box>

        <Box className="grid grid-cols-3 border-y-grayLine mt-[30px] border-y-2 py-[12px] lg:flex-row lg:items-center">
          <Box className="flex flex-col">
            <Skeleton width='20%' height='60px' />
            <Skeleton width='50%' />
          </Box>
          <Box className="flex flex-col">
            <Skeleton width='20%' height='60px' />
            <Skeleton width='50%' />
          </Box>
          <Box className="flex flex-col">
            <Skeleton width='20%' height='60px' />
            <Skeleton width='50%' />
          </Box>
        </Box>
        <Box className="mt-[30px] flex w-full flex-col gap-y-[10px]">
          <Skeleton variant="rectangular" width='100%' height='45px' />
          <Skeleton variant="rectangular" width='100%' height='45px' />
          <Skeleton variant="rectangular" width='100%' height='45px' />
        </Box>
      </Box>
    </div>
  )
}

export default LoadingGradedAnswer