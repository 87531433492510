import React from 'react'
import StepperIcon from './StepperIcon'

const EDUStepper = ({
  children, //accepts node for labelling
  active, //receives index of active object
  index, // index of current object
}) => {
  return (
    <div className="relative flex w-auto items-center justify-start py-2">
      <StepperIcon
        active={index === active ? true : false}
        completed={index < active ? true : false}
      />
      {children}
      <div
        className={`w-[2px] ${index <= active ? 'bg-edu-success' : 'bg-edu-grayLine'
          } absolute left-[11.5px] bottom-[calc(100%/2)] h-full ${index === 0 ? 'hidden' : 'block'}`}
      />
    </div>
  )
}

export default EDUStepper
