import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import valueToStringConversion from 'services/helper/valueToStringConversion'

const EDUCourseCard = ({ data, showPricing = true, handleNavigate, sx }) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const [hover, setHover] = useState(false)

  return (
    <Box
      onClick={handleNavigate}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        ...sx,
      }}
    >
      {/*Image and Play Button */}
      <Box
        sx={{
          width: '100%',
          height: '177px',
          backgroundImage: `url(${data?.course_logo})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {
          hover && <Box
            sx={{
              backgroundColor: 'dark-text.main',
              opacity: 0.5,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        }
      </Box>
      {/*Details and Copywriting */}
      <Box
        sx={{
          width: '100%',
          height: 'calc(100% - 177px)',
          paddingY: '20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Box
            sx={{
              mb: '8px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ textTransform: 'uppercase', color: 'information.main' }}
            >
              {data?.course_type}
            </Typography>
            {data?.total > 5 && (
              <Box
                sx={{
                  paddingX: '10px',
                  paddingY: '2px',
                  backgroundColor: 'primary.main',
                  borderRadius: '2.5px',
                }}
              >
                <Typography variant="subtitle1" sx={{ color: 'white.main' }}>
                  Best Seller
                </Typography>
              </Box>
            )}
          </Box>

          <Typography
            variant="h4"
            sx={{
              textTransform: 'capitalize',
              mb: '6px',
              textDecoration: hover && 'underline',
            }}
          >
            {data?.course_title}
          </Typography>

          <Typography
            variant="body1"
            sx={{ color: 'light-gray-text.main' }}
          >
            by {data?.trainer_name}
          </Typography>

        </Box>
        {
          showPricing && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: md ? 'row' : 'column',
                alignItems: md ? 'center' : 'flex-start',
                justifyContent: 'space-between',
                mt: '11.6px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: md ? 'row' : 'column',
                  alignItems: md ? 'center' : 'flex-start',
                  columnGap: 1,
                }}
              >
                <Typography variant="h3" sx={{ color: data?.discount_fee && 'error.main' }}>
                  {data?.discount_fee
                    ? `RM ${valueToStringConversion(data?.discount_fee?.toFixed(2))}`
                    : `RM ${valueToStringConversion(data?.course_fee?.toFixed(2))}`}
                </Typography>
                {Boolean(data?.discount_fee) && (
                  <Typography
                    variant="body1"
                    sx={{ color: 'dark-text.main', textDecoration: 'line-through' }}
                  >
                    {`RM ${valueToStringConversion(data?.course_fee?.toFixed(2))}`}
                  </Typography>
                )}
              </Box>
              {data?.total > 20 && (
                <Typography variant="body1" sx={{ color: 'light-gray-text.main' }}>
                  ({data?.total} sold)
                </Typography>
              )}
            </Box>
          )
        }
      </Box>
    </Box>
  )
}

export default EDUCourseCard