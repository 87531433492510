import Bugsnag from "@bugsnag/js"
import { Close, Visibility, VisibilityOff } from "@mui/icons-material"
import { Divider } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import EDUButton from "components/Button/EDUButton"
import EDUModal from "components/Modal/EDUModal"
import EDUTextField from "components/TextField/EDUTextField"
import { authContext } from "contexts/AuthContext"
import { Form, Formik } from "formik"
import { useContext } from "react"
import { useRef } from "react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { resetEmail, validateEmail } from "services/api/authentication"
import { clearCookies } from "services/helper/clearCookies"
import * as yup from 'yup'

const ChangeAccountModal = ({ children, email }) => {
  const { logoutUser } = useContext(authContext)
  const formikRef = useRef()
  const resetEmailRef = useRef()
  const [type, setType] = useState('password')
  const [open, setOpenModal] = useState(false)
  const [modalState, setModalState] = useState(0)
  const navigate = useNavigate()
  const validationSchema = yup.object({
    email: yup.string().email('Email must be a valid email').required('Email is required'),
    password: yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required'),
  })

  const validateResetEmail = yup.object({
    email: yup.string().email('Email must be a valid email').required('Email is required'),
  })

  const handleOpen = () => {
    setOpenModal(true)
  }
  const handleClose = () => {
    setModalState(0)
    setOpenModal(false)
  }
  const { mutate: mutateValidation, isLoading } = useMutation(validateEmail, {
    onSuccess: () => {
      setModalState(1)
    },
    onError: (err) => {
      if (err.status === 404) {
        formikRef.current.setFieldError('password', err.data.message)
      } else if (err.status === 422) {
        const errors = err.data.errors
        Object.keys(errors).forEach(function (key) {
          resetEmailRef.current.setFieldError(key, errors[key][0])
        })
      } else {
        Bugsnag.notify(err)
      }
    }
  })
  const checkEmail = (values) => {
    mutateValidation(values)
  }

  const { mutate, isLoading: loadingReset } = useMutation(resetEmail, {
    onSuccess: () => {
      navigate('/verification-email', {
        state: {
          status: 'resetEmail',
          payload: resetEmailRef.current.values
        }
      })
      clearCookies()
      logoutUser()
    },
    onError: (err) => {
      if (err.status === 422) {
        const errors = err.data.errors
        Object.keys(errors).forEach(function (key) {
          resetEmailRef.current.setFieldError(key, errors[key][0])
        })
      } else {
        Bugsnag.notify(err)
      }
    }
  })

  const resetSubmission = (val) => {
    mutate(val)
  }
  return (
    <>
      <div onClick={handleOpen}>{children}</div>
      <EDUModal
        open={open}
        modalWidth={600}
        onClose={handleClose}
        sxModal={{
          px: 0, py: 0,
        }}
      >
        <div className="flex items-center justify-between px-[24px] py-[15px]">
          <h4 className='text-h4'>Change email</h4>
          <Close onClick={handleClose} sx={{ fontSize: 20, color: 'dark-text.main', cursor: 'pointer' }} />
        </div>
        <Divider />
        {
          !modalState ? (
            <Formik
              enableReinitialize
              innerRef={formikRef}
              validationSchema={validationSchema}
              initialValues={{
                email: email,
                password: ''
              }}
              onSubmit={checkEmail}
            >
              {
                ({ values, touched, errors, handleBlur, handleSubmit, handleChange }) => {
                  return (
                    <Form>
                      <div className='p-[24px]'>
                        <p className="text-edu-lightGrayText">Enter the password associated with your account to change to new email</p>
                        <div className='mb-[20px] mt-[24px]'>
                          <EDUTextField
                            name='email'
                            label='Email'
                            value={values?.email}
                            disabled
                            isErrorShown={touched?.email && errors?.email}
                            errorMessage={touched?.email && errors?.email}
                          />
                        </div>
                        <EDUTextField
                          label='Password'
                          name='password'
                          value={values?.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type={type}
                          endAdornment={type === 'password' ? <Visibility color='light-gray-text' sx={{ cursor: 'pointer' }} onClick={() => setType('text')} /> : <VisibilityOff color='light-gray-text' sx={{ cursor: 'pointer' }} onClick={() => setType('password')} />}
                          isErrorShown={touched?.password && errors?.password}
                          errorMessage={touched?.password && errors?.password}
                        />
                      </div>
                      <div className="px-[24px] pb-[28px]">
                        <EDUButton type='submit' disabled={isLoading} loading={isLoading} onClick={handleSubmit} variant='contained' fullWidth>Continue</EDUButton>
                      </div>
                    </Form>
                  )
                }
              }
            </Formik>
          ) : (
            <Formik
              enableReinitialize
              innerRef={resetEmailRef}
              validationSchema={validateResetEmail}
              initialValues={{
                email: ''
              }}
              onSubmit={resetSubmission}
            >
              {
                ({ values, touched, errors, handleBlur, handleSubmit, handleChange }) => {
                  return (
                    <Form>
                      <div className='p-[24px]'>
                        <p className="text-edu-lightGrayText">Enter the new email you wish to change to.</p>
                        <div className='my-[24px]'>
                          <EDUTextField
                            name='email'
                            label='Email'
                            value={values?.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isErrorShown={touched?.email && errors?.email}
                            errorMessage={touched?.email && errors?.email}
                          />
                        </div>
                      </div>
                      <div className="px-[24px] pb-[28px]">
                        <EDUButton type='submit' disabled={loadingReset} loading={loadingReset} onClick={handleSubmit} variant='contained' fullWidth>Continue</EDUButton>
                      </div>
                    </Form>
                  )
                }
              }
            </Formik>
          )
        }
      </EDUModal>
    </>
  )
}

export default ChangeAccountModal