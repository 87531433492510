import { Box, ClickAwayListener, Popper } from "@mui/material"

const EDUPopper = ({ children, handleClose, open, anchorEl, sx, ...rest }) => {
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      // transition
      // disablePortal
      placement="bottom-end"
      sx={{
        zIndex: 10,
        // offset
        backgroundColor: 'white.main',
        border: 1,
        borderColor: 'gray-line.main',
        borderRadius: '4.5px',
        ...sx
      }}
      {...rest}
    >
      {/* {({ TransitionProps }) => (
        <Grow {...TransitionProps} sx={{ transformOrigin: 'right top' }}> */}
      <Box>
        <ClickAwayListener onClickAway={handleClose}>
          <Box>
            {children}
          </Box>
        </ClickAwayListener>
      </Box>
      {/* </Grow>
      )} */}
    </Popper >
  )
}

export default EDUPopper