import axios, { getData } from 'lib/axios'

export const getCourseDetails = async (id) => {
  const { data } = await axios.get(`adx_edu/course/${id}`).then(getData)
  return data
}

export const addToWishlist = async (id) => {
  return await axios.post(`adx_edu/wishlist/${id}`)
}

export const removeWishlist = async (id) => {
  return await axios.delete(`adx_edu/wishlist-delete/${id}`)
}