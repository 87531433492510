import EDUButton from 'components/Button/EDUButton'
import React from 'react'
import { Info, CheckCircle, Error } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import timeConversion from 'services/helper/timeConversion'
import gradedImage from '../assets/graded-quiz-thumbnail.png'
import recapImage from '../assets/recap-quiz-thumbnail.png'

const GradedQuizDashboard = ({ enrollment_id, courseState, handleNext, courseDetails }) => {
  const navigate = useNavigate()

  const handleTakeQuiz = (id) => {
    navigate(`/quiz/${id}`, {
      state: {
        enrollment_id,
        courseState
      }
    })
  }
  const { hours, minutes } = timeConversion(courseState?.next?.duration)

  const handleView = (id) => {
    navigate(`/quiz/${id}/results`, { state: { view: false, enrollment_id, title: courseState?.subtopic?.title, courseState } })
  }

  return (
    <div>
      <h3 className="text-h3 text-edu-darkText">{courseState?.subtopic?.title}</h3>
      {
        !courseState?.subtopic?.id &&
        <div className='bg-edu-defaultBackground mt-[14px] rounded-[4.5px] p-[16px] flex space-x-[12px] items-center'>
          <Error sx={{ color: 'warning.main', fontSize: 20 }} />
          <p>You have an incomplete task before you can continue to this section</p>
        </div>
      }
      <div className="border-grayLine mt-[20px] flex w-full flex-col gap-y-[20px] rounded-[4.5px] border py-6 px-5">
        <div className="flex flex-col items-center justify-between space-y-[20px] md:space-y-0 md:space-x-[20px] md:flex-row">
          <div style={{ backgroundImage: `url(${gradedImage})` }} className="self-stretch h-[200px] md:h-auto w-full md:w-1/3 bg-cover bg-center bg-no-repeat" />
          <div className="flex h-auto w-full md:w-2/3 flex-col">
            <div className="flex-col mb-[24px] space-y-[8px]">
              <h3 className="text-h3 text-edu-darkText">Graded Quiz</h3>
              <h3 className="text-body text-edu-lightGrayText">
                {courseState?.subtopic?.total_question} question{courseState?.subtopic?.total_question > 1 ? 's' : ''} &bull; {courseState?.subtopic?.duration} - {courseState?.subtopic?.duration + 2} mins
              </h3>
            </div>
            {/* TO DOUBLE CHECK. FIRST NO RETRY. ONCE QUIZ HAS BEEN TAKEN, RETRY === TRUE. IF TRIED 3 TIMES, RETRY === {NUMBER OF HOURS} */}
            <EDUButton variant={(!courseState?.subtopic?.attempt || courseState?.subtopic?.status !== 1) ? "contained" : 'outlined'} disabled={(courseState?.subtopic?.attempt && courseState?.subtopic?.retry !== true) || !courseState?.subtopic?.id} onClick={() => handleTakeQuiz(courseState?.id)}>
              {
                !courseState?.subtopic?.attempt ? 'Take quiz' : courseState?.subtopic?.retry !== true ? `Take again in ${courseState?.subtopic?.retry} hour${Boolean(courseState?.subtopic?.retry) ? 's' : ''}` : courseState?.subtopic?.status === 1 ? 'Take again' : 'Retake assessment'
              }
            </EDUButton>
          </div>
        </div>
        {
          courseState?.subtopic?.attempt && (
            <div className="border-y-grayLine flex flex-col md:flex-row items-center justify-between border-y-2 md:px-[21px] py-[10px]">
              <div className="flex w-full flex-row gap-x-[30px]">
                <div className="flex flex-col md:flex-row md:gap-x-[10px]">
                  <p className="text-body text-edu-lightGrayText">Status:</p>
                  {courseState?.subtopic?.status === 1 ? (
                    <div className="flex flex-row items-center gap-x-[6px]">
                      <CheckCircle sx={{ color: 'success.main', fontSize: '14px' }} />
                      <p className="text-center text-subtitle text-edu-darkText">Pass</p>
                    </div>
                  ) : (
                    <div className="flex flex-row items-center gap-x-[6px]">
                      <p className="text-center text-subtitle text-edu-error">Failed</p>
                    </div>
                  )}
                </div>
                <div className="flex flex-col md:flex-row md:gap-x-[10px]">
                  <p className="text-body text-edu-lightGrayText">Total attempt:</p>
                  <p className="text-body text-edu-darkText">{courseState?.subtopic?.attempt}</p>
                </div>
                <div className="flex flex-col md:flex-row md:gap-x-[10px]">
                  <p className="text-body text-edu-lightGrayText">Highest grade:</p>
                  <p className="text-body text-edu-darkText">{Math.round(courseState?.subtopic?.grade)}%</p>
                </div>
              </div>
              <div onClick={() => handleView(courseState?.id)}>
                <p className="cursor-pointer my-[8px] md:my-[8px] text-subtitle text-edu-information underline">
                  View
                </p>
              </div>
            </div>
          )
        }
        <div
          className={`flex w-full flex-row items-center ${courseState?.subtopic?.status === 0 ? 'bg-edu-lightYellow' : 'bg-edu-defaultBackground'
            } py-3 px-4`}
        >
          <Info
            sx={{ fontSize: '14px', color: courseState?.subtopic?.status === 0 ? 'primary.main' : 'info.main', mr: 1 }}
          />
          <p
            className={`text-body ${courseState?.subtopic?.status === 0 ? 'text-edu-primary' : 'text-edu-lightGrayText'}`}
          >
            To pass, score at least {courseState?.subtopic?.passing_mark}% or higher
          </p>
        </div>
      </div>
      {courseState?.subtopic?.is_completed && courseState?.next && (
        <div className="mt-[20px] flex w-full flex-col gap-y-[20px] rounded-[4.5px] bg-edu-defaultBackground py-6 px-5">
          <div>
            <p className="text-subtitle text-edu-lightGrayText">UP NEXT</p>
          </div>
          <div className="flex flex-col justify-start space-y-[20px] md:space-y-0 items-start md:flex-row md:items-center md:justify-between">
            <div className="flex flex-row items-center gap-x-[20px]">
              <div style={{ backgroundImage: `url(${courseState?.next?.type === undefined ? courseDetails?.thumbnail_url : courseState?.next?.type === 'RECAP' ? recapImage : gradedImage})` }} className="self-stretch w-[100px] bg-edu-grayBackground bg-cover bg-center bg-no-repeat" />
              <div className="flex flex-col gap-y-[10px]">
                <p className="text-subtitle text-edu-darkText">
                  {courseState?.next?.title}
                </p>
                <p className="text-body text-edu-lightGrayText">{hours !== '00' && `${hours.slice(-1, 2)}h`}{' '}
                  {minutes && `${minutes}min`}</p>
              </div>
            </div>
            <EDUButton variant="contained" sx={{ width: { xs: '100%', md: '150px' } }} onClick={() => handleNext()}>
              <div className="flex flex-row items-center space-x-[10px]">
                <p className="text-subtitle">Continue</p>
              </div>
            </EDUButton>
          </div>
        </div>
      )}
    </div>
  )
}

export default GradedQuizDashboard
