import axios, { getData } from 'lib/axios'

export const retrieveQuizQuestions = async (id, enrollment_id) => {
  const { data } = await axios.get(`adx_edu/${enrollment_id}/form/${id}`).then(getData)
  return data
}

export const quizSubmission = async (id, payload) => {
  return await axios.post(`adx_edu/form/submit/${id}`, payload).then(getData)
}

export const getRecapAnswer = async (id) => {
  const { data } = await axios.get(`adx_edu/form/answer/${id}`).then(getData)
  return data
}

export const getGradedAnswer = async (eduFormId, payload) => {
  const { data } = await axios.post(`adx_edu/form/response/${eduFormId}`, payload).then(getData)
  return data
}