import Link from '@mui/material/Link'
import { Link as RRDLink, useNavigate } from 'react-router-dom'
import { Box, Typography, useMediaQuery, useTheme, Grid } from '@mui/material'
import EDUButton from 'components/Button/EDUButton'
import AdnexioEDUWhite from '../../assets/logos/adnexio-logo-white-edu-v2-aug.png'
import { Copyright, Facebook, Instagram, Twitter, LinkedIn } from '@mui/icons-material'
import Tiktok from './assets/tiktok_icon.jpg'

const Footer = () => {
  // Auto change year
  let thisDay = new Date()
  let thisYear = thisDay.getFullYear()
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const navigate = useNavigate()

  return (
    <>
      {md ? (
        //desktop view
        <>
          <Box className="flex-col bg-adx-darkText " sx={{ display: 'flex' }}>
            <Box className="m-auto max-w-[1200px]" sx={{ display: 'flex', width: '100%' }}>
              <Box sx={{ p: '60px', width: '50%' }}>
                <Typography variant="h3" sx={{ color: 'white.main', mb: '4px' }}>
                  Get Started With Zero Experiences
                </Typography>
                <Typography variant="body1" sx={{ color: 'white.main', mb: '25px' }}>
                  Gain the education and experience you need to move forward today with adnexioedu.
                </Typography>
                <EDUButton
                  variant="contained"
                  onClick={() => navigate('/signup')}
                  sx={{
                    backgroundColor: 'white.main',
                    '&:hover': {
                      color: 'dark-text.main',
                      backgroundColor: 'white.main',
                    },
                  }}
                >
                  <Typography variant="h4" sx={{ color: 'dark-text.main' }}>
                    SIGN UP FOR FREE
                  </Typography>
                </EDUButton>
              </Box>
              <Box sx={{ p: '60px', width: '50%', display: 'flex', columnGap: '10px', marginBottom: '100px' }}>
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '150px' }}
                >
                  <Typography variant="subtitle1" sx={{ color: 'white.main' }}>
                    EDU
                  </Typography>
                  <RRDLink to="/explore-courses">
                    <Typography variant="body1" sx={{ color: 'white.main' }}>
                      Course
                    </Typography>
                  </RRDLink>
                  <Typography onClick={() => window.open(`${process.env.REACT_APP_URL_REDIRECT}/bootcamp`)} variant="body1" sx={{ color: 'white.main', cursor: 'pointer' }}>
                    Bootcamp
                  </Typography>
                  <RRDLink to="/explore-courses">
                    <Typography variant="body1" sx={{ color: 'white.main' }}>
                      Mini course
                    </Typography>
                  </RRDLink>
                </Box>
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '150px' }}
                >
                  <Typography variant="subtitle1" sx={{ color: 'white.main' }}>
                    SUPPORT
                  </Typography>
                  <RRDLink to="/contact-us">
                    <Typography variant="body1" sx={{ color: 'white.main' }}>
                      Contact us
                    </Typography>
                  </RRDLink>
                  <RRDLink to="/terms-conditions">
                    <Typography variant="body1" sx={{ color: 'white.main' }}>
                      Terms of use
                    </Typography>
                  </RRDLink>
                  <RRDLink to="/privacy-policy">
                    <Typography variant="body1" sx={{ color: 'white.main' }}>
                      Privacy Policies
                    </Typography>
                  </RRDLink>
                </Box>
              </Box>
            </Box>

            <Box
              className="m-auto max-w-[1200px] p-[60px]"
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Link href="#" underline="hover">
                <img alt='adnexio logo' src={AdnexioEDUWhite} className="w-28 lg:w-48" />
              </Link>
              <Typography variant="body2" sx={{ textAlign: 'center', color: 'white.main' }}>
                <Copyright /> {thisYear} Copyright adnexio.jobs. All rights reserved
              </Typography>
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Link href="https://www.linkedin.com/company/adnexiojobs/">
                  <LinkedIn sx={{ color: 'white.main', fontSize: '25px' }} />
                </Link>
                <Link href="https://www.instagram.com/adnexio.jobs/">
                  <Instagram sx={{ color: 'white.main', fontSize: '25px' }} />
                </Link>
                <Link href="https://www.facebook.com/adnexio.jobs">
                  <Facebook sx={{ color: 'white.main', fontSize: '25px' }} />
                </Link>
                <Link href="https://twitter.com/adnexioJobs">
                  <Twitter sx={{ color: 'white.main', fontSize: '25px' }} />
                </Link>
                <Link href="https://www.tiktok.com/@adnexio?_t=8YyrC8QIyIc&_r=1">
                  <img alt='tiktok logo' src={Tiktok} />
                </Link>
              </Box>
            </Box>
          </Box>

          <Box
            className="m-auto max-w-[1200px]"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: 'white.main',
              py: '30px',
              px: '60px',
            }}
          >
            <Typography variant="subtitle1" sx={{ color: 'light-gray-text.main' }}>
              OUR PRODUCTS TO DISCOVER
            </Typography>
            <Box sx={{ display: 'flex', columnGap: '50px' }}>
              <Link
                sx={{ textDecoration: 'none' }}
                href='#'
              >
                <Typography sx={{ textDecoration: 'underline', color: 'dark-text.main' }}>
                  adnexioedu
                </Typography>
              </Link>
              <Link
                sx={{ textDecoration: 'none' }}
                href={process.env.REACT_APP_ADNEXIO_HR_URL}
                target='_blank'
                rel='noreferrer'
              >
                <Typography sx={{ textDecoration: 'underline', color: 'dark-text.main' }}>
                  adnexiohr
                </Typography>
              </Link>
              <Link
                sx={{ textDecoration: 'none' }}
                href={process.env.REACT_APP_URL_REDIRECT}
                target='_blank'
                rel='noreferrer'
              >
                <Typography sx={{ textDecoration: 'underline', color: 'dark-text.main' }}>
                  adnexiojobs
                </Typography>
              </Link>
              <Link
                sx={{ textDecoration: 'none' }}
                href={process.env.REACT_APP_URL_REDIRECT + '/gigs'}
                target='_blank'
                rel='noreferrer'
              >
                <Typography sx={{ textDecoration: 'underline', color: 'dark-text.main' }}>
                  adnexiogigs
                </Typography>
              </Link>
            </Box>
          </Box>
        </>
      ) : (
        //mobile view
        <>
          <Box
            className=" bg-adx-darkText "
            sx={{ display: 'flex', flexDirection: 'column', px: '20px', py: '60px' }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '80px' }}>
              <Box sx={{ width: '100%' }}>
                <Typography variant="h3" sx={{ color: 'white.main', mb: '4px' }}>
                  Get Started With Zero Experiences
                </Typography>
                <Typography variant="body1" sx={{ color: 'white.main', mb: '25px' }}>
                  Gain the education and experience you need to move forward today with adnexioedu.
                </Typography>
                <EDUButton
                  variant="contained"
                  onClick={() => navigate('/signup')}
                  sx={{
                    backgroundColor: 'white.main',
                    '&:hover': {
                      color: 'dark-text.main',
                      backgroundColor: 'white.main',
                    },
                  }}
                >
                  <Typography variant="h4" sx={{ color: 'dark-text.main' }}>
                    SIGN UP FOR FREE
                  </Typography>
                </EDUButton>
              </Box>
              <Box sx={{ p: '20px', display: 'flex', columnGap: '10px', marginBottom: '100px' }}>
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '100%' }}
                >
                  <Typography variant="subtitle1" sx={{ color: 'white.main' }}>
                    EDU
                  </Typography>
                  <RRDLink to="/explore-courses">
                    <Typography variant="body1" sx={{ color: 'white.main' }}>
                      Course
                    </Typography>
                  </RRDLink>
                  <Typography onClick={() => window.open(`${process.env.REACT_APP_URL_REDIRECT}/bootcamp`)} variant="body1" sx={{ color: 'white.main', cursor: 'pointer' }}>
                    Bootcamp
                  </Typography>
                  <RRDLink to="/explore-courses">
                    <Typography variant="body1" sx={{ color: 'white.main' }}>
                      Mini course
                    </Typography>
                  </RRDLink>
                </Box>
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '100%' }}
                >
                  <Typography variant="subtitle1" sx={{ color: 'white.main' }}>
                    SUPPORT
                  </Typography>
                  <RRDLink to="/contact-us">
                    <Typography variant="body1" sx={{ color: 'white.main' }}>
                      Contact us
                    </Typography>
                  </RRDLink>
                  <RRDLink to="/terms-conditions">
                    <Typography variant="body1" sx={{ color: 'white.main' }}>
                      Terms of use
                    </Typography>
                  </RRDLink>
                  <RRDLink to="/privacy-policy">
                    <Typography variant="body1" sx={{ color: 'white.main' }}>
                      Privacy Policies
                    </Typography>
                  </RRDLink>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                rowGap: '30px',
              }}
            >
              <Link href="#" underline="hover">
                <img src={AdnexioEDUWhite} className="w-64" alt="adnexio logo" />
              </Link>
              <Typography variant="body2" sx={{ color: 'white.main', textAlign: 'center' }}>
                <Copyright /> {thisYear} Copyright adnexio.jobs. All rights reserved
              </Typography>
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Link href="https://www.linkedin.com/company/adnexiojobs/">
                  <LinkedIn sx={{ color: 'white.main', fontSize: '25px' }} />
                </Link>
                <Link href="https://www.instagram.com/adnexio.jobs/">
                  <Instagram sx={{ color: 'white.main', fontSize: '25px' }} />
                </Link>
                <Link href="https://www.facebook.com/adnexio.jobs">
                  <Facebook sx={{ color: 'white.main', fontSize: '25px' }} />
                </Link>
                <Link href="https://twitter.com/adnexioJobs">
                  <Twitter sx={{ color: 'white.main', fontSize: '25px' }} />
                </Link>
                <Link href="https://www.tiktok.com/@adnexio?_t=8YyrC8QIyIc&_r=1">
                  <img alt='tiktok logo' src={Tiktok} />
                </Link>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: 'white.main',
              py: '30px',
              px: '20px',
            }}
          >
            <Typography variant="subtitle1" sx={{ color: 'light-gray-text.main' }}>
              OUR PRODUCTS TO DISCOVER
            </Typography>
            <Box sx={{ width: '100%', mt: '30px' }}>
              <Grid container spacing={1} alignItems="center" justifyContent="center">
                <Grid item xs={4}>
                  <Link
                    sx={{ textDecoration: 'none', width: '100%', textAlign: 'center' }}
                    href='#'
                  >
                    <Typography sx={{ textDecoration: 'underline', color: 'dark-text.main' }}>
                      adnexioedu
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={4}>
                  <Link
                    sx={{ textDecoration: 'none', width: '100%', textAlign: 'center' }}
                    href={process.env.REACT_APP_ADNEXIO_HR_URL}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Typography sx={{ textDecoration: 'underline', color: 'dark-text.main' }}>
                      adnexiohr
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={4}>
                  <Link
                    sx={{ textDecoration: 'none', width: '100%', textAlign: 'center' }}
                    href={process.env.REACT_APP_URL_REDIRECT}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Typography sx={{ textDecoration: 'underline', color: 'dark-text.main' }}>
                      adnexiojobs
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link
                    sx={{ textDecoration: 'none', width: '100%', textAlign: 'center' }}
                    href={process.env.REACT_APP_URL_REDIRECT + '/gigs'}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Typography sx={{ textDecoration: 'underline', color: 'dark-text.main' }}>
                      adnexiogigs
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </>
  )
}

export default Footer
