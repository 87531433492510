import React from "react";
import Typography from "@mui/material/Typography";

const EDUErrorMessage = ({ children }) => {
  return (
    <Typography variant="body2" color="error.main" mt={0.5}>
      {children}
    </Typography>
  );
};

export default EDUErrorMessage;
