import { Skeleton, useMediaQuery, useTheme } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import EDUBreadcrumb from 'components/Breadcrumbs/EDUBreadcrumb'
import EDUBreadLink from 'components/Breadcrumbs/EDUBreadLink'
import EDUTabBadge from 'components/Tab/EDUTabBadge'
import RecapQuizDashboard from 'pages/recapQuiz/components/RecapQuizDashboard'
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router'
import { getModuleDetails } from 'services/api/myClass'
import GradedQuizDashboard from '../components/GradedQuizDashboard'
import LessonPlanAccordion from '../components/LessonPlanAccordion'
import MaterialsTab from '../components/MaterialsTab'
import OverviewTab from '../components/OverviewTab'
import VideoPlayer from '../components/VideoPlayer'
import { Error, Verified } from '@mui/icons-material'
import MobileLessonTab from '../components/MobileLessonTab'
import CertificateTab from '../components/CompletedCertificateTab'
import Bugsnag from '@bugsnag/js'
import EDUModal from 'components/Modal/EDUModal'
import completedImage from '../assets/completed-course-thumbnail.png'
import EDUButton from 'components/Button/EDUButton'
import { getCertificate } from 'services/api/profile'

const MyClassPage = ({ setVideoProgressFunction }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { state } = useLocation()
  const theme = useTheme()
  const lg = useMediaQuery(theme.breakpoints.up('lg'))
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const [courseState, setCourseState] = useState({})
  const [expanded, setExpanded] = useState()
  const [loadingCert, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const handleCloseModal = () => {
    setOpenModal(false)
  }
  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleOnMount = (topics) => {
    let incompleteSession = topics
      ?.find((topic) =>
        topic?.course_sessions?.find(
          (obj) => obj?.video?.is_completed === null || obj?.quiz?.is_completed === false
        )
      )
      ?.course_sessions?.find(
        (obj) => obj?.video?.is_completed === null || obj?.quiz?.is_completed === false
      )

    let incompleteSessionIndex = topics
      ?.find((topic) =>
        topic?.course_sessions?.find(
          (obj) => obj?.video?.is_completed === null || obj?.quiz?.is_completed === false
        )
      )
      ?.course_sessions?.findIndex(
        (obj) => obj?.video?.is_completed === null || obj?.quiz?.is_completed === false
      )

    setCourseState({
      id:
        incompleteSession?.video?.is_completed === null
          ? incompleteSession?.video?.id
          : incompleteSession?.quiz?.id,
      topic: topics?.find((topic) =>
        topic?.course_sessions?.find(
          (obj) => obj?.video?.is_completed === null || obj?.quiz?.is_completed === false
        )
      )?.topic_title,

      subtopic:
        incompleteSession?.video?.is_completed === null
          ? incompleteSession?.video
          : incompleteSession?.quiz,

      material: topics?.find((topic) =>
        topic?.course_sessions?.find(
          (obj) => obj?.video?.is_completed === null || obj?.quiz?.is_completed === false
        )
      )?.material,

      overview: topics?.find((topic) =>
        topic?.course_sessions?.find(
          (obj) => obj?.video?.is_completed === null || obj?.quiz?.is_completed === false
        )
      )?.topic_description,

      type:
        incompleteSession?.video?.is_completed === null
          ? 'video'
          : incompleteSession?.quiz?.type === 'RECAP'
            ? 'recap'
            : 'graded',

      next:
        incompleteSession?.video?.is_completed === null
          ? incompleteSession?.quiz
          : topics?.find((topic) =>
            topic?.course_sessions?.find(
              (obj) => obj?.video?.is_completed === null || obj?.quiz?.is_completed === false
            )
          )?.course_sessions?.length !==
            incompleteSessionIndex + 1
            ? topics?.find((topic) =>
              topic?.course_sessions?.find(
                (obj) => obj?.video?.is_completed === null || obj?.quiz?.is_completed === false
              )
            )?.course_sessions[incompleteSessionIndex + 1]?.video ||
            topics?.find((topic) =>
              topic?.course_sessions?.find(
                (obj) => obj?.video?.is_completed === null || obj?.quiz?.is_completed === false
              )
            )?.course_sessions[incompleteSessionIndex + 1]?.quiz
            : topics[
              topics?.findIndex((topic) =>
                topic?.course_sessions?.find(
                  (obj) => obj?.video?.is_completed === null || obj?.quiz?.is_completed === false
                )
              ) + 1
            ]?.course_sessions[0]?.video,
    })
  }
  const handleCourseCompletedOnMount = (topics) => {
    setCourseState({
      id: topics[0]?.course_sessions[0]?.video?.id,
      topic: topics[0]?.topic_title,
      subtopic: topics[0]?.course_sessions[0]?.video,
      material: topics[0]?.material,
      overview: topics[0]?.topic_description,
      type: 'video',
      next: topics[0]?.course_sessions[0]?.quiz,
    })
  }
  const { data, isLoading, isInitialLoading, isRefetching } = useQuery(
    ['moduleDetails', id],
    () => getModuleDetails(id),
    {
      onError: (error) => {
        if (error.status === 404) {
          navigate('/course-not-found')
        }
        Bugsnag.notify(error)
      },
    }
  )
  useEffect(() => {
    setExpanded(data?.topics?.find((topic) => topic?.topic_title === courseState?.topic)?.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseState])

  useEffect(() => {
    if (courseState && courseState?.type === 'video') {
      setCourseState({ ...courseState })
    } else {
      //state
      if (Boolean(state) && !state?.next && data?.certificate) {
        handleOpenModal()
      }
      if (state) {
        let currentSubtopic = data?.topics
          ?.find((topic) =>
            topic?.course_sessions?.find((session) =>
              state?.subtopic?.type === undefined
                ? state?.subtopic?.id === session?.video?.id
                : state?.subtopic?.id === session?.quiz?.id
            )
          )
          ?.course_sessions?.find((session) =>
            state?.subtopic?.type === undefined
              ? state?.subtopic?.id === session?.video?.id
              : state?.subtopic?.id === session?.quiz?.id
          )
        let currentTopicIndex = data?.topics?.findIndex((topic) =>
          topic?.course_sessions?.find((session) => state?.subtopic?.id === session?.quiz?.id)
        )
        setCourseState({
          ...state,
          subtopic:
            state?.subtopic?.id === currentSubtopic?.video?.id && Boolean(!state?.subtopic?.type)
              ? currentSubtopic?.video
              : currentSubtopic?.quiz,
          next:
            state?.subtopic?.type === 'GRADED'
              ? data?.topics[currentTopicIndex + 1]?.course_sessions[0]?.video
              : state?.next,
        })
      } else if (data?.certificate) {
        handleCourseCompletedOnMount(data?.topics)
      } else {
        handleOnMount(data?.topics)
      }
    }
    window.history.replaceState({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoading, state, isRefetching])

  const handleCourseState = (subtopic) => {
    setCourseState(subtopic)
  }

  const handleNextCourse = () => {
    let nextSession = data?.topics
      ?.find((topic) =>
        topic?.course_sessions?.find((obj) => {
          if (courseState?.next?.type === undefined) {
            return courseState?.next?.id === obj?.video?.id
          } else {
            return courseState?.next?.id === obj?.quiz?.id
          }
        })
      )
      ?.course_sessions?.find((obj) => {
        if (courseState?.next?.type === undefined) {
          return courseState?.next?.id === obj?.video?.id
        } else {
          return courseState?.next?.id === obj?.quiz?.id
        }
      })

    let nextSessionIndex = data?.topics
      ?.find((topic) =>
        topic?.course_sessions?.find((obj) => {
          if (courseState?.next?.type === undefined) {
            return courseState?.next?.id === obj?.video?.id
          } else {
            return courseState?.next?.id === obj?.quiz?.id
          }
        })
      )
      ?.course_sessions?.findIndex((obj) => {
        if (courseState?.next?.type === undefined) {
          return courseState?.next?.id === obj?.video?.id
        } else {
          return courseState?.next?.id === obj?.quiz?.id
        }
      })
    setCourseState({
      subtopic: courseState?.next,
      type:
        courseState?.next?.type === undefined
          ? 'video'
          : courseState?.next?.type === 'RECAP'
            ? 'recap'
            : 'graded',
      id: courseState?.next?.id,
      topic: data?.topics?.find((topic) =>
        topic?.course_sessions?.find((obj) => {
          if (courseState?.next?.type === undefined) {
            return courseState?.next?.id === obj?.video?.id
          } else {
            return courseState?.next?.id === obj?.quiz?.id
          }
        })
      )?.topic_title,
      overview: data?.topics?.find((topic) =>
        topic?.course_sessions?.find((obj) => {
          if (courseState?.next?.type === undefined) {
            return courseState?.next?.id === obj?.video?.id
          } else {
            return courseState?.next?.id === obj?.quiz?.id
          }
        })
      )?.topic_description,
      material: data?.topics?.find((topic) =>
        topic?.course_sessions?.find((obj) => {
          if (courseState?.next?.type === undefined) {
            return courseState?.next?.id === obj?.video?.id
          } else {
            return courseState?.next?.id === obj?.quiz?.id
          }
        })
      )?.material,
      next:
        courseState?.next?.type === undefined
          ? nextSession?.quiz ||
          data?.topics?.find((topic) =>
            topic?.course_sessions?.find((obj) => {
              if (courseState?.next?.type === undefined) {
                return courseState?.next?.id === obj?.video?.id
              } else {
                return courseState?.next?.id === obj?.quiz?.id
              }
            })
          )?.course_sessions[nextSessionIndex + 1]?.video ||
          data?.topics?.find((topic) =>
            topic?.course_sessions?.find((obj) => {
              if (courseState?.next?.type === undefined) {
                return courseState?.next?.id === obj?.video?.id
              } else {
                return courseState?.next?.id === obj?.quiz?.id
              }
            })
          )?.course_sessions[nextSessionIndex + 1]?.quiz
          : data?.topics?.find((topic) =>
            topic?.course_sessions?.find((obj) => {
              if (courseState?.next?.type === undefined) {
                return courseState?.next?.id === obj?.video?.id
              } else {
                return courseState?.next?.id === obj?.quiz?.id
              }
            })
          )?.course_sessions?.length !==
            nextSessionIndex + 1
            ? data?.topics?.find((topic) =>
              topic?.course_sessions?.find((obj) => {
                if (courseState?.next?.type === undefined) {
                  return courseState?.next?.id === obj?.video?.id
                } else {
                  return courseState?.next?.id === obj?.quiz?.id
                }
              })
            )?.course_sessions[nextSessionIndex + 1]?.video ||
            data?.topics?.find((topic) =>
              topic?.course_sessions?.find((obj) => {
                if (courseState?.next?.type === undefined) {
                  return courseState?.next?.id === obj?.video?.id
                } else {
                  return courseState?.next?.id === obj?.quiz?.id
                }
              })
            )?.course_sessions[nextSessionIndex + 1]?.quiz
            : data?.topics[
              data?.topics?.findIndex((topic) =>
                topic?.course_sessions?.find((obj) => {
                  if (courseState?.next?.type === undefined) {
                    return courseState?.next?.id === obj?.video?.id
                  } else {
                    return courseState?.next?.id === obj?.quiz?.id
                  }
                })
              ) + 1
            ]?.course_sessions[0]?.video,
    })
  }

  const handleViewCertificate = async () => {
    setIsLoading(true)
    try {
      const res = await getCertificate(data?.certificate?.cert_id)
      const element = document.createElement('a')
      const file = new Blob([res.data], { type: res.headers['content-type'] })
      element.href = URL.createObjectURL(file)
      element.target = '_blank'
      element.click()
    } catch (error) {
      Bugsnag.notify(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="relative px-[30px]">
      <EDUBreadcrumb
        sx={{
          '&.MuiBreadcrumbs-root': {
            py: 1.5,
            mt: '14px',
          },
        }}
        separatorSx={{ fontSize: 20 }}
      >
        <EDUBreadLink href="/my-learning">My learning</EDUBreadLink>
        {isLoading ? <Skeleton width="200px" /> : <EDUBreadLink last>{data?.title}</EDUBreadLink>}
      </EDUBreadcrumb>
      <div className="flex h-full w-full flex-row py-[10px]">
        <div className='lg:w-2/4" h-full w-full lg:pr-[25px]'>
          {isLoading ? (
            <div className="w-full">
              <Skeleton variant="rectangular" width="100%" height="400px" />
              <Skeleton width="10%" sx={{ mt: '20px' }} />
              <Skeleton width="30%" variant="rectangular" height="20px" sx={{ mt: '6px' }} />
            </div>
          ) : courseState?.type === 'video' ? (
            <>
              <div className="mb-[20px] w-full">
                {!courseState?.subtopic?.url ? (
                  <div className=" relative w-full ">
                    <img src={data?.thumbnail_url} alt={`${data?.title} thumbnail`} />
                    <div className="absolute top-0 left-0 h-full w-full bg-[#3A3A3AB8]" />
                    <div className="z-2 h-full w-full">
                      <div
                        className={`z-2 absolute bottom-0 left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center`}
                      >
                        <Error sx={{ color: 'white.main', fontSize: { xs: 30, md: 40 } }} />
                        <p className="mt-[14px] text-center text-edu-white md:mt-[24px]">
                          You have incomplete task before you can continue to this section
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <VideoPlayer
                    source={courseState?.subtopic?.url}
                    state={courseState}
                    enrollment_id={id}
                    handleNext={handleNextCourse}
                    setVideoProgressFunction={setVideoProgressFunction}
                  />
                )}
              </div>
              <p className="text-subtitle text-edu-lightGrayText">{courseState?.topic}</p>
              <h3 className="mt-[6px] text-h3">{courseState?.subtopic?.title}</h3>
            </>
          ) : courseState?.type === 'recap' ? (
            <RecapQuizDashboard
              enrollment_id={id}
              courseState={courseState}
              setCourseSession={handleCourseState}
              handleNext={handleNextCourse}
              courseDetails={data}
            />
          ) : (
            courseState?.type === 'graded' && (
              <GradedQuizDashboard
                enrollment_id={id}
                courseState={courseState}
                setCourseSession={handleCourseState}
                handleNext={handleNextCourse}
                courseDetails={data}
              />
            )
          )}
          <EDUTabBadge
            tabsBorderBottom
            sx={{
              '& .MuiTabScrollButton-root': {
                display: md && 'none',
              },
              mt: '20px',
            }}
          >
            {courseState?.overview !== '' && (
              <OverviewTab label="Overview" overview={courseState?.overview} />
            )}
            {!lg && (
              <MobileLessonTab
                label="Lesson"
                courseDetails={data?.topics}
                courseState={courseState}
                setCourseSession={handleCourseState}
                expanded={expanded}
                setExpanded={setExpanded}
              />
            )}
            {courseState?.material?.length > 0 && (
              <MaterialsTab label="Materials" readingMaterials={courseState?.material} />
            )}
            {!isLoading && data?.certificate !== null && (
              <CertificateTab label="Certificate" data={data?.certificate} />
            )}
          </EDUTabBadge>
        </div>
        <div className="box-border hidden h-full w-2/4 pl-[25px] lg:sticky lg:top-[75px] lg:block">
          <LessonPlanAccordion
            courseDetails={data?.topics}
            courseState={courseState}
            setCourseSession={handleCourseState}
            expanded={expanded}
            setExpanded={setExpanded}
            isLoading={isLoading}
          />
        </div>
      </div>
      <EDUModal
        open={openModal}
        modalWidth={600}
        onClose={handleCloseModal}
        sxModal={{
          px: 0,
          py: 0,
          borderRadius: '4.5px',
        }}
      >
        <div
          style={{ backgroundImage: `url(${completedImage})` }}
          className="h-[240px] w-full bg-cover bg-center bg-no-repeat"
        />
        <div className="space-y-[32px] p-[40px]">
          <div className="space-y-[16px]">
            <h2 className="text-center text-h3 md:text-h2">
              Congratulations on completing the course!
            </h2>
            <p className="text-center">
              Your hard work and dedication have paid off. You’ve finally finished your lesson and
              received a certificate. Good job and keep up the great work!
            </p>
          </div>
          <div className={`w-full rounded-[4.5px] border border-edu-grayLine px-[16px] py-[18px] `}>
            <div className="flex items-center space-x-[18px]">
              <div className="flex items-center justify-center rounded-full bg-edu-lightYellow p-[12px]">
                <Verified sx={{ color: 'primary.main', fontSize: 24 }} />
              </div>
              <div className="flex flex-col">
                <p className="mb-[4px] text-subtitle">Certificate of completion</p>
                <div className="flex flex-col items-start space-y-[4px] md:flex-row md:items-center md:space-y-0">
                  <p className="text-body2 capitalize text-edu-lightGrayText">
                    This course was successfully completed on {data?.certificate?.completed_date}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-[8px]">
            <EDUButton
              disabled={loadingCert}
              loading={loadingCert}
              onClick={handleViewCertificate}
              fullWidth
              variant="contained"
            >
              View certificate
            </EDUButton>
            <EDUButton fullWidth onClick={handleCloseModal} variant="outlined">
              Close
            </EDUButton>
          </div>
        </div>
      </EDUModal>
    </div>
  )
}

export default MyClassPage
