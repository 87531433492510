const { createTheme } = require('@mui/material')

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#F3BF3A', //yellow
    },
    secondary: {
      main: '#2F80ED', //blue button, checkbox, radio etc
    },
    'green-01': {
      main: '#09A3A9',
      contrastText: '#FFFFFF',
    },
    'light-green-01': {
      main: '#E6F6F6',
    },
    'green-02': {
      main: '#0F6264',
      contrastText: '#FFFFFF',
    },
    'light-green-02': {
      main: '#CCFBF1',
    },
    'yellow-01': {
      main: '#EAB308',
    },
    'light-yellow-01': {
      main: '#FDF6E2',
    },
    'yellow-02': {
      main: '#D09A01',
    },
    'dark-text': {
      main: '#3A3A3A',
    },
    'light-gray-text': {
      main: '#8A8A8A',
    },
    'light-gray-text-2': {
      main: '#C6CAC9',
    },
    'gray-line': {
      main: '#E5E5E5',
    },
    'gray-background': {
      main: '#EDEFF0',
    },
    'default-background': {
      main: '#F7F9FA',
    },
    success: {
      main: '#2FED64',
    },
    'light-success': {
      main: '#E0FCE8',
    },
    error: {
      main: '#EB5757', //red
    },
    'light-error': {
      main: '#FDEEEE',
    },
    warning: {
      main: '#E2B93B',
    },
    'light-warning': {
      main: '#FBF5E2',
    },
    information: {
      main: '#27ABF5',
    },
    'light-information': {
      main: '#EAF2FD',
    },
    white: {
      main: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    h1: {
      fontSize: '34px',
      lineHeight: `${34 * 1.5}px`,
      fontWeight: 500,
    },
    h2: {
      fontSize: '24px',
      lineHeight: `${24 * 1.5}px`,
      fontWeight: 500,
    },
    h3: {
      fontSize: '20px',
      lineHeight: `${20 * 1.5}px`,
      fontWeight: 500,
    },
    h4: {
      fontSize: '16px',
      lineHeight: `${16 * 1.5}px`,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '14px',
      lineHeight: `${14 * 1.5}px`,
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '12px',
      lineHeight: `${12 * 1.5}px`,
      fontWeight: 500,
    },
    body1: {
      fontSize: '14px',
      lineHeight: `${14 * 1.5}px`,
      fontWeight: 400,
    },
    body2: {
      fontSize: '12px',
      lineHeight: `${12 * 1.5}px`,
      fontWeight: 400,
    },
    button: {
      textTransform: 'initial',
    },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiButton-contained': {
            color: '#FFFFFF', //white
          },
          minHeight: '45px',
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          outline: '1px solid',
          outlineColor: '#8A8A8A', //light-gray-text
          '&.Mui-focused': {
            outline: '1px  solid',
            outlineColor: '#F3BF3A', //primary yellow
          },
          '&:hover': {},
          '&.Mui-disabled': {
            backgroundColor: '#F7F9FA', //gray-background
            outline: '1px solid',
            outlineColor: '#E5E5E5', //gray-inactive-button
          },
        },
        input: {
          color: '#3A3A3A', //black OR dark text
          '&::placeholder': {
            color: '#8A8A8A', //light-gray-text
            opacity: 1,
          },
        },
      },
    },
    // MuiMenuItem: {
    //   defaultProps: {
    //     mx: 2,
    //   },
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: 'transparent !important',
    //       '&:hover': {
    //         backgroundColor: '#FEF9C3 !important', //light-yellow-1 TBC with designer
    //       },
    //       '&:focus': {
    //         backgroundColor: '#FEF9C3 !important', //light-yellow-1 TBC with designer
    //       },
    //     },
    //   },
    // },
    // MuiCssBaseline: {
    //   styleOverrides: {
    //     '*': {
    //       '&::-webkit-scrollbar': {
    //         width: '0.5em',
    //         height: '0.5rem',
    //       },
    //       '&::-webkit-scrollbar-track': {
    //         '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    //       },
    //       '&::-webkit-scrollbar-thumb': {
    //         backgroundColor: '#5D5D5D', //light-grey-text-2
    //         borderRadius: '1rem',
    //       },
    //       '&::-webkit-scrollbar-thumb:hover': {
    //         backgroundColor: '#5D5D5D', //light-grey-text-2
    //       },
    //       '&::-webkit-scrollbar-thumb:active': {
    //         backgroundColor: '#8A8A8A', //light-gray-text
    //       },
    //     },
    //   },
    // },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      '2xl': 1536,
    },
  },
})
