import Box from '@mui/material/Box'
import React from 'react'

const EDUTabPanel = ({
  children,
  sx,
  label,
  notification,
  sideProps,
  icon,
  iconPosition,
  ...rest
}) => {
  return (
    <Box
      aria-label={`label: ${label ? label : 'label'}, notification: ${
        notification ? notification : 0
      }, sideProps: ${sideProps ? sideProps : 'None'}, icon: ${
        icon ? icon : null
      }}, iconPosition: ${iconPosition ? iconPosition : null}`}
      sx={{ ...sx }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default EDUTabPanel
