import { Box, Typography, Stack, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import BannerOne from '../../asset/landingpage_laptop.jpg'

const SectionFour = () => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const xl = useMediaQuery(theme.breakpoints.up('xl'))
  return (
    <Stack direction={{ xs: 'column-reverse', md: 'row' }}>
      <Box
        sx={{
          backgroundColor: 'default-background.main',
          width: md ? '50%' : '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: xl ? 'flex-end' : 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: '60px',
            maxWidth: '600px',
          }}
        >
          <Typography
            variant={md ? 'h1' : 'h2'}
            sx={{ mb: '10px', textAlign: md ? 'none' : 'center' }}
          >
            Interactive Lessons
          </Typography>
          <Typography variant="body1" sx={{ mb: '20px', textAlign: md ? 'none' : 'center' }}>
            We believe that learning should be fun and effective. That's why our courses feature
            interactive lessons and engaging content that make learning a joy.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: 'primary.main',
          width: md ? '50%' : '100%',
          display: 'flex',
          justifyContent: xl ? 'flex-start' : 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ width: '100%', maxWidth: '600px' }}>
          <img alt='' src={BannerOne} width={'100%'} />
        </Box>
      </Box>
    </Stack>
  )
}

export default SectionFour
