import { Box, Typography } from '@mui/material'
import EDUTabBadge from 'components/Tab/EDUTabBadge'
import CompletedTab from '../components/CompletedTab'
import OnGoingTab from '../components/OnGoingTab'
import WishlistTab from '../components/WishlistTab'

const MyLearningPage = () => {
  return (
    <div className="box-border flex h-full w-full flex-col p-[30px]  ">
      <Box sx={{ width: '100%' }}>
        <Typography sx={{ mb: 2 }} variant="h3">
          My Learning
        </Typography>
        <EDUTabBadge tabsBorderBottom sx={{
          '& .MuiTabScrollButton-root': {
            display: { md: 'none' },
          },
        }}>
          <OnGoingTab label="On Going" />
          <CompletedTab label="Completed" />
          <WishlistTab label="Wishlist" />
        </EDUTabBadge>
      </Box>
    </div>
  )
}

export default MyLearningPage
