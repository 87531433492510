import { Box } from "@mui/material"
import confetti from '../assets/Confetti.jpg'
import balloon from '../assets/Balloon.png'
import { Cancel } from "@mui/icons-material"
import EDUButton from "components/Button/EDUButton"

const Summary = ({
  results,
  handleRetake,
  handleEndQuiz,
  handleViewAnswer,
  retryStatus
}) => {
  return (
    <div className=" mx-auto min-h-screen max-w-3xl bg-edu-white ">
      {results?.score >= results?.passing_mark ? (
        <Box
          sx={{
            backgroundImage: `url(${confetti})`,
            width: '100%',
            height: '150px',
            position: 'relative',
            backgroundRepeat: 'none',
            backgroundSize: '100%',
          }}
          alt="confetti"
        >
          <Box
            className="absolute left-1/2 top-1/2 h-[100px] w-[100px] -translate-x-1/2"
            sx={{
              backgroundImage: `url(${balloon})`,
            }}
            alt="balloon"
          />
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '150px',
            position: 'relative',
            backgroundImage: 'linear-gradient(to bottom, #EB5757 -90%, #FFF 100% )',
          }}
        >
          <Box className="absolute left-1/2 top-1/2 -translate-x-1/2">
            <Cancel sx={{ color: 'error.main', fontSize: '100px' }} />
          </Box>
        </Box>
      )}

      <Box sx={{ mt: '80px', px: '50px' }}>
        {results?.score >= results?.passing_mark ? (
          <Box>
            <h2 className="text-center text-h2 text-edu-darkText">
              Congratulations! You've passed
            </h2>
            <p className="text-body1 mt-[6px] text-center text-edu-darkText">
              You can now view your answer and see how well you did. If you want to try again, you can retake the quiz. Otherwise, you can simply end the quiz.
            </p>
          </Box>
        ) : (
          <Box>
            <h2 className="text-center text-h2 text-edu-darkText">Opps! Please try again</h2>
            <p className="text-body1 mt-[6px] text-center text-edu-darkText">
              To pass, you are required to score at least {results?.passing_mark}% or higher. No worries! you may try
              again anytime when you are ready.
            </p>
          </Box>
        )}

        <Box className="border-y-grayLine mt-[30px] flex flex-col justify-between  border-y-2 py-[12px] lg:flex-row lg:items-center">
          <Box className="flex flex-col">
            <h2 className={`text-h2  ${results?.score >= results?.passing_mark ? 'text-edu-darkText' : 'text-edu-error'}`}>
              {Math.round(results?.score)} %
            </h2>
            <p className="text-body1 mt-[3px]  text-edu-lightGrayText">Grade received</p>
          </Box>
          <Box className="flex flex-col">
            <h2 className=" text-h2 text-edu-darkText">{results?.total_questions}</h2>
            <p className="text-body1 mt-[3px]  text-edu-lightGrayText">Total questions</p>
          </Box>
          <Box className="flex flex-col">
            <h2 className=" text-h2 text-edu-darkText">{results?.correct_answer}</h2>
            <p className="text-body1 mt-[3px]  text-edu-lightGrayText">Correct answer</p>
          </Box>
        </Box>
        <Box className="mt-[30px] flex w-full flex-col gap-y-[10px]">
          <div className={`flex ${results?.score >= results?.passing_mark ? 'flex-col-reverse' : 'flex-col'} gap-y-[10px]`}>
            <EDUButton variant="contained" onClick={handleEndQuiz}>
              End quiz
            </EDUButton>
            <EDUButton variant={typeof retryStatus === 'number' ? 'contained' : 'outlined'} disabled={typeof retryStatus === 'number'} onClick={handleRetake}>
              {typeof retryStatus === 'number' ? 'Please try again later' : results?.score >= results?.passing_mark ? 'Take Again' : 'Retake Assessment'}
            </EDUButton>
          </div>
          <EDUButton variant="outlined" onClick={handleViewAnswer}>
            View answer
          </EDUButton>
        </Box>
      </Box>
    </div>
  )
}

export default Summary