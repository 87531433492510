import React from 'react'
import adnexioEDU from 'assets/logos/adnexio-edu-logo.svg'

const PaymentHeader = () => {
  return (
    <header className="sticky top-0 z-20 flex w-full flex-row items-center justify-center border-b border-edu-grayLine bg-[rgba(255,255,255,0.8)] py-[20px] backdrop-blur">
      <img alt="adnexio EDU" src={adnexioEDU} className="h-[20px] md:h-[30px]" />
    </header>
  )
}

export default PaymentHeader
