import { Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import React from 'react'

const SkillsCard = ({ icon, title, message }) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Box
      sx={{
        px: '14px',
        py: '38px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        maxWidth: md ? '267px' : null,
      }}
    >
      {icon}
      <Typography variant="h3" sx={{ textAlign: 'center', mt: '14px', mb: '6px' }}>
        {title}
      </Typography>
      <Typography variant="body1" sx={{ textAlign: 'center', color: 'light-gray-text.main' }}>
        {message}
      </Typography>
    </Box>
  )
}

export default SkillsCard
