import React from 'react'

const CourseDesription = ({ data }) => {
  return (
    <div className="flex  w-full flex-col justify-start md:flex-row md:gap-[20px]">
      <div style={{ backgroundImage: `url(${data?.course_logo})` }} className="self-stretch h-inherit md:h-auto w-full md:w-1/4 bg-cover bg-center bg-no-repeat" />
      <div className="flex w-full flex-col items-start justify-start gap-[4px] md:w-3/4">
        <p className="text-body2 uppercase text-edu-information">{data?.course_type}</p>
        <h4 className="w-full truncate text-h4 capitalize">{data?.course_title}</h4>
        <p className="capitalize text-edu-lightGrayText">{data?.trainer}</p>
      </div>
    </div>
  )
}

export default CourseDesription
