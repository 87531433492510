import EDUButton from 'components/Button/EDUButton'
import EDUCheckbox from 'components/Checkbox/EDUCheckbox'
import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import valueToStringConversion from 'services/helper/valueToStringConversion'
import CourseDesription from './CourseDescription'
import { useMutation } from '@tanstack/react-query'
import { firstPayment } from 'services/api/payment'
import Bugsnag from '@bugsnag/js'

const EduMiniCart = ({ data }) => {
  const [agreement, setAgreement] = useState(false)
  const navigate = useNavigate()

  const { mutate } = useMutation(firstPayment, {
    onSuccess: ({ data }) => window.location.href = data,
    onError: (err) => Bugsnag.notify(err)
  })
  const handleSubmit = () => {
    let values = {
      nex_edu_course_id: data?.course_id,
      is_installment: 0,
      amount: data?.course_fee,
      agree_status: agreement,
      nex_edu_discount_id: null
    }
    mutate(values)
  }
  return (
    <div>
      <CourseDesription
        data={{ course_title: data?.course_title, trainer: data?.trainer_name, course_type: 'COURSE', course_logo: data?.course_logo }}
      />
      <div className="flex min-h-[calc(100vh-192px)] flex-col items-start justify-between md:min-h-[calc(100vh-202px)]">
        <div className="my-[30px] flex w-full flex-col gap-[10px]">
          <h4 className="text-h4">Summary</h4>
          <div className="flex items-center justify-between">
            <p className="capitalize text-edu-lightGrayText">{data?.course_title}</p>
            <p className="text-right text-edu-lightGrayText">RM {valueToStringConversion(data?.course_fee)}</p>
          </div>
          <div className="h-[1px] w-full bg-edu-grayLine" />
          <div className="flex items-center justify-between">
            <p className="text-edu-lightGrayText">Subtotal</p>
            <p className="text-right text-edu-lightGrayText">RM {valueToStringConversion(data?.course_fee)}</p>
          </div>
          <div className="flex items-center justify-between">
            <p className="text-subtitle">Total amount</p>
            <p className="text-right text-subtitle">RM {valueToStringConversion(data?.course_fee)}</p>
          </div>
        </div>
        <div className="flex w-full flex-col items-start gap-[10px] py-[10px]">
          <div className='flex items-center mb-[8px] gap-[8px]'>
            <EDUCheckbox
              checked={agreement}
              onChange={() => setAgreement(!agreement)}
            />
            <p className=" text-edu-lightGrayText">
              I have read and agree to the{' '}
              <span
                onClick={() => window.open(data?.tnc_url)}
                className="cursor-pointer text-edu-secondary underline"
              >
                Terms and Conditions.
              </span>
            </p>
          </div>
          <EDUButton fullWidth disabled={!agreement} variant="contained" onClick={handleSubmit}>
            Pay now
          </EDUButton>
          <EDUButton fullWidth variant="text" onClick={() => navigate(-1)}>
            Return to course
          </EDUButton>
        </div>
      </div>
    </div>
  )
}

export default EduMiniCart
