import { Close } from '@mui/icons-material'
import EDUProgressBar from 'components/ProgressBar/EDUProgressBar'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const RecapHeader = ({ state, setOpenModal, courseState, enrollment_id }) => {
  const navigate = useNavigate()
  const [value, setValue] = useState(0)
  let completed = []
  useEffect(() => {
    let length = state?.question?.length
    state?.question?.map((obj, index) => {
      if (obj?.attempt === true) {
        completed.push(index)
      }
      return null
    })
    setValue(completed?.length / length * 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const handleClose = () => {
    if (
      state?.question?.findIndex((obj) => obj?.attempt === true) === 0
    ) {
      setOpenModal(true)
    } else {
      navigate(`/my-learning/${enrollment_id}`, { state: courseState })
    }
  }
  return (
    <>
      <header className="sticky top-0 z-20 flex w-full flex-row items-center justify-between bg-[rgba(255,255,255,0.8)] px-[24px] py-[23px] backdrop-blur">
        <h4 className='text-h4'>Recap quiz: {state?.title}</h4>
        <Close sx={{ cursor: 'pointer' }} onClick={handleClose} />
      </header>
      <EDUProgressBar value={value} sx={{
        borderRadius: 0,
        backgroundColor: 'gray-line.main',
        height: '5px',
        '& .MuiLinearProgress-bar': {
          backgroundColor: 'secondary.main',
          borderRadius: 0
        },
      }} />
    </>
  )
}

export default RecapHeader