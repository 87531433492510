import { Box, Grid, IconButton, Skeleton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import EDUTabPanel from 'components/Tab/EDUTabPanel'
import EDUNoData from 'components/NoData/EDUNoData'
import EDUCourseCard from 'components/CourseCard/EDUCourseCard'
import React from 'react'
import { Favorite, Info } from '@mui/icons-material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getMyLearning } from 'services/api/myLearning'
import EDUButton from 'components/Button/EDUButton'
import { useNavigate } from 'react-router-dom'
import Bugsnag from '@bugsnag/js'
import { removeWishlist } from 'services/api/courseDetails'

const WishlistTab = ({ label }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const md = useMediaQuery(theme.breakpoints.up('md'))

  const { isLoading, data } = useQuery(
    ['getWishlistCourse'],
    async () => await getMyLearning('wishlist'),
    {
      onError: (err) => Bugsnag.notify(err),
    }
  )
  const handlePurchase = (courseId) => {
    navigate(`/payment-summary/${courseId}`)
  }

  const handleViewCourseDetails = (courseId) => {
    navigate(`/course/${courseId}`)
  }

  const navigateExploreCourses = () => {
    navigate(`/explore-courses`)
  }

  const queryClient = useQueryClient()
  const { mutate: mutateRemoveWishlist } = useMutation(removeWishlist, {
    onSuccess: () => {
      queryClient.invalidateQueries('getWishlistCourse')
    },
    onError: (err) => {
      Bugsnag.notify(err)
    }
  })

  const handleWishlist = (courseId) => {
    mutateRemoveWishlist(courseId)
  }

  return (
    <EDUTabPanel label={label}>
      <Box sx={{ paddingY: '20px', flexGrow: 1 }}>
        <Grid container rowSpacing={4} columnSpacing={8}>
          {!isLoading ? (
            data?.length !== 0 ? data.map((item, index) => {
              return (
                <Grid key={index} item xs={12} md={6} lg={4}>
                  <Box sx={{ width: '100%', marginBottom: 4, cursor: 'pointer' }}>
                    <EDUCourseCard
                      data={item}
                      handleNavigate={() => handleViewCourseDetails(item?.id)}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        columnGap: 1,
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      {item?.spots_remaining > 0 ? (
                        <EDUButton variant="contained" disabled={item?.isPurchased} sx={{ width: '85%' }} onClick={() => handlePurchase(item?.id)}>
                          {item?.isPurchased ? 'Purchased' : 'Buy Now'}
                        </EDUButton>
                      ) : (
                        <Box sx={{ width: '100%' }}>
                          <EDUButton variant="contained"
                            onClick={() => handleViewCourseDetails(item?.id)}
                            sx={{
                              width: '100%',
                              '&:hover': {
                                color: 'light-gray-text.main',
                                backgroundColor: 'gray-background.main'
                              },
                              '&.MuiButtonBase-root': {
                                color: 'light-gray-text.main',
                                backgroundColor: 'gray-background.main'
                              },
                            }}>
                            Fully Booked <Tooltip placement='bottom-start' title="By adding fully booked bootcamp in wishlist, you’ll be notified once there is an available slot"><Info sx={{ color: 'info.main', ml: 1 }} /></Tooltip>
                          </EDUButton>
                        </Box>
                      )}

                      <IconButton
                        onClick={() => handleWishlist(item?.id)}
                        sx={{
                          width: '15%',
                          minHeight: '45px',
                          px: '12.5px',
                          '&.MuiIconButton-root': {
                            borderRadius: '3.5px',
                          },
                        }}
                      >
                        <Favorite sx={{ color: 'error.main', fontSize: 25 }} />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              )
            }) : (
              <Grid item xs={12}>
                <EDUNoData title="You have no course added to wishlist yet" icon={<Favorite sx={{ fontSize: 40 }} />} sxIcon={{ width: 'auto', height: 'auto' }} action={<EDUButton variant='outlined' onClick={navigateExploreCourses} sx={{ mt: 3 }}>Explore courses</EDUButton>} />
              </Grid>
            )
          ) : (
            [1, 1, 1].map((_, index) => {
              return (
                <Grid key={index} item xs={12} md={6} lg={4}>
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      maxWidth: md ? '373px' : null,
                      backgroundColor: 'white.main',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    {/*Image and Play Button */}
                    <Skeleton sx={{ width: '100%', height: '200px' }}></Skeleton>
                    {/*Details and Copywriting */}
                    <Box
                      sx={{
                        width: '100%',
                        paddingY: '22px',
                      }}
                    >
                      <Box
                        sx={{
                          mb: '8px',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Skeleton>
                          <Typography
                            variant="subtitle1"
                            sx={{ textTransform: 'uppercase', color: 'information.main' }}
                          >
                            Course
                          </Typography>
                        </Skeleton>

                        <Skeleton
                          sx={{
                            paddingX: '10px',
                            paddingY: '2px',
                            borderRadius: '2.5px',
                          }}
                        >
                          <Typography variant="subtitle1" sx={{ color: 'white.main' }}>
                            Best Seller
                          </Typography>
                        </Skeleton>
                      </Box>
                      <Skeleton>
                        <Typography variant="h4" sx={{ textTransform: 'capitalize', mb: '6px' }}>
                          Software Engineering Conversion Bootcamp
                        </Typography>
                      </Skeleton>
                      <Skeleton>
                        <Typography
                          variant="body1"
                          sx={{ textTransform: 'capitalize', color: 'light-gray-text.main' }}
                        >
                          By Invoke Solutions Sdn. Bhd.
                        </Typography>
                      </Skeleton>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: md ? 'row' : 'column',
                          alignItems: md ? 'center' : 'flex-start',
                          justifyContent: 'space-between',
                          mt: '11.6px',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: md ? 'row' : 'column',
                            alignItems: md ? 'center' : 'flex-start',
                            columnGap: 1,
                          }}
                        >
                          <Skeleton>
                            <Typography variant="h3" sx={{ color: 'error.main' }}>
                              RM 99,999
                            </Typography>
                          </Skeleton>

                          <Skeleton>
                            <Typography
                              variant="body1"
                              sx={{ color: 'dark-text.main', textDecoration: 'line-through' }}
                            >
                              RM 99,999
                            </Typography>
                          </Skeleton>
                        </Box>

                        <Skeleton>
                          <Typography variant="body1" sx={{ color: 'light-gray-text.main' }}>
                            (999.9k sold)
                          </Typography>
                        </Skeleton>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              )
            })
          )}
        </Grid>
      </Box>
    </EDUTabPanel>
  )
}

export default WishlistTab
