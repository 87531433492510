import { Avatar } from '@mui/material'
import React from 'react'

const EDUAvatar = ({
  sx,
  children, // accepts string name. This is required if the image is broken
  ...rest // accepts any avatar props. src for image to be displayed
}) => {

  let nameArray = children?.toUpperCase()?.split(' ')
  nameArray?.splice(2)
  return (
    <Avatar sx={{ ...sx }} {...rest}>
      {children && `${nameArray?.length === 2 ? `${nameArray[0][0]}${nameArray[1][0]}` : `${nameArray[0][0]}`}`}
    </Avatar>
  )
}

export default EDUAvatar