import EDUTabPanel from "components/Tab/EDUTabPanel"
import LessonPlanAccordion from "./LessonPlanAccordion"

const MobileLessonTab = ({ label, courseDetails,
  courseState,
  setCourseSession,
  expanded,
  setExpanded }) => {
  return (
    <EDUTabPanel label={label}>
      <div className="py-[16px]">
        <LessonPlanAccordion
          courseDetails={courseDetails}
          courseState={courseState}
          setCourseSession={setCourseSession}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      </div>
    </EDUTabPanel>
  )
}

export default MobileLessonTab