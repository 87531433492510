import Bugsnag from '@bugsnag/js'
import { Circle, Info } from '@mui/icons-material'
import { useMutation } from '@tanstack/react-query'
import EDUButton from 'components/Button/EDUButton'
import EDURadio from 'components/Radio/EDURadio'
import { Form, Formik } from 'formik'
import React from 'react'
import { installmentPayment } from 'services/api/payment'
import dateConverter from 'services/helper/dateConverter'

const PaymentDrawer = ({ data, totalAmount, overdueStatus }) => {

  const { mutate, isLoading } = useMutation(installmentPayment, {
    onSuccess: ({ data }) => window.location.href = data,
    onError: (err) => Bugsnag.notify(err)
  })
  const handlePayment = (values) => {
    const { amount, ...rest } = values
    let data = {
      ...rest,
      amount: parseFloat(amount.replace(/,/g, ''))
    }
    mutate(data)
  }

  return (
    <div className="flex min-h-[calc(100vh-55px)] flex-col gap-[10px] bg-edu-defaultBackground">
      <Formik
        enableReinitialize
        initialValues={{
          nex_edu_payment_schedule_id: null,
          installment_number: null,
          amount: null,
        }}
        onSubmit={handlePayment}
      >
        {({ values, setValues, handleSubmit }) => {
          return (
            <Form className="flex min-h-[calc(100vh-55px)] flex-col justify-between">
              <div>
                {overdueStatus && (
                  <div className="flex items-center gap-[10px] bg-edu-lightError py-[12px] px-[30px]">
                    <Info sx={{ fontSize: 21, color: 'error.main' }} />
                    <p className="text-edu-error">
                      You have an overdue payment. Kindly clear your payment to reactivate your
                      access
                    </p>
                  </div>
                )}
                <div className="flex flex-col items-center gap-[5px] py-[24px] px-[30px]">
                  <h2 className="text-h2 text-edu-information">
                    RM{' '}
                    {totalAmount
                      ?.reduce((a, b) => a + b, 0)
                      ?.toFixed(2)
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </h2>
                  <p className="text-edu-lightGrayText">Amount to pay</p>
                </div>
                <div>
                  <div className="border-b border-edu-grayLine bg-edu-white py-[17px] pr-[30px] pl-[68px]">
                    <p className="text-subtitle">Select active installment</p>
                  </div>
                  {data?.map((course, index) => (
                    <div
                      key={index}
                      className={`flex items-start bg-edu-white py-[20px] px-[30px] ${index + 1 !== data?.length ? 'border-b border-edu-grayLine' : 'mb-[40px]'
                        }`}
                    >
                      <EDURadio
                        checked={values?.nex_edu_payment_schedule_id === course?.nex_edu_payment_schedule_id}
                        onChange={() => {
                          setValues({
                            nex_edu_payment_schedule_id: course?.nex_edu_payment_schedule_id,
                            installment_number: course?.installment_number,
                            amount: course?.amount,
                          })
                        }}
                        value={course?.course_id}
                      // disabled={course?.payment_status === 1}
                      />
                      <div>
                        <p
                        // className={`${course?.payment_status === 1 && 'text-edu-lightGrayText'}`}
                        >
                          {course?.course_title}
                        </p>
                        <div className="mb-[20px] flex items-center gap-[6px]">
                          <p className="text-body2 text-edu-lightGrayText">
                            Payment {course?.installment_number}/{course?.total_installment}
                          </p>
                          <span>
                            <Circle sx={{ fontSize: 5, color: 'light-gray-text.main' }} />
                          </span>
                          <p className="text-body2 text-edu-lightGrayText">
                            Due on {dateConverter(course?.due_date)}
                          </p>
                        </div>
                        <div className="flex items-center gap-[10px]">
                          <p
                            className={`text-subtitle ${course?.overdue_status === 1 && 'text-edu-error'
                              }`}
                          >
                            RM {course?.amount}
                          </p>
                          {
                            // course?.payment_status === 1 ? (
                            //   <div className="bg-edu-defaultBackground px-[6px] py-[2px]">
                            //     <p className="text-subtitle2 text-edu-lightGrayText">Paid</p>
                            //   </div>
                            // ) : (
                            course?.overdue_status === 1 ? (
                              <div className="bg-edu-lightError px-[6px] py-[2px]">
                                <p className="text-subtitle2 text-edu-error">Overdue</p>
                              </div>
                            ) : null
                            // )
                          }
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="bg-edu-white px-[30px]">
                <div className="my-[10px] flex items-center justify-between">
                  <p className="text-subtitle">Total amount</p>
                  <p className="text-subtitle">RM {values?.amount || '0.00'}</p>
                </div>
                <EDUButton
                  variant="contained"
                  loading={isLoading}
                  disabled={values?.amount === null || isLoading}
                  onClick={handleSubmit}
                  fullWidth
                  sx={{ mb: '20px' }}
                >
                  Make payment
                </EDUButton>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default PaymentDrawer
