import EDUTabPanel from 'components/Tab/EDUTabPanel'
import React from 'react'

const OverviewTab = ({ label, overview }) => {
  return (
    <EDUTabPanel label={label}>
      <div className="py-[22px]">
        <div>
          <p className="text-edu-lightGrayText whitespace-pre-line text-justify md:text-left">{overview}</p>
        </div>
      </div>
    </EDUTabPanel>
  )
}

export default OverviewTab
