import React, { useState } from 'react'
import { FormControl, RadioGroup } from '@mui/material'
import EDUFormControlLabel from 'components/FormLabel/EDUFormControlLabel'
import EDURadio from 'components/Radio/EDURadio'

const MultipleChoiceQuiz = ({ quest, index, ...props }) => {
  const { setAnswer, formikProps } = props

  const [radioValue, setRadioValue] = useState(null)

  const handleChangeRadio = (e) => {
    setAnswer(e.target.value)
    setRadioValue(parseInt(e.target.value))
  }
  return (
    <div key={index} className="mb-[10px] flex w-full flex-col bg-edu-white p-[30px]">
      <h3 className="text-subtitle text-edu-darkText">{index + 1}. {quest?.title}</h3>
      {quest?.answers?.map((opt, optIndex) => (
        <FormControl key={optIndex}>
          <RadioGroup
            name={formikProps?.validationSchema?.submission}
            value={radioValue}
            onChange={handleChangeRadio}
          >
            <EDUFormControlLabel value={opt?.id} control={<EDURadio />} label={opt?.answer} />
          </RadioGroup>
        </FormControl>
      ))}
    </div>
  )
}

export default MultipleChoiceQuiz
