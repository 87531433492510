import { ExpandMore } from '@mui/icons-material'
import { AccordionSummary, styled } from '@mui/material'
import React from 'react'

const EDUAccordionSummary = styled(({ ...rest }) => (
  <AccordionSummary expandIcon={<ExpandMore />} {...rest} />
))(({ theme }) => ({
  width: '100%',
  minHeight: '61px',
  backgroundColor: theme.palette['default-background'].main,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
}))

export default EDUAccordionSummary
