import { Radio } from '@mui/material'
import React from 'react'

const EDURadio = ({ sx, ...rest }) => {
  return (
    <Radio
      {...rest}
      sx={{
        '&.MuiRadio-root': {
          p: 0,
          mr: 2,
          minHeight: 0,
          color: 'light-gray-text.main',
          '&.Mui-disabled': {
            color: 'gray-line.main',
          },
        },
        '&.Mui-checked': {
          color: 'secondary.main',
        },
        ...sx,
      }}
    />
  )
}

export default EDURadio
