import React from 'react'
import CourseDesription from './CourseDescription'
import * as yup from 'yup'
import { Form, Formik } from 'formik'
import EDUStepper from 'components/ProgressStepper/EDUStepper'
import EDUButton from 'components/Button/EDUButton'
import { useNavigate } from 'react-router-dom'
import EDURadio from 'components/Radio/EDURadio'
import EDUCheckbox from 'components/Checkbox/EDUCheckbox'
import valueToStringConversion from 'services/helper/valueToStringConversion'
import { firstPayment } from 'services/api/payment'
import { useMutation } from '@tanstack/react-query'
import Bugsnag from '@bugsnag/js'
import dateConverter from 'services/helper/dateConverter'

const BootcampCart = ({ data }) => {
  const navigate = useNavigate()
  const validationSchema = yup.object({
    nex_edu_course_id: yup.number().required('Enrollment id is required'),
    amount: yup.number().required('Please select a payment method'),
    agree_status: yup.boolean().oneOf([true], 'The terms and conditions must be accepted.'),
  })
  const { mutate } = useMutation(firstPayment, {
    onSuccess: ({ data }) => window.location.href = data,
    onError: (err) => Bugsnag.notify(err)
  })

  const handleMakePayment = ({ amount, nex_edu_discount_id, ...rest }) => {
    let values = {
      amount:
        amount === '1'
          ? !data?.applicable_discounts?.find((item) => item?.name === 'Full Payment') ? data?.course_fee : data?.applicable_discounts?.find((item) => item?.name === 'Full Payment')?.type === 'percent' ? (100 - parseInt(data?.applicable_discounts?.find((item) => item?.name === 'Full Payment')?.value) / 100 * data?.course_fee) : parseInt(data?.course_fee - parseInt(data?.applicable_discounts?.find((item) => item?.name === 'Full Payment')?.value))
          : parseFloat(data?.installment_dates[0].amount.replace(/,/g, '')),
      nex_edu_discount_id: amount === '1' ? nex_edu_discount_id : null,
      ...rest,
    }
    mutate(values)
  }
  return (
    <div>
      <CourseDesription
        data={{ course_title: data?.course_title, trainer: data?.trainer_name, course_type: 'BOOTCAMP', course_logo: data?.course_logo }}
      />
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{
          nex_edu_course_id: data?.course_id,
          is_installment: 0,
          amount: '1',
          agree_status: false, // to confirm if still needed
          nex_edu_discount_id: data?.applicable_discounts?.find((item) => item?.name === 'Full Payment')?.id
        }}
        onSubmit={handleMakePayment}
      >
        {({ values, handleChange, setFieldValue, handleSubmit }) => {
          return (
            <Form>
              <div className="flex min-h-[calc(100vh-192px)] flex-col items-start justify-between md:min-h-[calc(100vh-202px)]">
                <div className="w-full">
                  <div className="my-[30px] flex flex-col gap-[10px]">
                    <h4 className="text-h4">Select payment plan</h4>
                    <label htmlFor="one-off" className="cursor-pointer">
                      <div
                        onClick={() => {
                          setFieldValue('amount', '1')
                          setFieldValue('is_installment', 0)
                        }}
                        className={`flex flex-col items-start justify-start gap-[4px] rounded-lg border border-edu-grayLine bg-edu-white px-[14px] py-[20px] md:gap-0 ${values?.amount === '1' && 'border-edu-secondary bg-edu-lightInformation'
                          }`}
                      >
                        <div className="flex items-center">
                          <EDURadio
                            checked={values?.amount === '1'}
                          />
                          <p>Full payment (RM {valueToStringConversion(data?.course_fee)})</p>
                          {Boolean(data?.lump_sum_discount) && (
                            <div className="ml-[10px] hidden px-[6px] py-[2px] md:block">
                              <p className="text-subtitle2 text-edu-success">
                                -RM {valueToStringConversion(data?.lump_sum_discount)} rebate
                              </p>
                            </div>
                          )}
                        </div>
                        {Boolean(data?.lump_sum_discount) && (
                          <div className="ml-[37px] block px-[6px] py-[2px] md:hidden">
                            <p className="text-subtitle2 text-edu-success">
                              -RM {valueToStringConversion(data?.lump_sum_discount)} rebate
                            </p>
                          </div>
                        )}
                      </div>
                    </label>
                    <label htmlFor="installment" className="cursor-pointer">
                      <div
                        onClick={() => {
                          setFieldValue('is_installment', 1)
                          setFieldValue('amount', '2')
                        }}
                        className={`flex flex-col items-start justify-start gap-[4px] rounded-lg border border-edu-grayLine bg-edu-white px-[14px] py-[20px] ${values?.amount === '2' && 'border-edu-secondary bg-edu-lightInformation'
                          }`}
                      >
                        <div className="flex items-center">
                          <EDURadio
                            checked={values?.amount === '2'}
                          />
                          <p>{data?.installment_dates?.length} months installment, 0% interest</p>
                        </div>
                        <p className="ml-[37px] text-body2 text-edu-lightGrayText">
                          An upfront payment of the 1st month installment will be charged to confirm
                          your order
                        </p>
                      </div>
                    </label>
                  </div>
                  {values?.amount === '2' && (
                    <div className="mb-[30px]">
                      <h4 className="mb-[10px] text-h4">Installment schedule</h4>
                      {data?.installment_dates?.map((installment, index) => {
                        return (
                          <EDUStepper key={index} index={index}>
                            <div className="flex w-full items-center justify-between">
                              <div className="flex flex-col justify-around">
                                {/* to check with backend calendar format */}
                                <p className="text-subtitle">{dateConverter(installment.due_date)}</p>
                                <p className="text-body2 text-edu-lightGrayText">
                                  {index + 1}/{data?.installment_dates?.length} payment
                                </p>
                              </div>
                              <p className="text-subtitle">RM {valueToStringConversion(parseInt(installment.amount))}</p>
                            </div>
                          </EDUStepper>
                        )
                      })}
                    </div>
                  )}
                  <div className="mb-[30px] flex flex-col gap-[10px]">
                    <h4 className="text-h4">Summary</h4>
                    {values?.amount === '1' ? (
                      <>
                        <div className="flex items-center justify-between">
                          <p className="capitalize text-edu-lightGrayText">{data?.course_title}</p>
                          <p className="text-right text-edu-lightGrayText">RM {valueToStringConversion(data?.course_fee)}</p>
                        </div>
                        {data?.lump_sum_discount && (
                          <div className="flex items-center justify-between">
                            <p className="text-edu-lightGrayText">Full payment rebate</p>
                            <p className="text-right text-edu-success">
                              -RM {valueToStringConversion(data?.lump_sum_discount)}
                            </p>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="flex items-center justify-between">
                        <p className="text-edu-lightGrayText">
                          Upfront payment (1/{data?.installment_dates?.length})
                        </p>
                        <p className="text-right text-edu-lightGrayText">
                          RM {data?.installment_dates[0]?.amount}
                        </p>
                      </div>
                    )}
                    <div className="h-[1px] w-full bg-edu-grayLine" />
                    <div className="flex items-center justify-between">
                      <p className="text-edu-lightGrayText">Subtotal</p>
                      <p className="text-right text-edu-lightGrayText">
                        RM{' '}
                        {values?.amount === '1'
                          ? data?.lump_sum_discount
                            ? valueToStringConversion(data?.course_fee - data?.lump_sum_discount)
                            : valueToStringConversion(data?.course_fee)
                          : data?.installment_dates[0]?.amount}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-subtitle">Total amount</p>
                      <p className="text-right text-subtitle">
                        RM{' '}
                        {values?.amount === '1'
                          ? data?.lump_sum_discount
                            ? valueToStringConversion(data?.course_fee - data?.lump_sum_discount)
                            : valueToStringConversion(data?.course_fee)
                          : data?.installment_dates[0]?.amount}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex w-full flex-col items-start gap-[10px] py-[10px]">
                  <div className='flex items-center mb-[8px] gap-[8px]'>
                    <EDUCheckbox
                      name='agree_status'
                      value={values?.agree_status}
                      checked={values?.agree_status}
                      onChange={handleChange}
                    />
                    <p className=" text-edu-lightGrayText">
                      I have read and agree to the{' '}
                      <span
                        onClick={() => window.open(data?.tnc_url)}
                        className="cursor-pointer text-edu-secondary underline"
                      >
                        Terms and Conditions.
                      </span>
                    </p>
                  </div>
                  <EDUButton fullWidth disabled={!values?.agree_status} variant="contained" onClick={handleSubmit}>
                    Pay now
                  </EDUButton>
                  <EDUButton fullWidth variant="text" onClick={() => navigate(-1)}>
                    Return to course
                  </EDUButton>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default BootcampCart
