import { Done, Info } from '@mui/icons-material'
import { Skeleton } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import EDUStepper from 'components/ProgressStepper/EDUStepper'
import React from 'react'
import { detailPayment } from 'services/api/payment'
import dateConverter from 'services/helper/dateConverter'
import valueToStringConversion from 'services/helper/valueToStringConversion'

const PurchaseDetailsDrawer = ({ enrollment_id }) => {

  const { data, isLoading } = useQuery(['detailPaymentSummary', enrollment_id], async () => await detailPayment(enrollment_id))

  const overdueStatus = data?.installment_schedule?.find((obj) => obj?.overdue_status === 1)

  let active = data?.installment_schedule?.findIndex((status) => status.payment_status === 0) //index of active installment
  if (active === -1) {
    active = data?.installment_info?.length + 1
  }

  if (isLoading) {
    return (
      <div className='bg-edu-defaultBackground min-h-[calc(100vh-55px)]'>
        <div className='py-[20px] px-[30px] bg-edu-white w-full flex flex-col gap-[20px]'>
          <div className='flex flex-col gap-[2px]'>
            <Skeleton width='30%' />
            <Skeleton width='50%' />
          </div>
          <div className='flex flex-col gap-[5px]'>
            <div className='flex items-center gap-[20px]'>
              <Skeleton width='40%' />
              <Skeleton width='50%' />
            </div>
            <div className='flex items-center gap-[20px]'>
              <Skeleton width='40%' />
              <Skeleton width='50%' />
            </div>
            <div className='flex items-center gap-[20px]'>
              <Skeleton width='40%' />
              <Skeleton width='50%' />
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className='bg-edu-defaultBackground min-h-[calc(100vh-55px)] flex flex-col gap-[10px]'>
      <div>
        {
          overdueStatus && <div className='py-[12px] px-[30px] bg-edu-lightError flex items-center gap-[10px]'>
            <Info sx={{ fontSize: 21, color: 'error.main' }} />
            <p className='text-edu-error'>You have an overdue payment. Kindly clear your payment to reactivate your access</p>
          </div>
        }
        <div className='py-[20px] px-[30px] bg-edu-white'>
          <p className='text-body2 mb-[2px] text-edu-information uppercase'>{data?.course_type}</p>
          <p className='text-subtitle mb-[10px]'>{data?.course_title}</p>
          <div className='my-[20px] flex flex-col gap-[10px]'>
            <div className='flex items-center w-full'>
              <p className='text-edu-lightGrayText w-[160px] mr-[20px]'>Total amount</p>
              <p className='w-full'>RM {data?.discount ? valueToStringConversion(data?.course_fee - parseInt(data?.discount)) : valueToStringConversion(data?.course_fee)}</p>
            </div>
            <div className='flex items-center w-full'>
              <p className='text-edu-lightGrayText w-[160px] mr-[20px]'>Purchase date</p>
              <p className='w-full'>{dateConverter(data?.purchase_date)}</p>
            </div>
            {
              data?.course_type === 'bootcamp' && <div className='flex items-center w-full'>
                <p className='text-edu-lightGrayText w-[160px] mr-[20px]'>Payment type</p>
                <p className='w-full'>{data?.payment_type === "Installment" ? 'Installment' : 'One-off'}</p>
              </div>
            }
          </div>
        </div>
      </div>
      {
        (data?.installment_schedule !== null || data?.installment_schedule?.length < 1) && <div className='bg-edu-white'>
          <div className='py-[12px] px-[30px] border-b border-edu-grayLine'>
            <p className='text-subtitle'>Installment schedule</p>
          </div>
          <div className='py-[20px] px-[30px]'>
            {
              data?.installment_schedule?.map((plan, index) => {
                return (
                  <EDUStepper key={index} active={active} index={index}>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex flex-col justify-around">
                        <div className={`flex flex-row ${plan?.overdue_status === 1 && 'gap-[6px]'}`}>
                          <p className={`text-subtitle ${index < active && 'text-edu-lightGrayText'}`}>{dateConverter(plan.due_date)}</p>
                          {
                            plan?.overdue_status === 1 ? <div className='py-[2px] px-[6px] bg-edu-lightError'>
                              <p className='text-subtitle2 text-edu-error'>Overdue</p>
                            </div> : null
                          }
                        </div>
                        <p className='text-body2 text-edu-lightGrayText'>
                          {index + 1}/{data?.installment_schedule?.length} payment
                        </p>
                      </div>
                      <p className={`text-subtitle ${index < active && 'text-edu-lightGrayText'}`}>RM {valueToStringConversion(parseInt(plan.amount))}</p>
                    </div>
                  </EDUStepper>
                )
              })
            }
          </div>
        </div>
      }
      {
        data?.transaction_history?.length > 0 ? <div className='bg-edu-white mb-[40px]'>
          <div className='py-[12px] px-[30px] border-b border-edu-grayLine'>
            <p className='text-subtitle'>Transaction history</p>
          </div>
          {
            data?.transaction_history?.map((history, index) => (
              <div key={index} className='py-[20px] px-[30px] flex flex-col gap-[4px]'>
                <div className='flex justify-between items-center'>
                  {
                    data?.course_type === 'course' ? <p className='text-subtitle'>Course purchase</p> : (data?.installment_schedule === null || data?.installment_schedule?.length < 1) ? <p className='text-subtitle'>Full payment</p> : <p className='text-subtitle'>{history?.installment_number}/{data?.installment_schedule?.length} Payment</p>
                  }
                  <p className='text-subtitle'>RM {valueToStringConversion(parseInt(history?.amount))}</p>
                </div>
                <div className='flex justify-between items-center'>
                  <p className='text-body2 text-edu-lightGrayText'>{dateConverter(history?.payment_date)}</p>
                  {
                    history?.status === 'Success' ? <div className='flex items-center gap-[6px]'>
                      <Done sx={{ fontSize: 12, color: 'white.main', borderRadius: 50, bgcolor: 'success.main' }} />
                      <p className='text-body2 text-edu-lightGrayText'>Success</p>
                    </div> : <p className='text-edu-error text-body2'>Failed</p>
                  }
                </div>
                <p className='text-body2 text-edu-lightGrayText'>{history?.payment_method}</p>
              </div>
            ))
          }
        </div> : null
      }
    </div>
  )
}

export default PurchaseDetailsDrawer