const timeConversion = (secs, video = false) => {
  let minutes
  let seconds
  let hours
  switch (video) {
    case true:
      minutes = Math.floor((secs % 3600) / 60).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
      })
      hours = Math.floor(secs / 3600).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
      })
      seconds = parseInt(secs % 60).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
      })
      break

    default:
      minutes = Math.round((secs % 3600) / 60).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
      })
      hours = Math.round(secs / 3600).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
      })
      seconds = parseInt(secs % 60).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
      })
      break
  }
  return {
    hours,
    minutes,
    seconds,
  }
}
export default timeConversion
