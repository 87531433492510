import {
  Check,
  FastForward,
  FastRewind,
  Fullscreen,
  FullscreenExit,
  Pause,
  PlayArrow,
  Replay,
  SlowMotionVideo,
  VolumeOff,
  VolumeUp,
} from '@mui/icons-material'
import { Box, IconButton, MenuItem, Slider, Typography } from '@mui/material'
import timeConversion from 'services/helper/timeConversion'
import IconTooltipButton from './IconTooltipButton'
import EDUButton from 'components/Button/EDUButton'
import { useState } from 'react'
import EDUPopper from 'components/Popper/EDUPopper'
import { useRef } from 'react'

const MobileController = ({
  onReplay,
  onPlayPause,
  onMute,
  onRewind,
  onFastForward,
  onSlideProgress,
  onToggleFullScreen,
  onPlaybackChange,
  videoState,
  courseState,
  handleNext,
}) => {
  let total_duration = timeConversion(videoState?.duration, true)
  let progress_play = timeConversion(videoState?.progressSeconds, true)
  const { hours: totalHours, minutes: totalMinutes, seconds: totalSeconds } = total_duration
  const { hours: playedHours, minutes: playedMinutes, seconds: playedSeconds } = progress_play
  const defaultStyle = () => {
    return {
      color: 'white.main',
      cursor: 'pointer',
    }
  }
  const playbackRef = useRef(null)
  const [open, setOpen] = useState(false)
  // const [caption, setCaption] = useState(false)
  // TO FIX THE POPPER
  const openSetting = () => {
    setOpen((prev) => !prev)
  }

  const handleClose = (event) => {
    if (playbackRef.current && playbackRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handlePlaybackRate = (newValue) => (e) => {
    onPlaybackChange(newValue === 'Normal' ? 1 : newValue)
    setOpen(false)
  }
  return (
    <div className="flex h-full w-full flex-col items-start justify-between bg-[#3A3A3A78] p-[5px]">
      <div className="flex w-full flex-row-reverse items-center justify-between p-2">
        <IconButton
          ref={playbackRef}
          sx={{
            minHeight: 0,
            padding: 0,
          }}
          onClick={openSetting}
        >
          <SlowMotionVideo sx={{ ...defaultStyle() }} />
        </IconButton>
        <EDUPopper
          open={open}
          handleClose={handleClose}
          anchorEl={playbackRef.current}
          placement="bottom-end"
          sx={{
            backgroundColor: 'dark-text.main',
            minWidth: '100px',
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              py: 1,
              px: 2,
              borderColor: 'light-gray-text.main',
            }}
          >
            <Typography color="light-gray-text.main">Playback speed</Typography>
          </Box>
          {[0.5, 1, 1.5, 2].map((item, index) => (
            <MenuItem
              key={index}
              onClick={handlePlaybackRate(item)}
              sx={{
                color: 'light-gray-text.main',
                textAlign: 'center',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: 2,
                py: 1,
              }}
            >
              {item}
              {videoState?.playbackRate === item && (
                <Check color="light-gray-text" sx={{ fontSize: 20 }} />
              )}
            </MenuItem>
          ))}
        </EDUPopper>
        <IconButton
          onClick={onMute}
          sx={{
            minHeight: 0,
            padding: 0,
          }}
        >
          {videoState?.volume === 0 || videoState?.isMuted ? (
            <VolumeOff sx={{ ...defaultStyle() }} />
          ) : (
            <VolumeUp sx={{ ...defaultStyle() }} />
          )}
        </IconButton>
      </div>
      <div
        className={`${videoState?.endVideo ? 'hidden' : 'flex'
          } absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 items-center space-x-5`}
      >
        <IconTooltipButton onClick={onRewind}>
          <FastRewind sx={{ fontSize: 30, ...defaultStyle() }} />
        </IconTooltipButton>
        <IconTooltipButton onClick={onPlayPause}>
          {videoState?.isPlaying === true ? (
            <Pause sx={{ fontSize: 50, ...defaultStyle() }} />
          ) : (
            <PlayArrow sx={{ fontSize: 50, ...defaultStyle() }} />
          )}
        </IconTooltipButton>
        <IconTooltipButton onClick={onFastForward}>
          <FastForward sx={{ fontSize: 30, ...defaultStyle() }} />
        </IconTooltipButton>
      </div>
      <div
        className={`${!videoState?.endVideo && 'hidden'
          } z-2 absolute bottom-0 left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-2/3 flex-col items-center justify-center md:-translate-y-1/2`}
      >
        <p className="mb-2 text-subtitle2 text-edu-white">UP NEXT</p>
        <h3 className="text-center text-subtitle capitalize text-edu-white">
          Recap quiz: {courseState?.next?.title}
        </h3>
        <EDUButton
          onClick={handleNext}
          variant="contained"
          sx={{
            minHeight: '35px',
            fontSize: 14,
            width: '250px',
            mt: '8px',
            '&.MuiButtonBase-root': {
              background: 'white',
              color: 'black',
            },
          }}
        >
          Continue
        </EDUButton>
      </div>
      <div className="flex w-full flex-col space-y-1 px-2 ">
        <div className="flw-row flex items-center justify-between">
          <div className="flex items-center justify-end space-x-1">
            {videoState?.endVideo && (
              <Replay onClick={onReplay} sx={{ fontSize: 16, mr: 1, ...defaultStyle() }} />
            )}
            <Typography variant="body2" sx={{ color: 'white.main' }}>
              {playedHours === '00'
                ? `${playedMinutes}:${playedSeconds}`
                : `${playedHours}:${playedMinutes}:${playedSeconds}`}
            </Typography>
            <Typography variant="body2" sx={{ color: 'white.main' }}>{` / `}</Typography>
            <Typography variant="body2" sx={{ color: 'white.main' }}>
              {totalHours === '00'
                ? `${totalMinutes}:${totalSeconds}`
                : `${totalHours}:${totalMinutes}:${totalSeconds}`}
            </Typography>
          </div>
          <IconButton
            sx={{
              minHeight: 0,
              padding: 0,
            }}
            onClick={onToggleFullScreen}
          >
            {videoState?.isFullScreen ? (
              <FullscreenExit sx={{ ...defaultStyle() }} />
            ) : (
              <Fullscreen sx={{ ...defaultStyle() }} />
            )}
          </IconButton>
        </div>
        <Slider
          value={
            !videoState || videoState?.progressSeconds === 0 || videoState?.duration === 0
              ? 0
              : (videoState?.progressSeconds * 100) / videoState?.duration
          }
          onChange={onSlideProgress}
          sx={{
            height: 5,
            color: 'gray-line.main',
            '& .MuiSlider-thumb': {
              display: 'none',
            },
            '&.MuiSlider-root': {
              py: '5px',
            },
            '& .MuiSlider-track': {
              height: 4,
              color: 'yellow-01.main',
            },
          }}
        // valueLabelDisplay='auto'
        />
      </div>
    </div>
  )
}

export default MobileController
