import { AddCard } from '@mui/icons-material'
import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import EDUDrawer from 'components/Drawer/EDUDrawer'
import EDUNoData from 'components/NoData/EDUNoData'
import EDUTabPanel from 'components/Tab/EDUTabPanel'
import React from 'react'
import { useState } from 'react'
import PurchaseDetailsDrawer from './PurchaseDetailsDrawer'
import dateConverter from 'services/helper/dateConverter'
import valueToStringConversion from 'services/helper/valueToStringConversion'
import { useQuery } from '@tanstack/react-query'
import Bugsnag from '@bugsnag/js'
import { paymentDasboard } from 'services/api/payment'

const CompletedPaymentTab = ({ label }) => {
  const [drawerState, setDrawerState] = useState({ right: false })
  const [enrollmentId, setEnrollmentId] = useState()

  const handleDrawer = (anchor, state) => {
    setDrawerState({ [anchor]: state })
  }

  const { data, isLoading } = useQuery(['completedPayment'], async () => {
    return await paymentDasboard({ type: 'completed' })
  }, {
    onError: (err) => Bugsnag.notify(err)
  })
  return (
    <>
      <EDUTabPanel label={label}>
        <div className="py-[20px]">
          {isLoading ? (
            <div className="w-full">
              <Skeleton variant="rectangular" width="100%" height="40px" />
              {[1, 1, 1].map((_, index) => (
                <div
                  key={index}
                  className="flex w-full items-center justify-between border-b border-edu-grayLine p-[16px]"
                >
                  <Skeleton width="40%" />
                  <div className="flex w-full items-center justify-end gap-[10px]">
                    <Skeleton width="15%" />
                    <Skeleton width="15%" />
                    <Skeleton width="10%" />
                  </div>
                </div>
              ))}
            </div>
          ) : (data?.data?.length < 1 || data === null || data === undefined || data?.length === 0) ? (
            <EDUNoData
              title="No completion"
              description="You have not completed payment for any course yet."
              icon={<AddCard sx={{ fontSize: 40 }} />}
            />
          ) : (
            <div>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead
                  sx={{
                    backgroundColor: 'default-background.main',
                    '& .MuiTableCell-root': {
                      border: 0,
                    },
                  }}
                >
                  <TableRow sx={{ px: '16px', py: '12px' }}>
                    <TableCell width="60%" align="left">
                      <p className="text-body text-edu-lightGrayText">Course</p>
                    </TableCell>
                    <TableCell width="15%">
                      <p className="text-body text-edu-lightGrayText">Date</p>
                    </TableCell>
                    <TableCell width="20%" align="left">
                      <p className="text-body text-edu-lightGrayText">Amount</p>
                    </TableCell>
                    <TableCell width="5%" align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.data?.map((item, index) => (
                    <TableRow key={index} sx={{ cursor: 'default' }}>
                      <TableCell sx={{ fontSize: 14 }} width="60%">{item?.course_title}</TableCell>
                      <TableCell sx={{ fontSize: 14 }} width="15%" align="left">
                        {dateConverter(item?.purchased_date)}
                      </TableCell>
                      <TableCell sx={{ fontSize: 14 }} width="20%" align="left">
                        RM {valueToStringConversion(item?.amount_paid)}
                      </TableCell>
                      <TableCell sx={{ fontSize: 14 }} width="5%" align="right">
                        <p
                          onClick={() => {
                            handleDrawer('right', true)
                            setEnrollmentId(item?.enrolment_id)
                          }}
                          className="cursor-pointer text-subtitle text-edu-primary underline"
                        >
                          View
                        </p>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
      </EDUTabPanel>
      <EDUDrawer
        drawerState={drawerState}
        handleDrawer={handleDrawer}
        anchor={Object.keys(drawerState)[0]}
        header={<h4 className="text-h4">Purchase details</h4>}
        divider
      >
        <PurchaseDetailsDrawer enrollment_id={enrollmentId} />
      </EDUDrawer>
    </>
  )
}

export default CompletedPaymentTab
