import { Box } from '@mui/material'
import React from 'react'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'

const LandingPageCarousel = ({ children, arrowIndicator = true }) => {
  const handleLeft = () => {
    var slider = document.getElementById('display-area')
    slider.scrollLeft = slider.scrollLeft - 500
  }
  const handleRight = () => {
    var slider = document.getElementById('display-area')
    slider.scrollLeft = slider.scrollLeft + 500
  }
  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
      }}
    >
      {arrowIndicator && children.length > 2 && (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            zIndex: 2,
            columnGap: 1,
            right: 0,
            top: -60,
          }}
        >
          <Box className="classes-LeftArrow" onMouseDown={handleLeft}>
            <Box
              sx={{
                width: '45px',
                height: '45px',
                border: '1px solid',
                borderColor: 'gray-line.main',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4.5px',
                cursor: 'pointer',
              }}
            >
              <ChevronLeft fontSize="large" />
            </Box>
          </Box>
          <Box className="classes-RightArrow" onMouseDown={handleRight}>
            <Box
              sx={{
                width: '45px',
                height: '45px',
                border: '1px solid',
                borderColor: 'gray-line.main',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4.5px',
                cursor: 'pointer',
              }}
            >
              <ChevronRight fontSize="large" />
            </Box>
          </Box>
        </Box>
      )}

      <Box
        id="display-area"
        sx={{
          width: '100%',

          whiteSpace: 'nowrap',
          overflowX: 'scroll',
          scrollBehavior: 'smooth',

          '&::-webkit-scrollbar': { display: 'none', height: 0 },
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default LandingPageCarousel
