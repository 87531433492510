import { Code, Dashboard } from '@mui/icons-material'
import { Box, Tooltip, useTheme } from '@mui/material'
import ExploreIcon from 'assets/icons/ExploreIcon'
import PaymentIcon from 'assets/icons/PaymentIcon'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
// import { _navBar } from '_navBar'
import eduIcon from '../assets/logos/edu-icon.png'
import { ReactComponent as MyLearningIcon } from 'assets/icons/myLearningIcon.svg'

const SideMenu = ({ isLogin }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const { pathname } = location
  let pathArray = pathname.split('/')
  pathArray.shift()

  const _navBar = [
    {
      title: 'Dashboard',
      baseRoute: 'dashboard',
      slug: '/',
      icon: <Dashboard sx={{ fontSize: 24 }} />,
    },
    {
      title: 'My learning',
      baseRoute: 'my learning',
      slug: '/my-learning',
      icon: <MyLearningIcon className={`${pathArray[0] === 'my-learning' ? 'fill-edu-primary' : 'fill-edu-lightGrayText'}`} />,
    },
    {
      title: 'Payment',
      baseRoute: 'payment',
      slug: '/payment-dashboard',
      icon: <PaymentIcon color={pathArray[0] === 'payment-dashboard' ? theme.palette.primary.main : theme.palette['light-gray-text'].main} />,
    },
    {
      title: 'Components',
      baseRoute: 'components',
      slug: '/components',
      icon: <Code sx={{ fontSize: 24 }} />,
    },
  ]

  const IconMenu = ({ title, icon, slug }) => {
    const handleNavigation = () => {
      navigate(slug)
    }
    return (
      <Tooltip title={title} placement="right">
        <Box display="flex" width="75px" boxSizing="border-box" onClick={() => handleNavigation()}>
          <Box
            width="3px"
            height="auto"
            sx={{ backgroundColor: slug === `/${pathArray[0]}` ? 'primary.main' : 'white.main' }}
          />
          <Box
            sx={{
              cursor: 'pointer',
              padding: '15px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              color: slug === `/${pathArray[0]}` ? 'primary.main' : 'light-gray-text.main',
            }}
          >
            {icon}
          </Box>
        </Box>
      </Tooltip>
    )
  }
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100vh',
        borderRight: '1px solid',
        borderColor: 'gray-line.main',
      }}
    >
      <div>
        <div
          className="box-border flex h-[69.5px] w-full cursor-pointer flex-row items-center justify-center py-3.5 px-[15px]"
          onClick={() => navigate('/')}
        >
          <img alt="adnexio EDU" src={eduIcon} className="h-[22px]" />
        </div>
        {_navBar
          ?.filter((item) => {
            if (process.env.NODE_ENV !== 'production') {
              if (isLogin) {
                return item
              } else {
                if (item.title === 'Dashboard') {
                  return item
                } else {
                  return null
                }
              }
            } else {
              if (item.title !== 'Components') {
                if (isLogin) {
                  return item
                } else {
                  if (item.title === 'Dashboard') {
                    return item
                  } else {
                    return null
                  }
                }
              } else {
                return null
              }
            }
          })
          ?.map((item, index) => (
            <div key={index} className='mb-[10px]'>
              <IconMenu
                title={item?.title}
                icon={item.icon}
                slug={item.slug}
                baseRoute={item.baseRoute}
              />
            </div>
          ))}
      </div>
      <div className='absolute bottom-[64px]'>
        <IconMenu
          title={'Explore courses'}
          icon={<ExploreIcon color={pathArray[0] === 'explore-courses' ? theme.palette.primary.main : theme.palette['light-gray-text'].main} />}
          slug={'/explore-courses'}
          baseRoute={'explore courses'}
        />
      </div>
    </Box>
  )
}

export default SideMenu
