import EDUButton from 'components/Button/EDUButton'
import EDUTextField from 'components/TextField/EDUTextField'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom/dist'
import eduIcon from 'assets/logos/adnexio-edu-logo.svg'
import alertIcon from '../assets/alert-icon.svg'
import * as yup from 'yup'
import Bugsnag from '@bugsnag/js'
import SignUpContainer from '../components/SignUpContainer'
import EDUModal from 'components/Modal/EDUModal'
import { useMutation } from '@tanstack/react-query'
import { checkEmail } from 'services/api/registration'
import { useRef } from 'react'

const GetStarted = () => {
  const [open, setOpen] = useState(false)
  const formikRef = useRef()
  const handleClose = (event, reason) => {
    if ((reason === 'clickaway') | (reason === 'backdropClick') | (reason === 'escapeKeyDown')) {
      return
    }
    setOpen(false)
  }

  const validationSchema = yup.object({
    email: yup.string().email('Must be a valid email').required('Email is required'),
  })
  const navigate = useNavigate()
  const navigateSignin = () => {
    navigate('/signin')
  }
  const navigateTnc = () => {
    navigate('/terms-conditions')
  }
  const navigatePrivacyPolicy = () => {
    navigate('/privacy-policy')
  }

  const { mutate, isLoading } = useMutation(async (val) => await checkEmail(val), {
    onSuccess: ({ data }) => {
      if (Boolean(!data?.exists)) {
        navigate('/create-account', {
          state: data?.email
        })
      } else {
        setOpen(true)
      }
    },
    onError: (err) => {
      if (err?.status === 422) {
        formikRef.current.setFieldError('email', err?.data?.errors?.email[0])
      } else {
        Bugsnag.notify(err)
      }
    }
  })
  const checkUserAccount = (values) => {
    mutate(values)
  }

  return (
    <SignUpContainer>
      <Formik
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={checkUserAccount}
        initialValues={{
          email: '',
        }}
        enableReinitialize
      >
        {
          ({ values, handleSubmit, errors, touched, handleChange }) => {
            return (
              <Form className='max-w-[500px] min-h-full flex flex-col justify-start'>
                <div className='w-full'>
                  <div
                    className="box-border flex md:hidden mb-[30px] h-[69.5px] w-full cursor-pointer flex-row items-center justify-center "
                    onClick={() => navigate('/')}
                  >
                    <img alt="adnexio EDU" src={eduIcon} />
                  </div>
                  <h2 className='text-h2 mb-[10px] text-left'>Get started</h2>
                  <p className='text-edu-lightGrayText text-justify md:text-left'>Ready to level up your skills? Sign up now and access personalized courses tailored to your needs!</p>
                  <div className='my-[30px]'>
                    <EDUTextField
                      name='email'
                      label='Email'
                      value={values?.email}
                      onChange={handleChange}
                      isErrorShown={touched?.email && errors?.email}
                      errorMessage={touched?.email && errors?.email}
                    />
                  </div>
                </div>
                <div className='w-full'>
                  <EDUButton loading={isLoading} disabled={isLoading} onClick={handleSubmit} variant='contained' fullWidth sx={{ mb: '10px' }}>
                    Continue
                  </EDUButton>
                  <p className='text-center'>By continuing, you agree to our <span className='text-edu-secondary underline cursor-pointer' onClick={navigateTnc}>Terms of Use</span> and <span className='text-edu-secondary underline cursor-pointer' onClick={navigatePrivacyPolicy}>Privacy policy</span></p>
                  <div className='mt-[60px] flex flex-row justify-center border-t border-edu-grayLine items-center px-[16px] py-[11px]'>
                    <p className='text-center'>Already have an account? <span className='text-edu-secondary underline cursor-pointer' onClick={navigateSignin}>Sign in</span></p>
                  </div>
                </div>
              </Form>
            )
          }
        }
      </Formik>
      <EDUModal
        open={open}
        onClose={handleClose}
        modalWidth={570}
      >
        <div className='text-center flex flex-col items-center'>
          <img alt='' src={alertIcon} className='h-[50px] w-[50px]' />
          <div className='my-5'>
            <h2 className='text-h2'>Account has already existed</h2>
            <p className='text-edu-lightGrayText my-[6px]'>
              Looks like you already have an adnexio Jobseeker account linked to this email. You can either sign in using this account or create a new one with a different email.
            </p>
          </div>
          <div className='w-full flex flex-col gap-2 '>
            <EDUButton onClick={() => navigate('/signin')} variant='contained' fullWidth>
              Sign in
            </EDUButton>
            <EDUButton onClick={handleClose} variant='outlined' fullWidth>
              Sign up with another email
            </EDUButton>
          </div>
        </div>
      </EDUModal>
      {/* ---------------TEMPORARY----------------- */}
      {/* <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">Login Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <EDUButton variant='contained' onClick={handleClose}>OK</EDUButton>
          <EDUButton variant='outlined' onClick={navigateContactUs}>Contact Us</EDUButton>
        </DialogActions>
      </Dialog> */}
      {/* ---------------TEMPORARY----------------- */}
    </SignUpContainer>
  )
}

export default GetStarted