import { Accordion, styled } from '@mui/material'
import React from 'react'

const EDUAccordion = styled(({ ...rest }) => (
  <Accordion disableGutters square elevation={0} {...rest} />
))(() => ({
  width: '100%',
  '&:before': {
    display: 'none',
  },
  '&:not(:last-child)': {
    marginBottom: '4px',
  },
}))

export default EDUAccordion
