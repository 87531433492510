import { Box } from '@mui/material'
import React from 'react'
import confetti from '../assets/Confetti.jpg'
import balloon from '../assets/Balloon.png'
import { Cancel } from '@mui/icons-material'
import EDUFormAnswerLabel from 'components/FormLabel/EDUFormAnswerLabel'

const QuizAnswer = ({ results, summary }) => {
  return (
    <div className=" mx-auto  max-w-3xl bg-edu-white ">
      {summary?.score >= summary?.passing_mark ? (
        <Box
          sx={{
            backgroundImage: `url(${confetti})`,
            width: '100%',
            height: '20px',

            backgroundRepeat: 'none',
            backgroundPositionY: '20px',
          }}
          alt="confetti"
        ></Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '50px',
            backgroundImage: 'linear-gradient(to bottom, #FBE1E1 -100%, #FFF 100% )',
          }}
        ></Box>
      )}
      <Box className=" flex w-full flex-col items-center px-[50px] pb-[30px] lg:flex-row">
        {summary?.score >= summary?.passing_mark ? (
          <Box
            sx={{
              width: '25%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                backgroundImage: `url(${balloon})`,
                width: '120px',
                height: '120px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
              }}
              alt="balloon"
            />
          </Box>
        ) : (
          <Cancel sx={{ color: 'error.main', fontSize: '100px' }} />
        )}

        <Box className="w-full lg:ml-[45px]">
          {summary?.score >= summary?.passing_mark ? (
            <Box>
              <h2 className="text-center text-h2 text-edu-darkText lg:text-start">
                Congratulations! You've passed
              </h2>
            </Box>
          ) : (
            <Box>
              <h2 className="text-center text-h2 text-edu-darkText">Opps! Please try again</h2>
            </Box>
          )}

          <Box className="border-y-grayLine mt-[20px] flex flex-col justify-between  border-y-2 py-[12px] md:flex-row">
            <Box className="flex flex-col">
              <h2 className={`text-h2  ${summary?.score >= summary?.passing_mark ? 'text-edu-darkText' : 'text-edu-error'}`}>
                {Math.round(summary?.score)} %
              </h2>
              <p className="text-body1 mt-[3px]  text-edu-lightGrayText">Grade received</p>
            </Box>
            <Box className="flex flex-col">
              <h2 className=" text-h2 text-edu-darkText">{summary?.total_questions}</h2>
              <p className="text-body1 mt-[3px]  text-edu-lightGrayText">Total questions</p>
            </Box>
            <Box className="flex flex-col">
              <h2 className=" text-h2 text-edu-darkText">{summary?.correct_answer}</h2>
              <p className="text-body1 mt-[3px]  text-edu-lightGrayText">Correct answer</p>
            </Box>
          </Box>
        </Box>
      </Box>

      {results?.submission?.map((quest, index) => {
        return (
          <Box key={index} className=" w-full bg-edu-defaultBackground py-[10px]">
            <Box
              sx={{
                width: '100%',
                height: '100%',
                backgroundColor: 'white.main',
                padding: '30px',
              }}
            >
              <h3 className="mb-[30px] text-subtitle text-edu-darkText">{quest?.question}</h3>
              {quest?.answers.map((opt, optIndex) => {
                return (
                  <EDUFormAnswerLabel
                    key={optIndex}
                    selected={quest?.answer_id}
                    value={opt?.id}
                    answer={quest?.is_correct ? quest?.answer_id : null}
                    label={opt?.answer}
                  />
                )
              })}
              <Box
                sx={{
                  width: '100%',
                  backgroundColor: 'default-background.main',
                  px: '14px',
                  py: '12px',
                  mt: '30px',
                }}
              >
                {quest?.is_correct ? (
                  <p className="text-subtitle text-edu-success">Correct</p>
                ) : (
                  <p className="text-subtitle text-edu-error">Incorrect</p>
                )}
              </Box>
            </Box>
          </Box>
        )
      })}
    </div>
  )
}

export default QuizAnswer
