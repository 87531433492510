import { Skeleton } from '@mui/material'
import React from 'react'

const PaymentLoading = () => {
  return (
    <div className='h-[calc(100vh-121px)] md:h-[calc(100vh-131px)]'>
      <div className='flex w-full flex-col justify-start md:flex-row md:gap-[20px]'>
        <Skeleton variant='rectangular' width='25%' height='70px' />
        <div className="flex w-full flex-col items-start justify-start gap-[4px] md:w-3/4">
          <Skeleton variant='text' width='20%' />
          <Skeleton variant='text' width='100%' />
          <Skeleton variant='text' width='40%' />
        </div>
      </div>
      <div className="my-[30px] flex flex-col gap-[10px]">
        <Skeleton variant='text' width='40%' />
        <Skeleton variant='rectangular' width='100%' height='60px' />
        <Skeleton variant='rectangular' width='100%' height='60px' />
      </div>
      <div className="mb-[30px] flex flex-col gap-[10px]">
        <Skeleton variant='text' width='40%' />
        {
          [1, 1, 1].map((_, index) => (
            <div key={index} className="flex items-center justify-between">
              <Skeleton variant='text' width='20%' />
              <Skeleton variant='text' width='20%' />
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default PaymentLoading