import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import SkillsCard from './components/SkillsCard'
import { Work, MilitaryTech, AutoStories, Paid } from '@mui/icons-material'

const SectionThree = () => {
  const dataArray = [
    {
      icon: <MilitaryTech sx={{ color: 'primary.main', fontSize: '85px', mb: '10px' }} />,
      title: 'Trusted Masterclasses',
      message: 'Get certifications and opportunities to showcase your skills to employers',
    },
    {
      icon: <Work sx={{ color: 'primary.main', fontSize: '85px', mb: '10px' }} />,
      title: 'Graduate into a Job',
      message: 'Kickstart your career by joining our programs. Graduate into your dream job',
    },
    {
      icon: <AutoStories sx={{ color: 'primary.main', fontSize: '85px', mb: '10px' }} />,
      title: 'Skills First Learning',
      message:
        'Build your fundamentals with structured syllabuses, from the latest industry requirements',
    },
    {
      icon: <Paid sx={{ color: 'primary.main', fontSize: '85px', mb: '10px' }} />,
      title: 'Value for Money',
      message: 'Fast track learning and upskilling with industry expert trainers',
    },
  ]
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box
      className="m-auto max-w-[1200px]"
      sx={{
        p: md ? '60px' : '20px',
        backgroundColor: 'white.main',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant={md ? 'h1' : 'h2'} sx={{ mb: '20px' }}>
        Why adnexioedu?
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: md ? 'row' : 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          {dataArray?.map((item, index) => {
            return <SkillsCard key={index} icon={item?.icon} title={item?.title} message={item?.message} />
          })}
        </Box>
      </Box>
    </Box>
  )
}

export default SectionThree
