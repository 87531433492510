import EDUButton from 'components/Button/EDUButton'
import Header from 'components/Header/Header'
import EDUModal from 'components/Modal/EDUModal'
import SideMenu from 'components/SideMenu'
import { authContext } from 'contexts/AuthContext'
import { ProfileContext } from 'contexts/ProfileContext'
import React, { useState } from 'react'
import { useContext } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import onboardImg from 'assets/images/onboard.webp'
import { useEffect } from 'react'
import EDUTextField from 'components/TextField/EDUTextField'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import EDUPhoneNumber from 'components/PhoneNumber/EDUPhoneNumber'
import { Box, Typography } from '@mui/material'
import { useRef } from 'react'
import { CameraAlt } from '@mui/icons-material'
import EDUDropzone from 'components/Dropzone/EDUDropzone'
import { useCallback } from 'react'
import { signUrl } from 'services/api/storage'
import axios from 'axios'
import { jobseekerImage, updateProfile } from 'services/api/profile'
import { useMutation } from '@tanstack/react-query'
import Bugsnag from '@bugsnag/js'

const LayoutWithHeaderSideMenu = () => {
  const { auth } = useContext(authContext)
  const { profileData, retrieveProfile } = useContext(ProfileContext)
  const { pathname } = useLocation()
  const [step, setStep] = useState(0)
  const [errorMsg, setErrorMsg] = useState('')
  const [files, setFiles] = useState({})
  const dropzoneRef = useRef()
  const formikRef = useRef()
  const validationSchema = yup.object().shape({
    name: yup.string().required('Full name is required'),
    country_id: yup.string().required('Phone code is required'),
    contact_no: yup.string().matches(/^[0-9]+$/, 'Field must be an integer number').required('Phone number is required')
  })
  const { mutate, isLoading } = useMutation(updateProfile, {
    onSuccess: () => {
      retrieveProfile()
      handleClose()
    }
  })
  const submitDetails = async (values) => {
    const { country_id, contact_no, profile_image, ...rest } = values
    let deformatCode = JSON.parse(country_id)
    let data = {
      country_id: deformatCode?.id,
      contact_no: parseInt(contact_no),
      ...rest
    }
    try {
      if (profile_image) {
        const results = await signUrl({
          bucket: process.env.REACT_APP_AWS_BUCKET,
          key: 'profile_photo/' + profile_image.name,
        })
        const awsRequest = axios.create()
        delete awsRequest.defaults.headers.common['Authorization']
        await awsRequest.put(results.data.url, profile_image, {
          headers: {
            'Content-Type': profile_image.type,
          },
        })
        await jobseekerImage({ profile_image: profile_image.name })
      }
      mutate(data)
    } catch (error) {
      Bugsnag.notify(error)
    }
  }
  const [open, setModalOpen] = useState(false)
  const handleClose = () => {
    setModalOpen(false)
  }
  useEffect(() => {
    if (profileData?.contact_no === null || profileData?.contact_no === '') {
      setModalOpen(true)
    }
  }, [profileData])

  const handleUpload = useCallback(async (acceptedFiles, fileRejections) => {
    if (fileRejections.length && !acceptedFiles.length) {
      setErrorMsg(fileRejections[0]?.errors[0]?.code === "file-invalid-type" ? 'Failed to upload. Wrong file format.' : fileRejections[0]?.errors[0]?.code === "file-too-large" ? `Failed to upload. "File is larger than 2MB"` : `Failed to upload. ${fileRejections[0]?.errors[0]?.message}`);
      return;
    } else {
      setErrorMsg('')
    }
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    formikRef.current.setFieldValue('profile_image', acceptedFiles[0])
    // eslint-disable-next-line
  }, [])

  return (
    <div className="relative flex h-screen w-full flex-row items-start justify-start">
      <div className="fixed z-20 hidden bg-edu-white lg:block">
        <SideMenu isLogin={auth?.isAuth} />
      </div>
      <div className="absolute min-h-screen w-full lg:left-[75px] lg:w-[calc(100%-75px)]">
        <Header isLogin={auth?.isAuth} userInfo={profileData} />
        <div className={`${(pathname !== '/profile' && pathname !== '/account-settings') && 'm-auto max-w-screen-2xl'}`}>
          <Outlet />
        </div>
      </div>
      <EDUModal
        open={open}
        modalWidth={600}
      >
        {
          step === 0 ? (
            <div className='text-center flex flex-col items-center'>
              <img src={onboardImg} alt='' className='h-[250px]' />
              <div className='my-[40px]'>
                <h2 className='text-h3'>Welcome onboard!</h2>
                <p className=' my-[6px]'>
                  Thank you for choosing us! To begin your learning journey, let's personalize your experience by creating your profile, so we can tailor the courses to your liking.
                </p>
              </div>
              <div className='w-full flex flex-col gap-2 mt-[20px]'>
                <EDUButton onClick={() => setStep(1)} variant='contained' fullWidth>
                  Get started
                </EDUButton>
              </div>
            </div>
          ) : step === 1 ? (
            <Formik
              innerRef={formikRef}
              enableReinitialize
              initialValues={{
                profile_image: profileData?.profile_photo || null,
                name: profileData?.name || '',
                country_id: profileData?.country_id || 132,
                contact_no: profileData?.contact_no || ''
              }}
              validationSchema={validationSchema}
              onSubmit={submitDetails}
            >
              {
                ({ values, handleChange, errors, touched, setFieldValue, handleSubmit }) => (
                  <Form>
                    <div>
                      {/* <p className='text-subtitle2 text-edu-primary mb-[2px]'>Step 1 of 2</p> */}
                      <h3 className='text-h3'>Let's set your profile</h3>
                    </div>
                    <div className='my-[60px] w-full'>
                      <div className='w-full flex flex-col items-center justify-center'>
                        <EDUDropzone
                          ref={dropzoneRef}
                          sx={{
                            width: '150px',
                            height: '150px',
                            border: '0',
                            px: 0
                          }}
                          icon=''
                          useDropzoneObject={{
                            accept: {
                              'image/jpeg': ['.jpeg', '.png']
                            },
                            maxFiles: 1,
                            maxSize: 2097152,
                            noClick: true,
                            onDrop: handleUpload
                          }}
                        >
                          <div onClick={() => dropzoneRef.current.open()}>
                            {
                              files?.length && files[0].type.includes("image") ? (
                                <div className='relative'>
                                  <Box
                                    sx={{
                                      width: '150px',
                                      height: '150px',
                                      borderRadius: '100%',
                                      backgroundImage: `url(${files[0].preview})`,
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center',
                                    }}
                                  />
                                  <div className='absolute bottom-0 right-[6px] rounded-full border-[3px] border-edu-white flex justify-center items-center bg-edu-primary p-[8px]'>
                                    <CameraAlt sx={{ color: 'white.main', fontSize: 20 }} />
                                  </div>
                                </div>
                              ) : values?.profile_image ?
                                <div className='relative'>
                                  <Box
                                    sx={{
                                      width: '150px',
                                      height: '150px',
                                      borderRadius: '100%',
                                      backgroundImage: `url(${values?.profile_image})`,
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center',
                                    }}
                                  />
                                  <div className='absolute bottom-0 right-[6px] rounded-full border-[3px] border-edu-white flex justify-center items-center bg-edu-primary p-[8px]'>
                                    <CameraAlt sx={{ color: 'white.main', fontSize: 20 }} />
                                  </div>
                                </div> : (
                                  <div className='w-[150px] h-[150px] rounded-full border border-dashed flex justify-center items-center bg-edu-defaultBackground'>
                                    <CameraAlt sx={{ color: 'light-gray-text.main', fontSize: 30 }} />
                                  </div>
                                )
                            }
                          </div>
                        </EDUDropzone>
                        <p className='mt-[6px] text-center'>Profile picture</p>
                        {errorMsg && <p className='text-body2 text-edu-error mt-2'>{errorMsg}</p>}
                      </div>
                      <div className='mt-[30px] w-full mb-[20px]'>
                        <Typography>
                          Full name<span className='text-edu-error'>*</span>
                        </Typography>
                        <EDUTextField
                          name='name'
                          onChange={handleChange}
                          value={values?.name}
                          isErrorShown={touched?.name && errors?.name}
                          errorMessage={touched?.name && errors?.name}
                        />
                        <div className='mt-5 w-[70%]'>
                          <Typography sx={{ mb: '6px' }}>
                            Phone number<span className='text-edu-error'>*</span>
                          </Typography>
                          <EDUPhoneNumber
                            dropdownValue={values?.country_id}
                            onDropdownChange={(value) => setFieldValue(`country_id`, value)}
                            dropdownErrorMessage={
                              touched.country_id && errors.country_id
                            }
                            dropdownName='country_id'
                            inputValue={values.contact_no}
                            onInputChange={(e) => setFieldValue('contact_no', e.target.value)}
                            inputErrorMessage={touched.contact_no && errors.contact_no}
                            dropdownIsErrorShown={
                              touched.country_id && errors.country_id
                            }
                            inputName='contact_no'
                            inputIsErrorShown={touched.contact_no && errors.contact_no}
                          />
                        </div>
                      </div>
                    </div>
                    <EDUButton variant='contained' loading={isLoading} disabled={isLoading} onClick={handleSubmit} fullWidth>
                      Continue
                    </EDUButton>
                  </Form>
                )
              }
            </Formik>
          ) : null
        }
      </EDUModal>
    </div>
  )
}

export default LayoutWithHeaderSideMenu
