import { createContext, useState, useEffect, useContext } from 'react'
import { authContext } from 'contexts/AuthContext'
import Bugsnag from '@bugsnag/js'
import { getProfile } from 'services/api/profile'

export const ProfileContext = createContext()

const ProfileContextProvider = (props) => {
  const { auth } = useContext(authContext)
  const [profileData, setProfileData] = useState(null)

  const retrieveProfile = async () => {
    try {
      const results = await getProfile()
      setProfileData(results.data)
    } catch (e) {
      Bugsnag.notify(e)
    }
  }

  useEffect(() => {
    if (auth.isAuth) {
      retrieveProfile()
    } else {
      setProfileData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuth])

  return (
    <ProfileContext.Provider
      value={{
        profileData,
        retrieveProfile,
      }}
    >
      {props.children}
    </ProfileContext.Provider>
  )
}

export default ProfileContextProvider
