import Bugsnag from '@bugsnag/js'
import {
  AllInclusive,
  BarChart,
  CheckCircle,
  Description,
  Edit,
  Error,
  EventSeat,
  Favorite,
  FavoriteBorder,
  MilitaryTech,
  PhoneIphone,
  PlayCircle,
  VolumeUp,
  WatchLater,
} from '@mui/icons-material'
import { IconButton, Skeleton, Typography } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import EDUButton from 'components/Button/EDUButton'
import { authContext } from 'contexts/AuthContext'
import React from 'react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { addToWishlist, removeWishlist } from 'services/api/courseDetails'
import valueToStringConversion from 'services/helper/valueToStringConversion'

const CourseInfo = ({ courseDetails, isLoading }) => {
  const { auth } = useContext(authContext)
  const { isAuth } = auth
  const navigate = useNavigate()
  const queryClient = useQueryClient();

  const iconColor = 'information.main'

  const LoadingIcon = () => {
    return (
      <div className="my-[25px] flex w-full flex-row items-center justify-start space-x-[10px]">
        <Skeleton variant="circular" width={30} height={30} />
        <Typography variant="body1" sx={{ width: '80%' }}>
          <Skeleton width="100%" />
        </Typography>
      </div>
    )
  }

  const { mutate: mutateAddWishlist } = useMutation(addToWishlist, {
    onSuccess: () => {
      queryClient.invalidateQueries('queryCourseDetails')
    },
    onError: (err) => {
      Bugsnag.notify(err)
    }
  })

  const { mutate: mutateRemoveWishlist } = useMutation(removeWishlist, {
    onSuccess: () => {
      queryClient.invalidateQueries('queryCourseDetails')
    },
    onError: (err) => {
      Bugsnag.notify(err)
    }
  })

  const handleWishlist = () => {
    if (courseDetails?.isWishlist) {
      mutateRemoveWishlist(courseDetails?.id)
    } else {
      mutateAddWishlist(courseDetails?.id)
    }
  }

  return (
    <div className="w-full">
      {!isLoading ? (
        <p className="uppercase text-edu-information">{courseDetails?.course_type}</p>
      ) : (
        <Typography variant="body1" sx={{ width: '100%' }}>
          <Skeleton width="20%" />
        </Typography>
      )}
      <div className="flex flex-col md:flex-row items-start md:items-center justify-start md:space-x-[10px]">
        {!isLoading ? (
          <>
            <h1 className="text-h1">
              {courseDetails?.discount_value ? `RM${valueToStringConversion(courseDetails?.discount_fee?.toFixed(2))}` : `RM${valueToStringConversion(courseDetails?.course_fee?.toFixed(2))}`}
            </h1>
            {courseDetails?.discount_value ? (
              <>
                <h4 className="text-h4 text-edu-lightGrayText line-through">
                  RM{valueToStringConversion(courseDetails?.course_fee?.toFixed(2))}
                </h4>
                <h4 className="text-h4 text-edu-error">{courseDetails?.discount_type === 'percent' ? `${courseDetails?.discount_value}%` : `-RM${valueToStringConversion(courseDetails?.discount_value?.toFixed(2))}`}</h4>
              </>
            ) : null}
          </>
        ) : (
          <Typography variant="h1" sx={{ width: '100%' }}>
            <Skeleton width="50%" />
          </Typography>
        )}
      </div>
      {!isLoading ? (
        <div className="my-[25px] overflow-x-auto flex w-full flex-row items-center justify-start space-x-[30px]">
          {courseDetails?.level ? (
            <div className="flex flex-row items-center justify-start space-x-[8px]">
              <BarChart sx={{ color: iconColor }} />
              <p className="capitalize">
                {courseDetails?.level}
                <span>-level</span>
              </p>
            </div>
          ) : null}
          {courseDetails?.language ? (
            <div className="flex flex-row items-center justify-start space-x-[8px]">
              <VolumeUp sx={{ color: iconColor }} />
              <p className="capitalize">{courseDetails?.language}</p>
            </div>
          ) : null}
          {courseDetails?.course_type === 'BOOTCAMP' ? (
            <div className="flex flex-row items-center justify-start space-x-[8px]">
              <EventSeat sx={{ color: iconColor }} />
              <p className="capitalize">{`${courseDetails?.total_spots} seats`}</p>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="flex w-full flex-row items-center justify-start space-x-[10px]">
          {[1, 1, 1].map((_, index) => (
            <LoadingIcon key={index} />
          ))}
        </div>
      )}

      <div className="mb-[10px] flex flex-row items-center space-x-2.5">
        <EDUButton
          variant="contained"
          fullWidth
          onClick={() => navigate(`/payment-summary/${courseDetails?.id}`)}
          disabled={!isAuth || courseDetails?.isPurchased || courseDetails?.spots_remaining < 1}
        >
          {courseDetails?.isPurchased ? 'Purchased' : courseDetails?.spots_remaining < 1
            ? 'Fully booked'
            : 'Buy now'}
        </EDUButton>
        {
          isAuth && <IconButton
            onClick={handleWishlist}
            sx={{
              border: 1,
              borderStyle: 'solid',
              minHeight: '45px',
              px: '12.5px',
              '&.MuiIconButton-root': {
                borderRadius: '3.5px',
              },
            }}
          >
            {courseDetails?.isWishlist ? <Favorite sx={{ color: 'error.main' }} /> : <FavoriteBorder />}
          </IconButton>
        }
      </div>
      {
        !isAuth && <div className='bg-edu-lightYellow my-[14px] rounded-[4.5px] p-[16px] flex space-x-[12px] items-start'>
          <Error sx={{ color: 'warning.main', fontSize: 20 }} />
          <p>Please create or sign in to your account to purchase or add this course to your wishlist</p>
        </div>
      }
      {/* TO CHECK WHETHER ONLY TO DISPLAY ONCE BELOW CERTAIN NUMBERS OR NOT */}
      {!isLoading &&
        courseDetails?.type === 'BOOTCAMP' &&
        courseDetails?.spots_remaining !== 0 &&
        Boolean(courseDetails?.spots_remaining) && (
          <div className="flex flex-row items-center">
            <WatchLater sx={{ color: 'error.main', mr: '10px' }} />
            <p className="normal-case text-edu-error">{`${courseDetails?.spots_remaining
              } available seat${courseDetails?.spots_remaining > 1 ? `s` : ''} left`}</p>
          </div>
        )}
      <div className="mt-[30px]">
        <p className="text-subtitle">This course includes:</p>
        {!isLoading ? (
          courseDetails?.course_include?.map((item, index) => (
            <div
              key={index}
              className="my-[16px] flex flex-row items-center justify-start space-x-[14px]"
            >
              {item.type === 'access' ? (
                <AllInclusive sx={{ color: iconColor }} />
              ) : item.type === 'duration' ? (
                <PlayCircle sx={{ color: iconColor }} />
              ) : item.type === 'material' ? (
                <Description sx={{ color: iconColor }} />
              ) : item.type === 'quiz' ? (
                <Edit sx={{ color: iconColor }} />
              ) : item.type === 'device' ? (
                <PhoneIphone sx={{ color: iconColor }} />
              ) : item.type === 'certificate' ? (
                <MilitaryTech sx={{ color: iconColor }} />
              ) : (
                <CheckCircle sx={{ color: iconColor }} />
              )}
              <p>{item?.name}</p>
            </div>
          ))
        ) : (
          <div className="w-2/5">
            {[1, 1, 1, 1].map((_, index) => (
              <LoadingIcon key={index} />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default CourseInfo
