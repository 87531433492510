import Bugsnag from '@bugsnag/js'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useMutation } from '@tanstack/react-query'
import eduIcon from 'assets/logos/adnexio-edu-logo.svg'
import EDUButton from 'components/Button/EDUButton'
import EDUTextField from 'components/TextField/EDUTextField'
import { authContext } from 'contexts/AuthContext'
import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { changePassword, resetPassword } from 'services/api/authentication'
import { clearCookies } from 'services/helper/clearCookies'
import Cookies from 'universal-cookie'
import * as yup from 'yup'

const ResetPassword = () => {
  const cookies = new Cookies()
  const access_token = cookies.get("access_token")
  const { logoutUser } = useContext(authContext)
  const formikRef = useRef()
  const [typePassword, setTypePassword] = useState('password')
  const [typeCurrentPassword, setTypeCurrentPassword] = useState('password')
  const [typeConfirmPassword, setTypeConfirmPassword] = useState('password')
  const validationSchema = yup.object({
    email: yup.string().email('Must be a valid email').required('Email is required'),
    password: yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required'),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Password does not match').required('Password is required'),
  })

  const validationChangePasswordSchema = yup.object({
    current_password: yup.string().min(8, 'Password must be at least 8 characters').required('This field is required'),
    new_password: yup.string()
      .min(8, 'Password must be at least 8 characters')
      .notOneOf([yup.ref('current_password')], 'New password cannot be the same as old password')
      .required('Password is required'),
    new_confirm_password: yup.string().oneOf([yup.ref('new_password'), null], 'Password does not match').required('Password is required'),
  })

  const { token } = useParams()
  const [queryParams] = useSearchParams()
  const email = queryParams.get('email')
  const navigate = useNavigate()

  const { mutate, isLoading } = useMutation(resetPassword, {
    onSuccess: () => {
      navigate('/signin', {
        state: 'success'
      })
    }, onError: (err) => Bugsnag.notify(err)
  })

  const handleResetPassword = async (values) => {
    mutate(values)
  }

  const { mutate: mutateChangePassword, isLoading: loadingChangePassword } = useMutation(changePassword, {
    onSuccess: () => {
      clearCookies()
      logoutUser()
      navigate('/signin', {
        state: 'success'
      })
    }, onError: (error) => {
      if (error.status === 422) {
        const errors = error.data.errors
        Object.keys(errors).forEach(function (key) {
          formikRef.current.setFieldError(key, errors[key][0])
        })
      } else {
        Bugsnag.notify(error)
      }
    }
  })

  const handleChangePassword = async (values) => {
    mutateChangePassword(values)
  }

  return (
    <div className="relative bg-edu-defaultBackground w-screen min-h-screen flex justify-center items-center">
      <div className="absolute top-[42px] w-full p-5 flex justify-center items-center">
        <img onClick={() => navigate('/')} alt="adnexio EDU" src={eduIcon} />
      </div>
      <div className='max-w-[570px] p-[40px] bg-edu-white mt-[104px]'>
        {
          access_token ? (
            <Formik
              enableReinitialize
              innerRef={formikRef}
              validationSchema={validationChangePasswordSchema}
              initialValues={{
                current_password: '',
                new_password: '',
                new_confirm_password: '',
              }}
              onSubmit={handleChangePassword}
            >
              {
                ({ values, touched, errors, handleChange, handleSubmit }) => {
                  return (
                    <Form>
                      <h2 className='text-h2 mb-[10px] text-left'>Create new password</h2>
                      <p className='text-edu-lightGrayText text-justify md:text-left'>Create a new password for this email. New password must be different from the previous password.</p>
                      <div className='my-[30px]'>
                        <EDUTextField
                          label='Current password'
                          name='current_password'
                          value={values?.current_password}
                          onChange={handleChange}
                          type={typeCurrentPassword}
                          endAdornment={typeCurrentPassword === 'password' ? <Visibility color='light-gray-text' sx={{ cursor: 'pointer' }} onClick={() => setTypeCurrentPassword('text')} /> : <VisibilityOff color='light-gray-text' sx={{ cursor: 'pointer' }} onClick={() => setTypeCurrentPassword('password')} />}
                          isErrorShown={touched?.current_password && errors?.current_password}
                          errorMessage={touched?.current_password && errors?.current_password}
                        />
                        <div className='my-[20px]'>
                          <EDUTextField
                            label='Create new password'
                            name='new_password'
                            value={values?.new_password}
                            onChange={handleChange}
                            type={typePassword}
                            endAdornment={typePassword === 'password' ? <Visibility color='light-gray-text' sx={{ cursor: 'pointer' }} onClick={() => setTypePassword('text')} /> : <VisibilityOff color='light-gray-text' sx={{ cursor: 'pointer' }} onClick={() => setTypePassword('password')} />}
                            isErrorShown={touched?.new_password && errors?.new_password}
                            errorMessage={touched?.new_password && errors?.new_password}
                          />
                        </div>
                        <EDUTextField
                          label='Confirm password'
                          name='new_confirm_password'
                          value={values?.new_confirm_password}
                          onChange={handleChange}
                          type={typeConfirmPassword}
                          endAdornment={typeConfirmPassword === 'password' ? <Visibility color='light-gray-text' sx={{ cursor: 'pointer' }} onClick={() => setTypeConfirmPassword('text')} /> : <VisibilityOff color='light-gray-text' sx={{ cursor: 'pointer' }} onClick={() => setTypeConfirmPassword('password')} />}
                          isErrorShown={touched?.new_confirm_password && errors?.new_confirm_password}
                          errorMessage={touched?.new_confirm_password && errors?.new_confirm_password}
                        />
                      </div>
                      <EDUButton type='submit' loading={loadingChangePassword} disabled={loadingChangePassword} onClick={handleSubmit} variant='contained' fullWidth sx={{ mb: '10px' }}>
                        Change password
                      </EDUButton>
                    </Form>
                  )
                }
              }
            </Formik>
          ) : (
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                email: email,
                password: '',
                confirmPassword: '',
                token: token
              }}
              onSubmit={handleResetPassword}
            >
              {
                ({ values, touched, errors, handleChange, handleSubmit }) => {
                  return (
                    <Form>
                      <h2 className='text-h2 mb-[10px] text-left'>Create new password</h2>
                      <p className='text-edu-lightGrayText text-justify md:text-left'>Create a new password for this email. New password must be different from the previous password.</p>
                      <div className='my-[30px]'>
                        <EDUTextField
                          name='email'
                          value={values?.email}
                          disabled
                          isErrorShown={touched?.email && errors?.email}
                          errorMessage={touched?.email && errors?.email}
                        />
                        <div className='my-[20px]'>
                          <EDUTextField
                            label='Create new password'
                            name='password'
                            value={values?.password}
                            onChange={handleChange}
                            type={typePassword}
                            endAdornment={typePassword === 'password' ? <Visibility color='light-gray-text' sx={{ cursor: 'pointer' }} onClick={() => setTypePassword('text')} /> : <VisibilityOff color='light-gray-text' sx={{ cursor: 'pointer' }} onClick={() => setTypePassword('password')} />}
                            isErrorShown={touched?.password && errors?.password}
                            errorMessage={touched?.password && errors?.password}
                          />
                        </div>
                        <EDUTextField
                          label='Confirm password'
                          name='confirmPassword'
                          value={values?.confirmPassword}
                          onChange={handleChange}
                          type={typeConfirmPassword}
                          endAdornment={typeConfirmPassword === 'password' ? <Visibility color='light-gray-text' sx={{ cursor: 'pointer' }} onClick={() => setTypeConfirmPassword('text')} /> : <VisibilityOff color='light-gray-text' sx={{ cursor: 'pointer' }} onClick={() => setTypeConfirmPassword('password')} />}
                          isErrorShown={touched?.confirmPassword && errors?.confirmPassword}
                          errorMessage={touched?.confirmPassword && errors?.confirmPassword}
                        />
                      </div>
                      <EDUButton type='submit' loading={isLoading} disabled={isLoading} onClick={handleSubmit} variant='contained' fullWidth sx={{ mb: '10px' }}>
                        Reset
                      </EDUButton>
                    </Form>
                  )
                }
              }
            </Formik>
          )
        }
      </div>
    </div>
  )
}

export default ResetPassword