import QuizHeader from 'components/Header/QuizHeader'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { getGradedAnswer } from 'services/api/quiz'
import Bugsnag from '@bugsnag/js'
import Summary from '../components/Summary'
import QuizAnswer from '../components/QuizAnswer'
import LoadingGradedAnswer from '../components/LoadingGradedAnswer'

const QuizResults = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { state } = useLocation()
  const [results, setResults] = useState([]) //answers retrieve from backend
  const [summaryresults, setSummaryResults] = useState({}) //answers retrieve from backend
  const [viewSummary, setViewSummary] = useState(state?.view) // either view summary or view the answers

  const handleEndQuiz = () => {
    navigate(`/my-learning/${state?.enrollment_id}`, { state: state?.courseState })
  }

  const handleRetake = () => {
    navigate(-1)
  }

  const handleViewAnswer = () => {
    setViewSummary(false)
  }

  const { mutate, isLoading } = useMutation(
    async () => await getGradedAnswer(params?.id, { enrolment_id: state?.enrollment_id }),
    {
      onSuccess: (res) => {
        let correctAnswers = []
        res.submission.map((obj) => {
          if (obj.is_correct === true) {
            correctAnswers.push(obj)
          }
          return null
        })
        setResults(res)
        setSummaryResults({
          total_questions: res.submission.length,
          correct_answer: correctAnswers?.length,
          score: (correctAnswers?.length / res.submission.length) * 100,
          passing_mark: res.passing_mark
        })
      },
      onError: (err) => Bugsnag.notify(err),
    }
  )

  useEffect(() => {
    mutate()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="min-h-screen bg-edu-defaultBackground">
      <QuizHeader title={`Graded Quiz: ${state?.title}`} enrollment_id={state?.enrollment_id} courseState={state?.courseState} />
      {isLoading ? <LoadingGradedAnswer /> : viewSummary ? (
        <Summary
          results={summaryresults}
          handleRetake={handleRetake}
          handleEndQuiz={handleEndQuiz}
          handleViewAnswer={handleViewAnswer}
          retryStatus={results?.retry}
        />
      ) : (
        <QuizAnswer results={results} summary={summaryresults} />
      )}
    </div>
  )
}

export default QuizResults
