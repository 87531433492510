import { Check } from '@mui/icons-material'
import React from 'react'

const StepperIcon = ({ active, completed }) => {
  return (
    <div className="z-10 box-border pr-[20px]">
      <div
        className={` flex h-[25px] w-[25px] items-center justify-center rounded-full ${completed
            ? 'bg-edu-success'
            : active
              ? 'border border-edu-success bg-edu-white p-[5px]'
              : 'border border-edu-grayLine bg-edu-white'
          }`}
      >
        {completed ? (
          <Check color="white" />
        ) : (
          active && <div className="h-full w-full rounded-full bg-edu-success" />
        )}
      </div>
    </div>
  )
}

export default StepperIcon
