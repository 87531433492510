import { Close, KeyboardArrowRight, Menu } from '@mui/icons-material'
import { Box, Divider, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import adnexioEDU from 'assets/logos/adnexio-edu-logo.svg'
import React, { useContext, useState } from 'react'
import EDUDrawer from 'components/Drawer/EDUDrawer'
import EDUButton from 'components/Button/EDUButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { authContext } from 'contexts/AuthContext'
import EDUAvatar from 'components/Avatar/EDUAvatar'
import { ProfileContext } from 'contexts/ProfileContext'
import { ReactComponent as LogoutIcon } from 'assets/icons/logoutIcon.svg'
import { signout } from 'services/api/authentication'
import { clearCookies } from 'services/helper/clearCookies'
import Bugsnag from '@bugsnag/js'

const LandingPageHeader = () => {
  const { auth, logoutUser } = useContext(authContext)
  const { profileData } = useContext(ProfileContext)
  const [mobileMenu, setMobileMenu] = useState({ left: false })
  const handleMobileMenu = (anchor, state) => {
    setMobileMenu({ [anchor]: state })
  }
  const { pathname } = useLocation()
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const navigate = useNavigate()

  const handleNavigateMobile = (link) => {
    handleMobileMenu('left', false)
    navigate(link)
  }
  const handleSignOut = async () => {
    try {
      await signout()
      clearCookies()
      logoutUser()
      navigate('/')
    } catch (e) {
      Bugsnag.notify(e)
    }
  }

  const handleLogOutMobile = async () => {
    await handleSignOut()
    handleMobileMenu('left', false)
  }
  const firstSection = [
    {
      title: 'Sign in',
      link: '/signin'
    },
    {
      title: 'Sign up new account',
      link: '/signup'
    }
  ]
  const secondSection = [
    {
      title: 'Explore courses',
      link: '/explore-courses'
    },
    {
      title: 'Support',
      link: '/faq'
    }
  ]
  const authFirstSection = [
    {
      title: 'Dashboard',
      link: '/'
    },
    {
      title: 'My learning',
      link: '/my-learning'
    },
    {
      title: 'Payment',
      link: '/payment-dashboard'
    },
    {
      title: 'Explore courses',
      link: '/explore-courses'
    },
  ]
  const authSecondSection = [
    {
      title: 'My profile',
      link: '/profile'
    },
    {
      title: 'Account settings',
      link: '/account-settings'
    },
    {
      title: 'FAQs',
      link: '/faq'
    },
    {
      title: 'Support',
      link: '/contact-us'
    },
  ]
  return (
    <div className="sticky top-0 z-20  border-b border-edu-grayLine bg-edu-white py-3.5 px-[10px]"> {/*backdrop-blur bg-[rgba(255,255,255,0.8)] */}
      <div className="relative m-auto flex max-w-[1200px] lg:px-[60px] flex-row items-center justify-between">
        <div className=" lg:hidden">
          <IconButton onClick={() => handleMobileMenu('left', true)}>
            <Menu />
          </IconButton>
        </div>
        <div className="absolute left-1/2 -translate-x-1/2 lg:hidden">
          <img onClick={() => navigate('/')} alt="adnexio EDU" src={adnexioEDU} className="h-[20px] md:h-[30px]" />
        </div>
        <Box className="hidden flex-row items-center lg:flex">
          <img onClick={() => navigate('/')} alt="adnexio EDU" src={adnexioEDU} className="h-[20px] md:h-[30px]" />
          <div onClick={() => navigate('/explore-courses')} className={`py-[12px] px-3 relative ml-[40px] ${auth?.isAuth ? 'hidden' : 'block'}`}>
            <div className='cursor-pointer'>
              <Typography variant="subtitle1">Courses</Typography>
            </div>
            <div className={`absolute -bottom-[12px] left-0 w-full h-[2px] ${pathname === '/explore-courses' && 'bg-edu-primary'}`} />
          </div>
        </Box>

        <div className="hidden flex-row items-center justify-end space-x-3 lg:flex">
          <div onClick={() => navigate('/faq')} className={`py-[12px] px-3 mr-[20px] relative`}>
            <div className='cursor-pointer'>
              <Typography variant="subtitle1">Support</Typography>
            </div>
            <div className={`absolute -bottom-[12px] left-0 w-full h-[2px] ${(pathname === '/faq' || pathname === '/contact-us') && 'bg-edu-primary'}`} />
          </div>
          {
            auth?.isAuth ? <Tooltip title='Return to dashboard' placement='bottom'>
              <div>
                <EDUAvatar onClick={() => navigate('/')} src={profileData?.profile_photo} alt='profile' sx={{ cursor: 'pointer', ml: { md: '12px' } }}>{profileData?.name}</EDUAvatar>
              </div>
            </Tooltip> : (
              <>
                <EDUButton
                  onClick={() => navigate('/signin')}
                  variant="outlined"
                >
                  SIGN IN
                </EDUButton>

                <EDUButton
                  onClick={() => navigate('/signup')}
                  variant="contained"
                >
                  SIGN UP FOR FREE
                </EDUButton>
              </>
            )
          }
        </div>

        <EDUDrawer
          anchor={Object.keys(mobileMenu)[0]}
          drawerState={mobileMenu}
          handleDrawer={handleMobileMenu}
          sx={{ width: md ? '50vw' : '90vw' }}
          sxBody={{
            minHeight: '100vh',
            display: 'flex', flexDirection: 'column', justifyContent: auth?.isAuth ? 'space-between' : 'flex-start'
          }}
        >
          {
            !auth?.isAuth ? (
              <>
                <div className='py-[24px] px-[16px] flex flex-col space-y-[16px]'>
                  <p className='text-edu-lightGrayText cursor-default'>Account</p>
                  {
                    firstSection.map((item, index) => (
                      <div key={index} onClick={() => handleNavigateMobile(item?.link)} className={`flex items-center justify-between cursor-pointer`}>
                        <p>{item?.title}</p>
                        <KeyboardArrowRight sx={{ fontSize: 20 }} />
                      </div>
                    ))
                  }
                </div>
                <Divider />
                <div className='py-[24px] px-[16px] flex flex-col space-y-[16px]'>
                  {
                    secondSection.map((item, index) => (
                      <div key={index} onClick={() => handleNavigateMobile(item?.link)} className={`flex items-center justify-between cursor-pointer`}>
                        <p>{item?.title}</p>
                        <KeyboardArrowRight sx={{ fontSize: 20 }} />
                      </div>
                    ))
                  }
                </div>
                <Divider />
              </>
            ) : (
              <>
                <div >
                  <div className='px-[16px] py-[24px] flex items-start justify-between box-border'>
                    <div className='flex items-start w-auto'>
                      <EDUAvatar src={profileData?.profile_photo} alt='profile' sx={{ cursor: 'default', mr: 2 }}>{profileData?.name}</EDUAvatar>
                      <div className='flex-1 flex-col'>
                        <p className='text-subtitle mb-[8px] '>{profileData?.name}</p>
                        <p className='text-edu-lightGrayText truncate max-w-[50vw] md:max-w-[calc(50vw-120px)]'>{profileData?.email}</p>
                      </div>
                    </div>
                    <Close
                      sx={{ alignSelf: 'start', justifySelf: 'center', cursor: 'pointer' }}
                      onClick={() => handleMobileMenu('left', false)}
                    />
                  </div>
                  <Divider />
                  <div className='py-[24px] px-[16px] flex flex-col space-y-[16px]'>
                    <p className='text-edu-lightGrayText cursor-default'>Learn</p>
                    {
                      authFirstSection.map((item, index) => (
                        <div key={index} onClick={() => handleNavigateMobile(item?.link)} className={`flex items-center justify-between cursor-pointer`}>
                          <p>{item?.title}</p>
                          <KeyboardArrowRight sx={{ fontSize: 20 }} />
                        </div>
                      ))
                    }
                  </div>
                  <Divider />
                  <div className='py-[24px] px-[16px] flex flex-col space-y-[16px]'>
                    <p className='text-edu-lightGrayText cursor-default'>Account</p>
                    {
                      authSecondSection.map((item, index) => (
                        <div key={index} onClick={() => handleNavigateMobile(item.link)} className={`flex items-center justify-between cursor-pointer`}>
                          <p>{item?.title}</p>
                          <KeyboardArrowRight sx={{ fontSize: 20 }} />
                        </div>
                      ))
                    }
                  </div>
                </div>
                <div onClick={handleLogOutMobile} className='px-[16px] py-[24px] cursor-pointer border-t border-edu-grayLine flex justify-between items-center'>
                  <p className='text-edu-error'>Log out</p>
                  <LogoutIcon />
                </div>
              </>
            )
          }
        </EDUDrawer>
      </div>
    </div>
  )
}

export default LandingPageHeader
