import LayoutwithHeaderFooter from 'components/Container/LayoutwithHeaderFooter'
import LayoutWithHeaderSideMenu from 'components/Container/LayoutWithSideMenu'
// import EduRedirectSocialAuth from 'components/smart/EduRedirectSocialAuth'
import PrivateRoute from 'components/smart/PrivateRoute'
import { authContext } from 'contexts/AuthContext'
import Components from 'guidelines/Components'
import CourseDetailsPage from 'pages/courseDetails/view/CourseDetailsPage'
import DashboardPage from 'pages/dashboard_pages/view/DashboardPage'
import GradeQuiz from 'pages/gradeQuiz/view/GradeQuiz'
import LandingPage from 'pages/landingPage/view/LandingPage'
import MyClassPage from 'pages/myClass/view/MyClassPage'
import MyLearningPage from 'pages/myLearning/view/MyLearningPage'
import CourseNotFound from 'pages/not_found/view/CourseNotFound'
import PageNotFound404 from 'pages/not_found/view/NotFound'
import PaymentDashboard from 'pages/paymentDashboard/view/PaymentDashboard'
import PaymentStatus from 'pages/paymentStatus/view/PaymentStatus'
import PaymentSummary from 'pages/paymentSummary/view/PaymentSummary'
import RecapQuizPage from 'pages/recapQuiz/view/RecapQuizPage'
import QuizResults from 'pages/gradeQuiz/view/QuizResults'
import ForgotPassword from 'pages/resetPassword/view/ForgotPassword'
import ResetPassword from 'pages/resetPassword/view/ResetPassword'
import PrivacyPolicy from 'pages/setting/view/PrivacyPolicy'
import TermsConditions from 'pages/setting/view/TermsConditions'
import SignIn from 'pages/signIn/view/SignIn'
import CreateAccount from 'pages/signup/view/CreateAccount'
import GetStarted from 'pages/signup/view/GetStarted'
import React, { useContext, useEffect, useState } from 'react'
import { Outlet, Route, Routes, useLocation } from 'react-router-dom'
import ExploreCourses from 'pages/exploreCourses/page/ExploreCourses'
import Notifications from 'pages/notifications/view/Notifications'
import ConfirmationEmail from 'pages/verification/view/ConfirmationEmail'
import Profile from 'pages/profile/view/Profile'
import Faq from 'pages/setting/view/Faq'
import ContactUs from 'pages/setting/view/ContactUs'
import AccountSetting from 'pages/setting/view/AccountSetting'
import EmailChangeStatus from 'pages/verification/view/EmailChangeStatus'

const MainRoutes = () => {
  const { auth } = useContext(authContext)
  const location = useLocation()
  const { loading, isAuth } = auth

  // LAST PIECE BY THE MAN THE MYTH THE LEJEN, AMAL. TO SAVE PROGRESS OF THE VIDEO WHEN CHANGING ROUTE**
  const [prevRouteIsModule, setIsModule] = useState(false)
  const [saveVideoProgressFunction, setVideoProgressFunction] = useState(() => fn => fn)
  useEffect(() => {
    if (location.pathname.includes("/my-learning/")) {
      setIsModule(true)
      return
    }
    if (prevRouteIsModule) {
      setIsModule(false)
      if (!location.pathname.includes("/my-learning/")) {
        if (typeof saveVideoProgressFunction === 'function') {
          saveVideoProgressFunction()
        }
      }
    }
    // eslint-disable-next-line
  }, [location.pathname])

  if (loading) {
    return
  }

  return (
    <Routes>
      {/* CODE BELOW BECAUSE USING SAME ROUTE BUT DISPLAYING DIFFERENT LAYOUT/UI BASED ON AUTH */}
      {isAuth && (
        <Route path="/" element={<LayoutWithHeaderSideMenu />}>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/explore-courses" element={<ExploreCourses isLogin={isAuth} />} />
          <Route path="course/:id" element={<CourseDetailsPage />} />
        </Route>
      )}
      <Route element={<LayoutwithHeaderFooter />}>
        <Route path='/' element={<LandingPage />} />
        <Route path="/explore-courses" element={<ExploreCourses isLogin={isAuth} />} />
        <Route path="course/:id" element={<CourseDetailsPage />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
      </Route>

      {/* FOR DEVELOPERS ONLY */}
      {process.env.NODE_ENV !== 'production' && (
        <>
          <Route path="/components" element={<Components />} />
        </>
      )}

      {/* REDIRECT FAIL */}
      {/* <Route path="redirect/:status/:value" element={<EduRedirectSocialAuth />} /> */}
      {/* REDIRECT SUCCESS */}
      {/* <Route path="redirect/:status/:value/:expired_at" element={<EduRedirectSocialAuth />} /> */}

      <Route path="/course-not-found" element={<CourseNotFound />} />
      <Route path="*" element={<PageNotFound404 />} />
      <Route path="signin" element={<SignIn />} />
      <Route path="/verification-email" element={<ConfirmationEmail />} />
      <Route path="signup" element={<GetStarted />} />
      <Route path="create-account" element={<CreateAccount />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-email/success" element={<EmailChangeStatus />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route element={<PrivateRoute redirectTo="signin" />}>
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path='recap-quiz/:id' element={<RecapQuizPage />} />
        <Route path="/payment-summary/:id" element={<PaymentSummary />} />
        <Route path="/payment-status" element={<PaymentStatus />} />
        <Route path="/quiz/:id" element={<GradeQuiz />} />
        <Route path="/quiz/:id/results" element={<QuizResults />} />
        <Route element={<LayoutWithHeaderSideMenu />}>
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/account-settings" element={<AccountSetting />} />
          <Route path="/payment-dashboard" element={<PaymentDashboard />} />
          <Route path="my-learning" element={<MyLearningPage />} />
          <Route path="my-learning" element={<Outlet />}>
            <Route path=":id" element={<MyClassPage setVideoProgressFunction={setVideoProgressFunction} />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}

export default MainRoutes
