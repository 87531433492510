import { Create, Description, PlayCircleOutline } from '@mui/icons-material'
import { Skeleton } from '@mui/material'
import EDUAccordion from 'components/Accordians/EDUAccordion'
import EDUAccordionDetails from 'components/Accordians/EDUAccordionDetails'
import EDUAccordionSummary from 'components/Accordians/EDUAccordionSummary'
import EDUTabPanel from 'components/Tab/EDUTabPanel'
import React from 'react'
import timeConversion from 'services/helper/timeConversion'

const LessonPlanTab = ({ label, lessonPlans, isLoading }) => {
  // TBC HOW THE ACCORDION SUPPOSE TO WORK
  return (
    <EDUTabPanel label={label}>
      <div className="py-[30px]">
        {!isLoading
          ? lessonPlans?.map((topic, index) => (
            <EDUAccordion key={index}>
              <EDUAccordionSummary>{topic.topic_title}</EDUAccordionSummary>
              {topic.course_sessions.map((subtopic, index) => {
                let { hours, minutes } = timeConversion(subtopic?.video?.duration)
                return (
                  <div key={index}>
                    {
                      subtopic?.video && <EDUAccordionDetails>
                        <div className="flex flex-row items-center">
                          <PlayCircleOutline color="light-gray-text" sx={{ fontSize: 25 }} />
                          <div className="mx-[16px] flex flex-col justify-around">
                            <p>{subtopic?.video?.title}</p>
                            <p className="text-body2 text-edu-lightGrayText">
                              {hours !== '00' && `${hours.slice(-1, 2)}h`} {minutes && `${minutes}min`}
                            </p>
                          </div>
                        </div>
                      </EDUAccordionDetails>
                    }
                    {
                      subtopic?.material && subtopic?.material?.map((material, index) => (
                        <EDUAccordionDetails key={index}>
                          <div className="flex flex-row items-center">
                            <Description color="light-gray-text" sx={{ fontSize: 25 }} />
                            <div className="mx-[16px] flex flex-col justify-around">
                              <p>{material?.title}</p>
                              <p className="text-body2 text-edu-lightGrayText">
                                Reading
                              </p>
                            </div>
                          </div>
                        </EDUAccordionDetails>
                      ))
                    }
                    {
                      subtopic?.quiz && <EDUAccordionDetails>
                        <div className="flex flex-row items-center">
                          <Create color="light-gray-text" sx={{ fontSize: 25 }} />
                          <div className="mx-[16px] flex flex-col justify-around">
                            <p>{subtopic?.quiz?.title}</p>
                            <p className="text-body2 text-edu-lightGrayText">
                              Quiz
                            </p>
                          </div>
                        </div>
                      </EDUAccordionDetails>
                    }
                    {/* <EDUAccordionDetails>
                      <div className="flex flex-row items-center">
                        {subtopic.video && (
                          <PlayCircleOutline color="light-gray-text" sx={{ fontSize: 25 }} />
                        )}
                        {subtopic.material && (
                          <Description color="light-gray-text" sx={{ fontSize: 25 }} />
                        )}
                        {subtopic.quiz && (
                          <Create color="light-gray-text" sx={{ fontSize: 25 }} />
                        )}
                        <div className="mx-[16px] flex flex-col justify-around">
                          <p>{subtopic.session_title}</p>
                          <p className="text-body2 text-edu-lightGrayText">
                            {subtopic.subtopic_type === 'video' && subtopic.duration}
                            {subtopic.subtopic_type === 'read' && 'Reading'}
                            {subtopic.subtopic_type === 'quiz' && 'Quiz'}
                          </p>
                        </div>
                      </div>
                    </EDUAccordionDetails> */}
                  </div>
                )
              })}
            </EDUAccordion>
          ))
          : [1, 1, 1].map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width="100%"
              height="60px"
              sx={{ my: 1 }}
            />
          ))}
      </div>
    </EDUTabPanel>
  )
}

export default LessonPlanTab
