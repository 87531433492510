import { Close } from '@mui/icons-material'
import { Box, Divider, Drawer, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

const EDUDrawer = ({
  drawerState, //receive an object with the key as an anchor and value for the state
  anchor, //direction of drawer
  header,
  handleDrawer, // accepts 2 parameters, first is anchor, second is drawer state
  divider = false,
  children,
  sxHeader,
  sx,
  sxBody,
  ...rest
}) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const lg = useMediaQuery(theme.breakpoints.up('lg'))
  return (
    <Drawer
      anchor={anchor}
      open={drawerState[anchor]}
      onClose={() => handleDrawer(anchor, false)}
      {...rest}
    >
      <Box sx={{ minHeight: '100vh', boxSizing: 'border-box', width: lg ? '600px' : md ? '500px' : '100vw', ...sx }}>
        {header && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: '32px', py: '15px', gap: 3, ...sxHeader }}
          >
            <Box>{header}</Box>
            <Close
              sx={{ alignSelf: 'center', justifySelf: 'center', cursor: 'pointer' }}
              onClick={() => handleDrawer(anchor, false)}
            />
          </Box>
        )}
        {divider && <Divider />}
        <Box sx={{ ...sxBody }}>{children}</Box>
      </Box>
    </Drawer>
  )
}

export default EDUDrawer
