import Bugsnag from '@bugsnag/js'
import { useMutation } from '@tanstack/react-query'
import eduIcon from 'assets/logos/adnexio-edu-logo.svg'
import EDUButton from 'components/Button/EDUButton'
import EDUTextField from 'components/TextField/EDUTextField'
import { Form, Formik } from 'formik'
import { useRef } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { forgotPassword } from 'services/api/authentication'
import * as yup from 'yup'

const ForgotPassword = () => {
  const [payload, setPayload] = useState({})
  const navigate = useNavigate()
  const formikRef = useRef()
  const validationSchema = yup.object({
    email: yup.string().email('Email must be a valid email').required('Email is required'),
  })

  const { mutate, isLoading } = useMutation(forgotPassword, {
    onSuccess: () => {
      navigate('/verification-email', {
        state: {
          status: 'check',
          payload: payload
        }
      })
    },
    onError: (err) => {
      if (err?.data?.message === 'We cant find user with that email address!') {
        formikRef.current.setFieldError('email', 'This email does not exist in our database');
      } else {
        Bugsnag.notify(err)
      }
    }
  })

  const handleResetPassword = (values) => {
    mutate(values)
  }
  return (
    <div className="relative bg-edu-defaultBackground w-screen min-h-screen flex justify-center items-center">
      <div className="absolute top-[42px] w-full p-5 flex justify-center items-center">
        <img onClick={() => navigate('/')} alt="adnexio EDU" src={eduIcon} />
      </div>
      <div className='max-w-[570px] p-[40px] bg-edu-white'>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            email: ''
          }}
          onSubmit={handleResetPassword}
          innerRef={formikRef}
        >
          {
            ({ values, touched, errors, setFieldValue, handleSubmit }) => {
              return (
                <Form>
                  <h2 className='text-h2 mb-[10px] text-left'>Reset password</h2>
                  <p className='text-edu-lightGrayText text-justify md:text-left'>Enter the email associated with your account to reset the password</p>
                  <div className='my-[30px]'>
                    <EDUTextField
                      name='email'
                      label='Email'
                      value={values?.email}
                      onChange={(e) => {
                        setFieldValue('email', e.target.value)
                        setPayload({
                          email: e.target.value
                        })
                      }}
                      isErrorShown={touched?.email && errors?.email}
                      errorMessage={touched?.email && errors?.email}
                    />
                  </div>
                  <EDUButton loading={isLoading} disabled={isLoading} onClick={handleSubmit} variant='contained' fullWidth sx={{ mb: '10px' }}>
                    Continue
                  </EDUButton>
                </Form>
              )
            }
          }
        </Formik>
      </div>
    </div>
  )
}

export default ForgotPassword