import { Box, Modal } from '@mui/material'

const EDUModal = ({
  modalWidth = 750,
  children,
  sxModal,
  ...rest
}) => {
  return (
    <Modal {...rest}>
      <Box
        bgcolor="white.main"
        width={{ xs: 'calc(100% - 30px)', lg: modalWidth }}
        borderRadius={{ xs: 2, lg: '20px' }}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          boxShadow: 3,
          py: '40px',
          px: { xs: '20px', md: '50px' },
          ...sxModal,
        }}
        boxSizing={'border-box'}
      >
        {children}
      </Box>
    </Modal>
  )
}

export default EDUModal