import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import email from '../assets/confirmation-email.webp'
import eduIcon from 'assets/logos/adnexio-edu-logo.svg'
import { useEffect } from 'react'
import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { resendEmail } from 'services/api/registration'
import { forgotPassword } from 'services/api/authentication'

const ConfirmationEmail = () => {
  const { state } = useLocation()
  const [countdown, setCountdown] = useState(30)
  const navigate = useNavigate()

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown === 0) {
        setCountdown(0)
      } else {
        setCountdown((prev) => prev - 1)
      }
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  })

  const { mutate: mutateVerify } = useMutation(resendEmail)
  const { mutate: mutateCheck } = useMutation(forgotPassword)

  const handleResend = () => {
    if (state?.status === 'verify') {
      mutateVerify(state?.payload)
    } else {
      mutateCheck(state?.payload)
    }
  }

  if (state?.status === null) {
    return <Navigate to={'/'} />
  }
  return (
    <div className="relative bg-edu-defaultBackground  w-screen min-h-screen flex justify-center items-center">
      <div className="absolute top-[42px] w-full p-5 flex justify-center items-center">
        <img onClick={() => navigate('/')} alt="adnexio EDU" src={eduIcon} />
      </div>
      <div className='max-w-[570px] text-center flex flex-col items-center py-[40px] px-[40px] bg-edu-white'>
        <img src={email} alt='' className='h-[215px]' />
        <h2 className='mt-[30px] mb-[10px] text-h2'>{state?.status === 'verify' || state?.status === 'resetEmail' ? 'Verify you email' : 'Check your email'}</h2>
        <p className='text-edu-lightGrayText'>{state?.status === 'verify' ? 'We’ve sent a verification link to your email. Please verify by clicking on the link you receive and you’re all set!' : state?.status === 'resetEmail' ? `We’ve sent a verification link to ${state?.payload?.email}. Please verify by clicking on the link you receive and you’re all set!` : 'We’ve sent you an email. To reset your password, please click “ reset button “ in the email you’ve received.'}</p>
        <div className={`mt-[30px] p-3 flex items-center justify-center ${state?.status === 'resetEmail' && 'hidden'}`}>
          <p>Didn’t receive any email? <span onClick={countdown < 1 ? () => handleResend() : null} className={`${countdown > 0 ? 'text-edu-lightGrayText cursor-default' : 'text-edu-secondary underline cursor-pointer'}`}>Send again {countdown > 0 && `in 00:${countdown < 10 ? `0${countdown}` : countdown}`}</span></p>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationEmail