import Bugsnag from "@bugsnag/js"
import { Typography } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import EDUBreadLink from "components/Breadcrumbs/EDUBreadLink"
import EDUBreadcrumb from "components/Breadcrumbs/EDUBreadcrumb"
import EDUButton from "components/Button/EDUButton"
import EDUPhoneNumber from "components/PhoneNumber/EDUPhoneNumber"
import EDUSnackbar from "components/Snackbar/EDUSnackbar"
import EDUTextField from "components/TextField/EDUTextField"
import { ProfileContext } from "contexts/ProfileContext"
import { Form, Formik } from "formik"
import { useRef } from "react"
import { useState } from "react"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { eduSupport } from "services/api/registration"
import * as yup from 'yup'

const ContactUs = () => {
  const navigate = useNavigate()
  const { profileData } = useContext(ProfileContext)
  const formikRef = useRef()
  const validationSchema = yup.object().shape({
    name: yup.string().required('Full name is required'),
    email: yup.string().email('Please use correct email format').required('Email is required'),
    number: yup.string().matches(/^[0-9]+$/, 'Field must be an integer number'),
    message: yup.string().required('This field is required'),
  })
  const [isOpenSnackbar, setOpenSnackbar] = useState(false)
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }
  const handleOpenSnackbar = () => {
    setOpenSnackbar(true)
  }
  const { mutate, isLoading } = useMutation(eduSupport, {
    onSuccess: () => {
      handleOpenSnackbar()
      formikRef.current.handleReset()
    }, onError: (err) => {
      Bugsnag.notify(err)
    }
  })
  const submitDetails = (values) => {
    const { code, number, ...rest } = values
    let deformatCode = JSON.parse(code)
    let data = {
      ...rest,
      contact_number: deformatCode?.phone_code?.concat(number)
    }
    mutate(data)
  }
  return (
    <div className="pt-[50px] pb-[100px]">
      <EDUBreadcrumb separatorSx={{ fontSize: 20 }}>
        <EDUBreadLink href="/faq">Support</EDUBreadLink>
        <EDUBreadLink last>Contact us</EDUBreadLink>
      </EDUBreadcrumb>
      <div className="mt-[16px] mb-[40px] space-y-[10px]">
        <h1 className="text-h1">Contact Us</h1>
        <p className="text-edu-lightGrayText">If you have any questions or concerns, please don't hesitate to reach out to us by filling out the form below. We'll respond to your message via email.</p>
      </div>
      <Formik
        enableReinitialize
        innerRef={formikRef}
        initialValues={{
          name: profileData?.name || '',
          email: profileData?.email || '',
          code: profileData?.country_id || 132,
          number: profileData?.contact_no || '',
          message: ''
        }}
        validationSchema={validationSchema}
        onSubmit={submitDetails}
      >
        {
          ({ values, handleChange, handleSubmit, setFieldValue, touched, errors }) => (
            <Form>
              <div className="space-y-[20px] mb-[40px]">
                <div>
                  <Typography >
                    Name<span className='text-edu-error'>*</span>
                  </Typography>
                  <EDUTextField
                    name='name'
                    onChange={handleChange}
                    disabled={Boolean(profileData)}
                    value={values?.name}
                    isErrorShown={touched?.name && errors?.name}
                    errorMessage={touched?.name && errors?.name}
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
                  <div>
                    <Typography>
                      Email<span className='text-edu-error'>*</span>
                    </Typography>
                    <EDUTextField
                      name='email'
                      onChange={handleChange}
                      disabled={Boolean(profileData)}
                      value={values?.email}
                      isErrorShown={touched?.email && errors?.email}
                      errorMessage={touched?.email && errors?.email}
                    />
                  </div>
                  <EDUPhoneNumber
                    label='Phone number'
                    dropdownValue={values?.code}
                    onDropdownChange={(value) => setFieldValue(`code`, value)}
                    dropdownErrorMessage={
                      touched.code && errors.code
                    }
                    dropdownName='code'
                    inputDisabled={Boolean(profileData)}
                    dropdownDisabled={Boolean(profileData)}
                    inputValue={values.number}
                    onInputChange={(e) => setFieldValue('number', e.target.value)}
                    inputErrorMessage={touched.number && errors.number}
                    dropdownIsErrorShown={
                      touched.code && errors.code
                    }
                    inputName='number'
                    inputIsErrorShown={touched.number && errors.number}
                  />
                </div>
                <div>
                  <Typography>
                    How can we help you?<span className='text-edu-error'>*</span>
                  </Typography>
                  <EDUTextField
                    name='message'
                    onChange={handleChange}
                    value={values?.message}
                    multiline
                    minRows={6}
                    maxRows={6}
                    charLimit={1000}
                    isErrorShown={touched?.message && errors?.message}
                    errorMessage={touched?.message && errors?.message}
                  />
                </div>
              </div>
              <EDUButton type='submit' loading={isLoading} disabled={isLoading} onClick={handleSubmit} variant='contained' sx={{ width: { xs: '100%', md: '300px' } }}>Submit</EDUButton>
            </Form>
          )
        }
      </Formik>
      <p className="mt-[30px]">By completing and submitting the form, you agree to our <span onClick={() => navigate('/terms-conditions')} className="underline text-edu-secondary cursor-pointer">Terms of Use</span> and <span onClick={() => navigate('/privacy-policy')} className="underline text-edu-secondary cursor-pointer">Privacy policy</span></p>
      <EDUSnackbar
        open={isOpenSnackbar}
        handleClose={handleCloseSnackbar}
        autoHideDuration={4000}
        status={'success'}
        message='Your message has been received'
        transition={'grow'}
      />
    </div>
  )
}

export default ContactUs