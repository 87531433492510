import Bugsnag from '@bugsnag/js'
import { AccountBalanceWallet } from '@mui/icons-material'
import { Skeleton, useMediaQuery, useTheme } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import EDUButton from 'components/Button/EDUButton'
import EDUDrawer from 'components/Drawer/EDUDrawer'
import EDUTabBadge from 'components/Tab/EDUTabBadge'
import React from 'react'
import { useState } from 'react'
import { pendingPayment } from 'services/api/payment'
import ActivePaymentTab from '../components/ActivePaymentTab'
import CompletedPaymentTab from '../components/CompletedPaymentTab'
import PaymentDrawer from '../components/PaymentDrawer'

const PaymentDashboard = () => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const [drawerState, setDrawerState] = useState({ right: false })
  const [dataLength, setDataLength] = useState(0)

  const handleDrawer = (anchor, state) => {
    setDrawerState({ [anchor]: state })
  }

  const { data: pendingPaymentData, isLoading } = useQuery(['pendingPayment'], async () => await pendingPayment(), {
    onError: (err) => Bugsnag.notify(err)
  })
  let totalAmount = []
  pendingPaymentData?.map((course) => {
    totalAmount.push(parseFloat(course?.amount?.replace(new RegExp(',', 'g'), '')))
    return null
  })

  const overdueStatus = pendingPaymentData?.find((obj) => obj?.overdue_status === 1)

  return (
    <div className="py-[30px] px-[30px]">
      {isLoading ? (
        <div className="mb-[17px] flex w-full flex-col items-start justify-between gap-5 rounded-lg border border-edu-grayLine py-[30px] px-[20px] md:flex-row md:items-center">
          <div className="flex w-full items-center justify-start gap-[10px]">
            <Skeleton variant="circular" width="50px" height="50px" />
            <div className="flex w-[calc(100%-60px)] flex-col items-start">
              <Skeleton width="40%" />
              <Skeleton width="60%" />
            </div>
          </div>
          <Skeleton variant="rectangular" width={md ? '20%' : '100%'} height="40px" />
        </div>
      ) : (
        <div className="mb-[17px] flex flex-col items-start justify-between gap-5 rounded-lg border border-edu-grayLine py-[30px] px-[20px] md:flex-row md:items-center">
          <div className="flex items-center justify-start">
            <div className="mr-[20px] box-border flex items-center justify-center rounded-full bg-edu-lightYellow p-[15px]">
              <AccountBalanceWallet color="primary" />
            </div>
            <div className="flex flex-col items-start">
              <p className="text-edu-lightGrayText">Amount to pay</p>
              <div className="flex flex-col items-start gap-[5px] md:flex-row md:items-center md:gap-[10px]">
                <h3 className={`text-h3 ${overdueStatus && 'text-edu-error'}`}>RM{' '}
                  {totalAmount
                    ?.reduce((a, b) => a + b, 0)
                    ?.toFixed(2)
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h3>
                {overdueStatus && (
                  <div className="bg-edu-lightError px-[6px] py-[2px]">
                    <p className="text-subtitle2 text-edu-error">Overdue</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <EDUButton
            variant="contained"
            onClick={() => handleDrawer('right', true)}
            disabled={(pendingPaymentData?.length < 1 || pendingPaymentData === null)}
            fullWidth={!md}
          >
            Make payment
          </EDUButton>
        </div>
      )}
      <EDUTabBadge
        tabsBorderBottom
        sx={{
          '& .MuiTabScrollButton-root': {
            display: md && 'none',
          },
        }}
      >
        <ActivePaymentTab label={`Active Payment ${Boolean(dataLength) ? `(${dataLength})` : ''
          }`} setDataLength={setDataLength} />
        <CompletedPaymentTab label='Completed' />
      </EDUTabBadge>
      <EDUDrawer
        drawerState={drawerState}
        handleDrawer={handleDrawer}
        anchor={Object.keys(drawerState)[0]}
        header={<h4 className="text-h4">Amount to pay</h4>}
        divider
      >
        <PaymentDrawer data={pendingPaymentData} totalAmount={totalAmount} overdueStatus={overdueStatus} />
      </EDUDrawer>
    </div>
  )
}

export default PaymentDashboard
