import { KeyboardArrowRight } from "@mui/icons-material"
import EDUButton from "components/Button/EDUButton"
import { ProfileContext } from "contexts/ProfileContext"
import { useContext } from "react"
import ChangeAccountModal from "../components/ChangeAccountModal"
import { useNavigate } from "react-router-dom"

const AccountSetting = () => {
  const { profileData } = useContext(ProfileContext)
  const navigate = useNavigate()
  const handleResetPassword = () => {
    navigate(`/reset-password`)
  }
  return (
    <div className="bg-edu-defaultBackground w-full min-h-[calc(100vh-70.5px)] p-[30px]">
      <div className="m-auto max-w-screen-2xl">
        <h4 className="text-h4 mb-[16px]">Account settings</h4>
        <p className="my-[12px]">Security</p>
        <div className="bg-edu-white border rounded-[4.5px] border-edu-grayLine">
          <div className="flex justify-between items-center w-full p-[16px] border-b border-edu-grayLine">
            <div className="flex items-center w-full">
              <p className="md:w-[200px] lg:w-[300px] mr-[14px]">Email</p>
              <p className="hidden md:block text-edu-lightGrayText self-stretch flex-1 truncate">{profileData?.email}</p>
            </div>
            {
              profileData?.social_account ? <p className="text-edu-lightGrayText underline cursor-default">Change</p> : <ChangeAccountModal email={profileData?.email}>
                <p className="text-edu-secondary underline cursor-pointer">Change</p>
              </ChangeAccountModal>
            }
          </div>
          <div className="p-[4px]">
            <EDUButton
              onClick={handleResetPassword}
              disabled={profileData?.social_account}
              fullWidth
              sx={{
                pl: 2,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p className="text-body text-edu-darkText">Change password</p>
              <KeyboardArrowRight sx={{ color: 'light-gray-text.main', fontSize: 20 }} />
            </EDUButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountSetting