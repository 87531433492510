import { AccordionDetails, styled } from '@mui/material'
import React from 'react'

const EDUAccordionDetails = styled(({ ...rest }) => <AccordionDetails {...rest} />)(
  ({ theme }) => ({
    width: '100%',
    backgroundColor: theme.palette.white.main,
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '14px',
    paddingRight: '14px',
    boxSizing: 'border-box',
  })
)

export default EDUAccordionDetails
