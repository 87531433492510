import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import EDUButton from 'components/Button/EDUButton'
import EDUTextField from 'components/TextField/EDUTextField'
import { Form, Formik } from 'formik'
import { useRef, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom/dist'
import BackgroundSignin from '../assets/sign-in.webp'
import eduIcon from 'assets/logos/adnexio-edu-logo.svg'
import * as yup from 'yup'
import { login } from 'services/api/authentication'
import Bugsnag from '@bugsnag/js'
import { useEffect } from 'react'
import EDUSnackbar from 'components/Snackbar/EDUSnackbar'
import { authContext } from 'contexts/AuthContext'
import { useContext } from 'react'

const SignIn = () => {
  const { auth } = useContext(authContext)
  const [type, setType] = useState('password')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const formikRef = useRef()
  const [open, setOpen] = useState(false)
  const { state } = useLocation()
  const handleClose = (event, reason) => {
    if ((reason === 'clickaway') || (reason === 'backdropClick') || (reason === 'escapeKeyDown')) {
      return
    }
    setOpen(false)
  }

  const [isOpenSnackbar, setOpenSnackbar] = useState(false)
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  useEffect(() => {
    if (state === 'success') {
      setOpenSnackbar(true)
    }
    // return
    window.history.replaceState({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigateContactUs = () => {
    navigate('/contact-us')
  }
  const validationSchema = yup.object({
    email: yup.string().email('Email must be a valid email').required('Email is required'),
    password: yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required'),
  })
  const navigate = useNavigate()
  const navigateSignup = () => {
    navigate('/signup')
  }
  const navigateForgotPassword = () => {
    navigate('/forgot-password')
  }
  const handleSignIn = async (values, actions) => {
    setLoading(true)
    try {
      await login(values)
      window.location.href = '/'
      /* TO CONFIRM ROUTING ONCE LOGIN */
      // ==============================================================
      // if (historyData?.from === 'courses/') {
      //   window.location.href = `/courses/${historyData.id}`
      // } else if (historyData?.from === 'courses') {
      //   window.location.href = `/courses`
      // } else if (historyData?.from === 'edu') {
      //   window.location.href = `/edu`
      // } else {
      //   return (window.location.href = '/edu')
      // }
      // ==============================================================
    } catch (e) {
      if (e.status === 401) {
        if (
          e.data.message ===
          'This email was used to create account with banned previously. Please proceed to sign in with banned'
        ) {
          setErrorMessage('Your account has been blocked.')
        } else {
          formikRef.current.setFieldError('email', e.data.message)
          // setErrorMessage(e.data.message)
        }
      } else if (e.status === 422) {
        const errors = e.data.errors
        Object.keys(errors).forEach(function (key) {
          actions.setFieldError(key, errors[key][0])
        })
      } else {
        Bugsnag.notify(e)
        setErrorMessage(
          'An unexpected error occurred. Please retry or contact us at support@adnexio.jobs for support.'
        )
        setOpen(true)
      }
      setLoading(false)
    }
  }
  if (auth?.isAuth) {
    return <Navigate to='/' />
  }
  return (
    <div className="flex flex-row w-screen min-h-screen ">
      <Box
        sx={{
          height: '100vh',
          width: '40%',
          display: { xs: 'none', lg: 'block' },
          background: `url(${BackgroundSignin})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <div className="w-full flex flex-row justify-center items-start min-h-full lg:w-3/5 p-[50px] md:p-[60px] ">
        <Formik
          innerRef={formikRef}
          validationSchema={validationSchema}
          onSubmit={handleSignIn}
          initialValues={{
            email: '',
            password: ''
          }}
          enableReinitialize
        >
          {
            ({ values, handleSubmit, handleBlur, errors, touched, handleChange }) => {
              return (
                <Form className='max-w-[500px] min-h-full flex flex-col justify-between'>
                  <div className='w-full'>
                    <div
                      className="box-border flex md:hidden mb-[30px] h-[69.5px] w-full cursor-pointer flex-row items-center justify-center "
                      onClick={() => navigate('/')}
                    >
                      <img alt="adnexio EDU" src={eduIcon} />
                    </div>
                    <h2 className='text-h2 mb-[10px] text-left'>Sign into your account</h2>
                    <p className='text-edu-lightGrayText text-justify md:text-left'>Get ready to explore the latest courses and boot camps, all customized to meet the current demands of the market!</p>
                    <div className='my-[30px]'>
                      <div className='mb-[20px]'>
                        <EDUTextField
                          name='email'
                          label='Email'
                          value={values?.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isErrorShown={touched?.email && errors?.email}
                          errorMessage={touched?.email && errors?.email}
                        />
                      </div>
                      <EDUTextField
                        label='Password'
                        name='password'
                        value={values?.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type={type}
                        endAdornment={type === 'password' ? <Visibility color='light-gray-text' sx={{ cursor: 'pointer' }} onClick={() => setType('text')} /> : <VisibilityOff color='light-gray-text' sx={{ cursor: 'pointer' }} onClick={() => setType('password')} />}
                        isErrorShown={touched?.password && errors?.password}
                        errorMessage={touched?.password && errors?.password}
                      />
                      <p onClick={navigateForgotPassword} className='underline text-edu-secondary cursor-pointer'>Forgot password?</p>
                    </div>
                  </div>
                  <div className='w-full'>
                    <EDUButton type='submit' loading={loading} disabled={loading} onClick={handleSubmit} variant='contained' fullWidth>
                      Sign in
                    </EDUButton>
                    <div className='mt-[30px] flex flex-row justify-center border-t border-edu-grayLine items-center px-[16px] py-[11px]'>
                      <p className='text-center'>New to adnexioedu? <span className='text-edu-secondary underline cursor-pointer' onClick={navigateSignup}>Create an account</span></p>
                    </div>
                  </div>
                </Form>
              )
            }
          }
        </Formik>
      </div >
      <EDUSnackbar
        open={isOpenSnackbar}
        handleClose={handleCloseSnackbar}
        autoHideDuration={4000}
        status={'success'}
        message='Password has been reset'
        transition={'grow'}
      />
      {/* ---------------TEMPORARY----------------- */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">Login Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <EDUButton variant='contained' onClick={handleClose}>OK</EDUButton>
          <EDUButton variant='outlined' onClick={navigateContactUs}>Contact Us</EDUButton>
        </DialogActions>
      </Dialog>
      {/* ---------------TEMPORARY----------------- */}
    </div >
  )
}

export default SignIn