import axios, { getData } from "lib/axios"

export const getProfile = async () => {
  return await axios.get("/adx_edu/profile").then(getData)
}

export const updateProfile = async (payload) => {
  return await axios.post(`/adx_edu/profile`, payload).then(getData)
}

export const jobseekerImage = async (data) => {
  return axios.post("/jobseeker_images", data).then(getData)
}

export const getCertificate = async (cert_id) => {
  return axios.get(`/adx_edu/certificate/${cert_id}`, { responseType: 'blob' })
}
