import logo from 'assets/logos/adnexio-edu-logo.svg'
import { Box, Button, Typography } from '@mui/material'

const ErrorView = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        minWidth: '280px',
        px: '20px',
        maxWidth: '450px',
        textAlign: 'center',
        margin: 'auto',
      }}
    >
      <Box
        component="img"
        src={logo}
        alt="adnexio logo"
        sx={{ position: 'absolute', top: 50, height: '30px' }}
      />
      <Typography
        variant="h1"
        fontWeight="medium"
        fontFamily={'Poppins'}
        fontSize={{ xs: '72px', sm: '96px' }}
        letterSpacing="initial"
      >
        500
      </Typography>
      <Typography
        variant="body1"
        textTransform={'uppercase'}
        fontFamily={'Poppins'}
        fontSize={{ xs: '14px', sm: '16px' }}
        letterSpacing="initial"
      >
        Ooops! Something went wrong.
      </Typography>
      <Typography
        variant="body2"
        fontFamily={'Poppins'}
        my={'30px'}
        fontSize={{ xs: '12px', sm: '14px' }}
        letterSpacing="initial"
      >
        Our team has been notified regarding this issue. You may refresh the page or try again
        later.
      </Typography>
      <Box sx={{ display: 'flex', gap: '20px' }}>
        <Button
          variant="contained"
          sx={{
            letterSpacing: 'initial',
            boxSizing: 'border-box !important',
            backgroundColor: '#2F80ED',
            height: '45px',
            paddingX: '30px',
            marginTop: '30px',
            textTransform: 'capitalize',
            fontFamily: 'Poppins',
            fontSize: '14px',
            borderRadius: '3.5px',
            '&:hover': {
              backgroundColor: '#1E539A',
            },
          }}
          disableElevation
          href="/"
        >
          Home
        </Button>
        <Button
          variant="outlined"
          sx={{
            letterSpacing: 'initial',
            height: '45px',
            paddingX: '30px',
            marginTop: '30px',
            textTransform: 'capitalize',
            fontFamily: 'Poppins',
            fontSize: '14px',
            borderRadius: '3.5px',
            '&:hover': {
              border: '1px solid #2F80ED',
              backgroundColor: '#EAF2FD',
            },
            color: '#2F80ED',
            border: 1,
            borderColor: '#2F80ED'
          }}
          disableElevation
          href="/contact-us"
        >
          Contact Us
        </Button>
      </Box>
    </Box>
  )
}

export default ErrorView
