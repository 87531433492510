import { useQuery } from '@tanstack/react-query'
import PaymentHeader from 'components/Header/PaymentHeader'
import React from 'react'
import { useParams } from 'react-router-dom'
import { paymentSummary } from 'services/api/payment'
import BootcampCart from '../components/BootcampCart'
import EduMiniCart from '../components/EduMiniCart'
import PaymentLoading from '../components/PaymentLoading'

const PaymentSummary = () => {

  const { id: course_id } = useParams()

  const { data: summaryData, isLoading: loading } = useQuery(['paymentSummaryDetails', course_id], async () => await paymentSummary(course_id))

  return (
    <div className="min-h-screen bg-edu-defaultBackground">
      <PaymentHeader />
      <div className="mx-auto max-w-3xl bg-edu-white p-[30px]">
        {loading ? (
          <PaymentLoading />
        ) : summaryData?.course_type === 'bootcamp' ? (
          <BootcampCart data={summaryData} />
        ) : (
          <EduMiniCart data={summaryData} />
        )}
      </div>
    </div>
  )
}

export default PaymentSummary
