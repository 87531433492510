import React from 'react'
import { Box, Typography, Grid, Skeleton } from '@mui/material'
import EDUCarousel from 'components/Carousel/EDUCarousel'
import BannerOne from '../assets/banner-2-new.png'
import BannerTwo from '..//assets/banner-1-new.png'
import { useNavigate } from 'react-router-dom'
import EDUVideoCard from 'components/VideoCard/EDUVideoCard'
import EDUCourseCard from 'components/CourseCard/EDUCourseCard'
import EDUButton from 'components/Button/EDUButton'
import { getCourseDashboard } from 'services/api/dashboard'
import { useQuery } from '@tanstack/react-query'
import Bugsnag from '@bugsnag/js'
import { CheckCircle } from '@mui/icons-material'

const DashboardPage = () => {
  const navigate = useNavigate()
  const banners = [{ banner_image: BannerOne, title: 'Feed Your Curiosity!', description: 'Expand your horizons with self-learning through our latest Digital Marketing & Data Science courses!' }, { banner_image: BannerTwo, title: 'Dive Into a World of Continuous Growth', description: ['Learn from acclaimed industry experts', 'Syllabus curated to the latest trend', 'Fit education seamlessly into your busy lifestyle', 'Gain practical experience through real-world projects'] }]
  const {
    isLoading,
    data,
  } = useQuery(
    ['courseListDashboard'],
    async () => {
      const {
        list: { data: recommendedCourse },
        recent: recentVideo,
      } = await getCourseDashboard()
      return { recommendedCourse, recentVideo }
    },
    {
      onError: (err) => Bugsnag.notify(err),
    }
  )

  const handleViewAll = (event) => {
    event.preventDefault()
    navigate('/my-learning')
  }
  const handleCourseRoute = (courseId) => {
    navigate(`/my-learning/${courseId}`)
  }

  const handleViewCourseDetails = (courseId) => {
    navigate(`/course/${courseId}`)
  }

  return (
    <div className="box-border flex h-full w-full flex-col p-[30px]">
      <Box sx={{ width: '100%' }}>
        {
          isLoading ? <Skeleton variant='rectangle' width='100%' height='250px' /> : <EDUCarousel infinite timer={5000} refId={'banner'}>
            {banners.map((item, index) => {
              return (
                <Box
                  key={index}
                  onClick={() => navigate('/explore-courses')}
                  sx={{
                    cursor: 'pointer',
                    width: '100%',
                    height: '300px',
                    backgroundImage: `url(${item.banner_image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'left',
                    padding: { xs: '20px', md: '30px' },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between'
                  }}
                >
                  <div className='flex flex-col items-start w-full'>
                    <h1 className='text-h2 lg:text-h1 mb-[16px] text-edu-white text-left md:max-w-[70%] lg:max-w-[40%]'>{item?.title}</h1>
                    {
                      typeof item?.description === 'object' ? <div className='space-y-[7px]'>
                        {
                          item?.description?.map((desc, index) => (
                            <div key={index} className='flex items-center space-x-[7px]'>
                              <CheckCircle color='white' />
                              <p className='text-edu-white text-left'>{desc}</p>
                            </div>
                          ))
                        }
                      </div> : <p className='text-edu-white md:max-w-[70%] lg:max-w-[40%] text-left'>{item?.description}</p>
                    }
                  </div>
                  {
                    index === 0 ? <div className='bg-edu-lightYellow rounded-[14px] flex justify-center items-center px-3 py-[6px]'>
                      <p className='text-center'>Stay tuned for new courses and bootcamps coming your way</p>
                    </div> : null
                  }
                </Box>
              )
            })}
          </EDUCarousel>
        }
        {data?.recentVideo && (
          <Box
            sx={{
              width: '100%',
              backgroundColor: 'white.main',
              marginTop: 2,
              marginBottom: '50px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: '22.5px',
              }}
            >
              <Typography variant="h3">Recently Watched</Typography>
              <Typography
                variant="h4"
                sx={{ textDecoration: 'underline', cursor: 'pointer', color: 'primary.main' }}
                onClick={handleViewAll}
              >
                View All
              </Typography>
            </Box>
            <Box sx={{ cursor: 'pointer' }} onClick={() => handleCourseRoute(data?.recentVideo?.id)}>
              <EDUVideoCard data={data?.recentVideo} />
            </Box>
          </Box>
        )}
        <Box sx={{ width: '100%', backgroundColor: 'white.main', marginTop: 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: '22.5px',
            }}
          >
            <Typography variant="h3">Recommended for you</Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Grid container rowSpacing={4} columnSpacing={8}>
              {
                isLoading ? [1, 1, 1].map((_, index) => (
                  <Grid item key={index} xs={12} md={6} lg={4}>
                    <Skeleton variant='rectangle' width='100%' height='177px' />
                    <Box
                      sx={{
                        paddingY: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Skeleton width='30%' />
                      <Skeleton width='60%' />
                      <Skeleton width='40%' />
                      <Skeleton width='50%' />
                    </Box>
                  </Grid>
                )) : data?.recommendedCourse?.slice(0, 3).map((course, index) => {
                  return (
                    <Grid item key={index} xs={12} md={6} lg={4}>
                      <EDUCourseCard data={course} handleNavigate={() => handleViewCourseDetails(course?.id)} />
                    </Grid>
                  )
                })
              }
            </Grid>
          </Box>
          {data?.recommendedCourse.length > 3 && (
            <Box sx={{ width: '100%', marginTop: '20px', marginBottom: '50px' }}>
              <EDUButton onClick={() => navigate('/explore-courses')} variant="outlined" sx={{ width: '100%' }}>
                Explore more courses
              </EDUButton>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  )
}

export default DashboardPage
