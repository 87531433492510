import React, { useEffect, useState } from 'react'
import { MenuItem } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { CheckCircle } from '@mui/icons-material'

const EDUMenu = ({ 'data-value': value, children, selectedvalue, sx, ...rest }) => {
  //Props:
  // selectedvalue: string. props can be passed from the parent component to check whether the item is selected.
  const [selected, setSelected] = useState(false)
  const theme = useTheme()

  useEffect(() => {
    if (selectedvalue === value) {
      return setSelected(true)
    } else {
      return setSelected(false)
    }
  }, [selectedvalue, value])

  return (
    <MenuItem
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: '9px',
        py: 1.5,
        borderRadius: '4.5px',
        mx: '5px',
        backgroundColor: selected ? `${theme.palette['default-background'].main} !important` : 'white.main',
        '&.Mui-selected': {
          backgroundColor: `${theme.palette['light-yellow-01'].main} !important`,
        },
        '&:hover': {
          backgroundColor: `${theme.palette['default-background'].main} !important`,
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
      {selected ? <CheckCircle color="success" /> : null}
    </MenuItem>
  )
}

export default EDUMenu
